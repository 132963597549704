import { useForm } from 'react-hook-form';
import { useTeleQuery } from './useGetTeleQuery';
import { useBarcodeQuery } from './useGetBarcodeQuery';
import {
  channelBuyQuery,
  mobileBrandQuery,
} from '../../../../services/react-query/optionMasterQuery';
import { useState } from 'react';
import { handleSubmitForm } from '../function/submitHandler';
import { onUploadFile } from './onUploadFile';

export const useGetWarrantyAddList = () => {
  const [dataBarcode, setDataBarCode] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { control, setValue, getValues, handleSubmit, reset, watch } = useForm();
  const { mutate: onCallTele, loading: isloadingTele } = useTeleQuery({ reset, getValues });
  const { mutate: onCallBarcode, loading: isloadingBarcode } = useBarcodeQuery({
    reset,
    getValues,
  });

  const { data: channelBuyOptions } = channelBuyQuery();
  const { data: mobileBrandOptions } = mobileBrandQuery();
  const { mutate: onAddWarranty, loading: isloadingWarrantyAdd } = onUploadFile({ reset });

  const handleOnEnterTele = (value) => {
    onCallTele(value);
  };

  const handleOnEnterBarcode = (value) => {
    onCallBarcode(value, {
      onSuccess: (data) => {
        setDataBarCode(data);
      },
    });
  };

  const onSubmit = async (values) => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    try {
      await handleSubmitForm(values, dataBarcode, onAddWarranty);
    } catch (error) {
      console.error('Error submitting form:', error);
    } finally {
      setTimeout(() => {
        setIsSubmitting(false);
      }, 1000);
    }
  };

  return {
    control,
    setValue,
    getValues,
    handleSubmit,
    reset,
    watch,
    handleOnEnterTele,
    channelBuyOptions,
    mobileBrandOptions,
    handleOnEnterBarcode,
    onSubmit,
    onAddWarranty,
    isSubmitting,
    isloadingWarrantyAdd,
    isloadingBarcode,
    isloadingTele,
  };
};
