import React from 'react';
import { Layout, ConfigProvider } from 'antd';
import { getTheme, useTheme } from '../../resources/theme';
import { useSideBarCollapse } from './hook/useSideBarCollapse';
import HeaderLayout from './layout/headerLayout';
import SiderLayout from './layout/siderLayout';
import ContentLayout from './layout/contentLayout';
import FooterLayout from './layout/footerLayout';
import { BreadcrumbRoutes } from '../breadcrumb';

const LayoutPage = () => {
  const { sidebarMode, switchTheme, globalTheme } = useTheme();
  const { collapsed, toggleCollapse } = useSideBarCollapse();

  return (
    <ConfigProvider theme={globalTheme}>
      <SiderLayout collapsed={collapsed} getTheme={getTheme} sidebarMode={sidebarMode} />
      <Layout
        className="site-layout"
        style={{
          minHeight: '100vh',
          marginLeft: collapsed ? 80 : 280,
        }}
      >
        <HeaderLayout
          getTheme={getTheme}
          collapsed={collapsed}
          sidebarMode={sidebarMode}
          toggleCollapse={toggleCollapse}
          switchTheme={switchTheme}
        />
        <BreadcrumbRoutes />
        <ContentLayout getTheme={getTheme} sidebarMode={sidebarMode} />
        <FooterLayout />
      </Layout>
    </ConfigProvider>
  );
};

export default LayoutPage;
