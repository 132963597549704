import { useForm } from 'react-hook-form';
import { FORM_CUSTOMER_SEARCH, searchDefaultValues } from '../form';
import { useGetCustomerFull } from './useGetCustomerFull';
import { columns } from '../columns';
import { useModal } from '../../../components/modal/hooks/useModal';
import { useState } from 'react';

export const useGetUserInfoList = () => {
  const formSearchObj = useForm({ defaultValues: searchDefaultValues });
  const { open: openLineUser, onToggle: onToggleLineUser } = useModal();
  const { open: openEditData, onToggle: onToggleEditData } = useModal();
  const [data, setData] = useState([]);

  const {
    dataSource: dataFull,
    onFetchFull,
    loading: isLoadingFull,
  } = useGetCustomerFull({ handleSubmit: formSearchObj.handleSubmit });
  const onOpenModalMessage = (record) => {
    setData(record);
    onToggleLineUser({ record });
  };
  const onOpenModalEdit = (record) => {
    setData(record);
    onToggleEditData({ record });
  };
  const formSearch = FORM_CUSTOMER_SEARCH({ onEnter: onFetchFull });
  const columnsData = columns({ onOpenModalMessage, onOpenModalEdit });

  return {
    formSearch,
    formSearchObj,
    dataFull,
    onFetchFull,
    isLoadingFull,
    columnsData,
    openLineUser,
    onToggleLineUser,
    onOpenModalMessage,
    onOpenModalEdit,
    data,
    openEditData,
    onToggleEditData,
  };
};
