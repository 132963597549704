import { useMutation } from '@tanstack/react-query';
import { POST, POST_RESET_LINE_USER } from '../../../services';
import { openAlert } from '../../../components/alert/hooks';

export const useResetLine = ({ onSuccessCallBack }) => {
  const { mutate, isPending } = useMutation({
    mutationFn: async (payload) => {
      const response = await POST(POST_RESET_LINE_USER(), payload);
      onSuccessCallBack && onSuccessCallBack(response);
      return response.item;
    },
    onSuccess: () => {
      openAlert({
        model: 'message',
        type: 'success',
        message: 'รีเซ็ตไลน์สำเร็จ',
      });
    },
  });
  return { mutate, loading: isPending };
};
