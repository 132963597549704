import { useForm } from 'react-hook-form';
import { useUpdateExpired } from '../hooks/useUpdateExpired';
import { useEffect } from 'react';
import { ButtonTheme, ModalTheme, RenderForm } from '../../../../components';
import { FORM_EDIT_EXPIRED } from '../form';
import { ContainerButton } from '../../../../styles/global-style';
import React from 'react';
import { Spin } from 'antd';

export const EditExpiredModal = ({ open, onToggle, dataEdit }) => {
  const { control, setValue, getValues, handleSubmit } = useForm();
  const { mutate: updateExpired, loading: isLoadingUpdateExpired } = useUpdateExpired();

  useEffect(() => {
    setValue('warrantyId', dataEdit?.warrantyId);
    setValue('expireDate', dataEdit?.expireDate);
  }, [dataEdit]);
  const onSubmit = (value) => {
    updateExpired(value);
    onToggle({ defaultValues: '' });
  };

  return (
    <Spin spinning={isLoadingUpdateExpired}>
      <ModalTheme title="แก้ไขวันหมดอายุ" open={open} onToggle={onToggle}>
        <RenderForm
          control={control}
          forms={FORM_EDIT_EXPIRED()}
          setValue={setValue}
          getValues={getValues}
        />
        <ContainerButton right>
          <ButtonTheme useFor="SUBMIT" onClick={handleSubmit(onSubmit)} />
          <ButtonTheme useFor="CANCEL" onClick={onToggle} />
        </ContainerButton>
      </ModalTheme>
    </Spin>
  );
};
