import { useMemo } from 'preact/hooks';
import { serviceOptions } from '../../../../functions/serviceOption';
export const useOptionZipcode = ({ watch }) => {
  const { provinceId, province, district, subDistrict, districtId } = watch();

  const provinceOptions = useMemo(() => serviceOptions('PROVINCE', province), [province]);

  const districtOptions = useMemo(
    () => (provinceId ? serviceOptions('DISTRICT', district) : []),
    [provinceId, district],
  );

  const subDistrictOptions = useMemo(
    () => (districtId ? serviceOptions('SUBDISTRICT', subDistrict) : []),
    [districtId, subDistrict],
  );

  return {
    provinceOptions,
    districtOptions,
    subDistrictOptions,
  };
};
