import { useEffect, useState } from 'react';
import {
  channelBuyQuery,
  mobileBrandQuery,
} from '../../../../services/react-query/optionMasterQuery';
import { useGetWarrantyEdit } from './useGetWarrantyEdit';
import { useForm } from 'react-hook-form';
import { useModal } from '../../../../components/modal/hooks/useModal';
import { navigateTo } from '../../../../utils/navigation';
import { ROUTES_PATH } from '../../../../resources/routes-name';
import { useBarcodeQuery } from './useGetBarcodeQuery';
import { onUploadFile } from './onUploadFile';
import { UpdateWarrantyEdit } from '../../../../resources/payload';

export const useGetWarrantyEditList = () => {
  const { control, setValue, getValues, handleSubmit, reset, watch } = useForm();
  const [state, setState] = useState();
  const [dataEdit, setDataEdit] = useState();
  const { mutate: onCallBarcode, loading: isloadingBarcode } = useBarcodeQuery({
    reset,
    getValues,
  });

  const queryParams = new URLSearchParams(window.location.search);
  const warrantyCode = queryParams.get('code');
  const { open: openEditExpired, onToggle: onToggleEditExpired } = useModal();
  const { data: channelBuyOptions } = channelBuyQuery();
  const { data: mobileBrandOptions } = mobileBrandQuery();
  const { mutate: onEditWarranty, loading: isloadingToSave } = onUploadFile();

  useEffect(() => {
    setState({ warrantyCode });
  }, [warrantyCode]);

  const handleOnEnterBarcode = (value) => {
    onCallBarcode(value);
  };

  const { loading: isLoadingWarrantyEdit, dataSource } = useGetWarrantyEdit({
    warrantyCode: state?.warrantyCode,
    reset,
    setValue,
    getValues,
  });
  useEffect(() => {
    const dataWarrantyEdit = dataSource?.warranty;
    setDataEdit(dataWarrantyEdit);
  }, [dataSource]);

  const goBack = () => {
    navigateTo({
      pathname: ROUTES_PATH.ROUTE_WARRANTY.PATH,
      state: getValues(),
    });
  };
  const onSubmit = (data) => {
    const formData = UpdateWarrantyEdit(data);
    onEditWarranty(formData);
  };
  return {
    control,
    setValue,
    getValues,
    handleSubmit,
    reset,
    watch,
    channelBuyOptions,
    mobileBrandOptions,
    isLoadingWarrantyEdit,
    dataSource,
    dataEdit,
    openEditExpired,
    onToggleEditExpired,
    goBack,
    onSubmit,
    handleOnEnterBarcode,
    isloadingBarcode,
    isloadingToSave,
  };
};
