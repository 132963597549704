import { Col, Row } from 'antd';
import { TextSmall } from '../../../../components/text';
import React from 'react';
import { IconContainerDiv, StatusColumnContainer } from '../../../../styles/global-style';
import { StepIcon } from '../../../../resources';
import { PauseOutlined } from '@ant-design/icons';
import { requestStatusObg2 } from '../resource/requestStatusObg';

const statusArray = Object.entries(requestStatusObg2);

export const InfoRegistration = () => {
  return (
    <>
      <Col gutter={24}>
        <TextSmall bold style={{ textDecoration: 'underline' }}>
          สถานะ
        </TextSmall>
      </Col>
      <Col gutter={24}>
        <Row align={'middle'} style={{ marginTop: '3px', marginBottom: '3px' }}>
          {statusArray.map(([key, { icon, color, label }], index) => (
            <React.Fragment key={key}>
              <Col>
                <StatusColumnContainer>
                  <IconContainerDiv style={{ color }}>{icon}</IconContainerDiv>
                  <TextSmall>{label}</TextSmall>
                </StatusColumnContainer>
              </Col>
              {index < statusArray.length - 2 && (
                <Col>
                  <StepIcon />
                </Col>
              )}
              {index === statusArray.length - 2 && (
                <Col>
                  <PauseOutlined />
                </Col>
              )}
            </React.Fragment>
          ))}
        </Row>
      </Col>
    </>
  );
};
