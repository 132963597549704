import React from 'react';

import { useResponsive } from '../../../../hooks/useResponsive.js';
import TableComponent from '../../../table/index.jsx';
import { CardTheme } from '../../../card/index.jsx';
import { CardListComponent } from '../../../card-list/index.jsx';
import { ConfigProvider } from 'antd';

export const ResponsiveTableLayout = ({
  style,
  columns,
  dataSource,
  rowSelection,
  extraContent,
  hasPagination,
  rowKey,
  components,
  ...rest
}) => {
  const { isMobile } = useResponsive();
  let tableThemeColor = {
    components: {
      Table: {
        rowSelectedBg: '#f69988',
        rowSelectedHoverBg: '#f69988',
        rowHoverBg: '#f69988',
      },
    },
  };
  return isMobile ? (
    <>
      {extraContent}
      <CardListComponent columns={columns} dataSource={dataSource} {...rest} />
    </>
  ) : (
    <CardTheme
      style={{ marginTop: '2%', ...style }}
      content={
        <>
          <ConfigProvider theme={tableThemeColor}>
            {extraContent}
            <TableComponent
              rowKey={rowKey}
              columns={columns}
              dataSource={dataSource}
              hasPagination={hasPagination || false}
              rowSelection={rowSelection}
              components={components}
              {...rest}
            />
          </ConfigProvider>
        </>
      }
    />
  );
};
