import React, { useEffect } from 'react';
import { ButtonTheme, ModalTheme, RenderForm } from '../../../../components';
import { TextLarge, TextSmall } from '../../../../components/text';
import { ContainerButton } from '../../../../styles/global-style';
import { useForm } from 'react-hook-form';
import { FORM_CLAIM_NO_APPROVE } from '../form';
import { useNoApproveClaim } from '../hooks/useNoApproveClaim';
import { Spin } from 'antd';

export const NoApproveClaimModal = ({ open, onToggle, claimData }) => {
  const { control, setValue, getValues, handleSubmit } = useForm({});
  const { mutate: noApproveClaim, loading: isLoadingNoApprove } = useNoApproveClaim();

  useEffect(() => {
    claimData?.claimId && setValue('claimId', claimData.claimId);
  }, [claimData, setValue]);

  const onSubmit = (values) => {
    noApproveClaim(values);
    onToggle({ defaultValue: '' });
  };

  return (
    <Spin spinning={isLoadingNoApprove}>
      <ModalTheme title=" " open={open} onToggle={onToggle}>
        <div style={{ width: '100%', textAlign: 'center' }}>
          <TextLarge text="หมายเหตุ" />
        </div>
        <div style={{ width: '100%', textAlign: 'left' }}>
          <TextSmall text={'ชื่อ : ' + claimData?.fullName} />
          <TextSmall text={'เลขที่เคลม : ' + claimData?.claimCode} />
        </div>
        <RenderForm
          control={control}
          setValue={setValue}
          getValues={getValues}
          forms={FORM_CLAIM_NO_APPROVE()}
        />
        <ContainerButton right>
          <ButtonTheme useFor="SUBMIT" onClick={handleSubmit(onSubmit)} />
          <ButtonTheme useFor="CANCEL" onClick={onToggle} />
        </ContainerButton>
      </ModalTheme>
    </Spin>
  );
};
