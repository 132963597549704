export const FORM_USER_REGISTRATION_WARRANTY = ({ onEnter }) => {
  return [
    {
      name: 'telephone',
      label: 'เบอร์ติดต่อ (กรอกเบอร์ติดต่อแล้วกด Enter)',
      type: 'INPUT_PHONE',
      placeholder: '',
      span: 12,
      rules: {
        required: true,
        pattern: /^[0-9]{10}$/,
      },
      maxLength: 10,
      onEnter,
    },
    {
      name: 'fullName',
      label: 'ชื่อ-นามสกุล',
      type: 'INPUT',
      placeholder: '',
      span: 12,
      rules: {
        required: true,
      },
    },
  ];
};

export const FORM_PRODUCT_REGISTRATION_WARRANTY = ({
  onEnter,
  mobileBrandOptions,
  channelBuyOptions,
}) => {
  const span = 24;
  const accept = [`image/jpeg`, 'image/png', 'image/jpg', 'image/bmp'];
  return [
    {
      name: 'barcode',
      label: 'Barcode',
      type: 'INPUT_BARCODE',
      placeholder: '',
      span,
      rules: {
        required: true,
        pattern: /^[0-9]{9}$/,
      },
      maxLength: 9,
      onEnter,
    },
    {
      name: 'productNameTh',
      label: 'ชื่อสินค้า',
      type: 'INPUT',
      placeholder: '',
      span,
      rules: {
        required: true,
      },
      style: { width: '100%', height: '100%', minHeight: '32px' },
      disabled: true,
    },
    {
      name: 'productTypeName',
      label: 'ประเภทของสินค้า',
      type: 'INPUT',
      placeholder: '',
      span,
      rules: {
        required: true,
      },
      disabled: true,
    },
    {
      name: 'mobileBrandId',
      label: 'ยี่ห้อ',
      type: 'DROPDOWN',
      placeholder: '',
      properties: {
        options: mobileBrandOptions || [],
      },
      span,
      rules: {
        required: true,
      },
      style: { width: '100%', height: '100%', minHeight: '32px' },
    },
    {
      name: 'mobileModelName',
      label: 'รุ่น',
      type: 'INPUT',
      placeholder: '',
      span,
      rules: {
        required: true,
      },
      style: { width: '100%', height: '100%', minHeight: '32px' },
    },

    {
      name: 'channelBuyId',
      label: 'ช่องทางการซื้อขาย',
      type: 'DROPDOWN',
      placeholder: '',
      properties: {
        options: channelBuyOptions || [],
      },
      span,
      rules: {
        required: true,
      },
    },
    {
      name: 'infoPicture',
      label: 'ชนิดรูปภาพที่ต้องการ (.png, .jpg, .jpeg, .bmp)',
      type: 'TEXT',
      span,
    },
    {
      name: 'invImage',
      label: 'รูปใบเสร็จ ',
      type: 'UPLOAD',
      accept: accept.join(','),
      listType: 'picture-card',
      span: 12,
      maxCount: 1,
      rules: {
        required: true,
      },
    },
    {
      name: 'packageImage',
      label: 'รูปสินค้า',
      type: 'UPLOAD',
      accept: accept.join(','),
      listType: 'picture-card',
      span: 12,
      maxCount: 1,
      rules: {
        required: true,
      },
    },
  ];
};
