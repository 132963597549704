import { useMutation } from '@tanstack/react-query';
import { POST, POST_TRACKING_SHIPMENT } from '../../../../services';
import { openAlert } from '../../../../components/alert/hooks';

export const useShipment = ({ selectedRowKeys }) => {
  const { mutate, isPending } = useMutation({
    mutationFn: async () => {
      try {
        const payload = {
          claimIdList: selectedRowKeys,
        };

        const response = await POST(POST_TRACKING_SHIPMENT(), payload, undefined, {
          responseType: 'blob',
        });

        if (response.type !== 'application/pdf') {
          throw new Error('Invalid file format');
        }

        const now = new Date();
        const dateStr = now
          .toLocaleDateString('th-TH', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          })
          .replace(/\//g, '-');
        const timeStr = now
          .toLocaleTimeString('th-TH', {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
          })
          .replace(/:/g, '-');

        const fileName = `shipment-label-${dateStr}-${timeStr}.pdf`;

        const blob = new Blob([response], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);

        return response;
      } catch (error) {
        throw error;
      }
    },

    onSuccess: () => {
      openAlert({ model: 'message', type: 'success', message: 'ดาวน์โหลดสำเร็จ' });
    },
  });
  return { mutate, loading: isPending };
};
