import { useMutation } from '@tanstack/react-query';
import { POST, POST_UPDATE_EXCEL } from '../../../services';
import { openAlert } from '../../../components/alert/hooks';
import Swal from 'sweetalert2';

export const useUpdateExcel = () => {
  const { mutate, isPending } = useMutation({
    mutationFn: async (formData) => {
      const response = await POST(POST_UPDATE_EXCEL(), formData);
      return response;
    },
    onSuccess: () => {
      openAlert({ model: 'message', type: 'success', message: 'บันทึกข้อมูลสำเร็จ' });
    },
    meta: { hideError: true },
    onError: (error) => {
      Swal.fire({
        icon: 'error',
        title: 'เกิดข้อผิดพลาด!',
        text: error?.message || 'ไม่สามารถบันทึกข้อมูลได้',
      });
    },
  });
  return { mutate, loading: isPending };
};
