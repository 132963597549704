import { useQuery } from '@tanstack/react-query';
import {
  GET,
  GET_CHANNEL_BUYS,
  GET_MOBILE_BRANDS,
  GET_PROCESS_STATUS,
  GET_PRODUCT_TYPES,
  GET_TRACKING_STATUS,
  GET_USER_PERMISSION,
} from '..';
import { serviceOptions } from '../../functions/serviceOption';

export const channelBuyQuery = () =>
  useQuery({
    queryKey: ['channelBuyQuery'],
    queryFn: async () => {
      const response = await GET(GET_CHANNEL_BUYS());
      return serviceOptions('CHANNEL_BUY', response.result.items);
    },
  });

export const mobileBrandQuery = () =>
  useQuery({
    queryKey: ['mobileBrandQuery'],
    queryFn: async () => {
      const response = await GET(GET_MOBILE_BRANDS());
      return serviceOptions('MOBILE_BRAND', response.result.items);
    },
  });
export const processStatusQuery = () =>
  useQuery({
    queryKey: ['processStatusQuery'],
    queryFn: async () => {
      const response = await GET(GET_PROCESS_STATUS());
      return serviceOptions('PROCESS_STATUS', response.result.items);
    },
  });
export const shippingSelfQuery = () =>
  useQuery({
    queryKey: ['shippingSelfQuery'],
    queryFn: async () => {
      const response = await GET(GET_TRACKING_STATUS());
      return serviceOptions('SHIPPING_SELF', response.result.items);
    },
  });
export const ProductTypesQuery = () =>
  useQuery({
    queryKey: ['productTypes'],
    queryFn: async () => {
      const response = await GET(GET_PRODUCT_TYPES());
      return serviceOptions('PRODUCT_TYPES', response?.result?.items || []);
    },
  });
export const userPermissionQuery = () =>
  useQuery({
    queryKey: ['userPermissionQuery'],
    queryFn: async () => {
      const response = await GET(GET_USER_PERMISSION());
      return serviceOptions('USER', response.result.items);
    },
  });
