import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useGetClaimView } from './useGetClaimView';
import { navigateTo } from '../../../../utils/navigation';
import { ROUTES_PATH } from '../../../../resources/routes-name';
import { useModal } from '../../../../components/modal/hooks/useModal';
import { useApproveClaim } from './useApproveClaim';
import { mobileBrandQuery } from '../../../../services/react-query/optionMasterQuery';

export const useGetClaimViewList = () => {
  const { control, getValues, setValue, reset, handleSubmit } = useForm();
  const [timelineList, setTimelineList] = useState();
  const [claimData, setClaimData] = useState();
  const [data, setData] = useState();
  const [state, setState] = useState();
  const { open: openCancel, onToggle: onToggleCancel } = useModal();
  const { open: openNoApprove, onToggle: onToggleNoApprove } = useModal();
  const { open: openUpdateProduct, onToggle: onToggleUpdateProduct } = useModal();
  const { mutate: onApproveClaim, loading: isLoadingApprove } = useApproveClaim();
  const { data: mobileBrandOptions } = mobileBrandQuery();

  const queryParams = new URLSearchParams(window.location.search);
  const claimCode = queryParams.get('code');
  const { dataSource, loading: LoadingClaimView } = useGetClaimView({
    claimCode: state?.claimCode,
    reset,
    setValue,
    getValues,
  });

  useEffect(() => {
    claimCode && setState({ claimCode });
  }, [claimCode]);

  useEffect(() => {
    dataSource && setData(dataSource);
  }, [dataSource]);

  useEffect(() => {
    if (data?.claim) {
      setClaimData({
        ...data.claim,
      });
      if (claimData?.remainingDays > 300000) {
        setValue('remainingDays', 'ตลอดชีวิต');
      }
      updateTimeline(data);
    }
  }, [data, setValue, claimData]);

  const updateTimeline = () => {
    if (data?.timelineList) {
      const timeline = data.timelineList.map((item) => ({
        text: item?.optionStatusNameTh,
        color: item?.optionStatusCode === data?.claim.claimStatus ? 'green' : 'black',
      }));
      setTimelineList(timeline);
    }
  };

  const goBack = () => {
    navigateTo({ pathname: ROUTES_PATH.ROUTE_CLAIM.PATH, state: getValues() });
  };
  const gotoEditClaim = () => {
    navigateTo({
      pathname: ROUTES_PATH.ROUTE_CLAIM_EDIT.PATH,
      search: `?code=${claimData?.claimCode}`,
    });
  };
  const onSubmitApprove = () => {
    onApproveClaim({ claimIdList: [claimData?.claimId] });
  };
  return {
    control,
    getValues,
    setValue,
    timelineList,
    LoadingClaimView,
    claimData,
    goBack,
    openCancel,
    onToggleCancel,
    gotoEditClaim,
    onSubmitApprove,
    openNoApprove,
    onToggleNoApprove,
    openUpdateProduct,
    onToggleUpdateProduct,
    handleSubmit,
    reset,
    mobileBrandOptions,
    isLoadingApprove,
  };
};
