import { useEffect, useState } from 'react';

import { useGetWarrantyView } from './useGetWarrantyView';
import { useForm } from 'react-hook-form';

import { navigateTo } from '../../../../utils/navigation';
import { ROUTES_PATH } from '../../../../resources/routes-name';
import {
  channelBuyQuery,
  mobileBrandQuery,
} from '../../../../services/react-query/optionMasterQuery';

export const useGetWarrantyViewList = () => {
  const { control, setValue, getValues, reset } = useForm();
  const [state, setState] = useState();
  const { data: channelBuyOptions } = channelBuyQuery();
  const { data: mobileBrandOptions } = mobileBrandQuery();

  const queryParams = new URLSearchParams(window.location.search);
  const warrantyCode = queryParams.get('code');

  useEffect(() => {
    setState({ warrantyCode });
  }, [warrantyCode]);

  const { loading: LoadingWarrantyView } = useGetWarrantyView({
    warrantyCode: state?.warrantyCode,
    reset,
    setValue,
    getValues,
  });
  const goBack = () => {
    navigateTo({
      pathname: ROUTES_PATH.ROUTE_WARRANTY.PATH,
      state: getValues(),
    });
  };
  return {
    control,
    setValue,
    getValues,
    reset,
    goBack,
    channelBuyOptions,
    LoadingWarrantyView,
    mobileBrandOptions,
  };
};
