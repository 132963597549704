import React from 'react';
import { StyledContainer } from '../../../components/container/styled';
import { TextLarge } from '../../../components/text';
import { CardTheme } from '../../../components/card';
import { Col, Row, Spin } from 'antd';
import { useGetClaimViewList } from './hooks/useGetClaimViewList';
import { ButtonTheme, RenderForm } from '../../../components';
import {
  FORM_CLAIM_CUSTOMER_DATA,
  FORM_CLAIM_DETAIL_DATA,
  FORM_CLAIM_DETAIL_PICTURE,
} from './form';
import { ContainerButton } from '../../../styles/global-style';
import { TimelineComponent } from '../../../components/timeline';
import { CancelClaimModal } from './modals/CancelClaimModal';
import { NoApproveClaimModal } from './modals/NoApproveClaimModal';
import { UpdateProductModal } from './modals/UpdateProductModal';
import { useAntDModalContext } from '../../../components/modal/context/useAntdModalContext';

export const ClaimView = () => {
  const {
    control,
    getValues,
    setValue,
    timelineList,
    LoadingClaimView,
    goBack,
    openCancel,
    onToggleCancel,
    claimData,
    gotoEditClaim,
    onSubmitApprove,
    openNoApprove,
    onToggleNoApprove,
    openUpdateProduct,
    onToggleUpdateProduct,
    isLoadingApprove,
  } = useGetClaimViewList();

  const { openConfirmModal } = useAntDModalContext();

  return (
    <Spin spinning={LoadingClaimView || isLoadingApprove}>
      <StyledContainer>
        <TextLarge text="รายละเอียดรายการเคลมสินค้า" />
        <Row gutter={[24, 24]}>
          <Col span={10}>
            <CardTheme>
              <TextLarge text="ข้อมูลส่วนตัว" />
              <RenderForm
                control={control}
                getValues={getValues}
                setValue={setValue}
                forms={FORM_CLAIM_CUSTOMER_DATA()}
              />
            </CardTheme>
          </Col>
          <Col span={10}>
            <CardTheme>
              <TextLarge text="ข้อมูลการแจ้งเคลม" />
              <RenderForm
                control={control}
                getValues={getValues}
                setValue={setValue}
                forms={FORM_CLAIM_DETAIL_DATA()}
              />
            </CardTheme>
          </Col>
          <Col span={4}>
            <TextLarge text="สถานะการเคลม" /> <br />
            <TimelineComponent timelineList={timelineList} />
          </Col>
        </Row>
        <br />
        <Row gutter={[24, 24]}>
          <Col span={10}>
            <CardTheme>
              <RenderForm
                control={control}
                getValues={getValues}
                setValue={setValue}
                forms={FORM_CLAIM_DETAIL_PICTURE()}
              />
            </CardTheme>
          </Col>
        </Row>
        <Row align="middle" justify="space-between" style={{ marginTop: '15px' }}>
          <Col>
            <ContainerButton>
              <ButtonTheme useFor="BACK_2" title="ย้อนกลับ" onClick={goBack} />
              <ButtonTheme
                useFor="CANCEL_LIST"
                title="ยกเลิกรายการ"
                onClick={onToggleCancel}
                disabled={claimData?.claimStatus !== 'WAIT'}
              />
              {openCancel && (
                <CancelClaimModal
                  open={openCancel}
                  onToggle={onToggleCancel}
                  claimData={claimData}
                />
              )}
            </ContainerButton>
          </Col>
          {claimData?.claimStatus !== 'TRANSPORTED' && (
            <Col>
              <ContainerButton>
                <ButtonTheme
                  useFor="CONFIRM"
                  title="อนุมัติ"
                  disabled={claimData?.claimStatus !== 'WAIT'}
                  onClick={() =>
                    openConfirmModal({
                      onOk: () => {
                        onSubmitApprove();
                      },
                      title: 'คุณต้องการอนุมัติเคลมหรือไม่',
                    })
                  }
                />
                <ButtonTheme
                  useFor="REJECT"
                  title="ไม่อนุมัติ"
                  disabled={claimData?.claimStatus !== 'WAIT'}
                  onClick={onToggleNoApprove}
                />
                {openNoApprove && (
                  <NoApproveClaimModal
                    open={openNoApprove}
                    onToggle={onToggleNoApprove}
                    claimData={claimData}
                  />
                )}
              </ContainerButton>
            </Col>
          )}
          <Col>
            <ContainerButton>
              <ButtonTheme
                useFor="CHANGE_PRODUCT"
                title="เปลี่ยนสินค้า"
                onClick={onToggleUpdateProduct}
              />
              {openUpdateProduct && (
                <UpdateProductModal
                  open={openUpdateProduct}
                  onToggle={onToggleUpdateProduct}
                  claimData={claimData}
                />
              )}
              {claimData?.claimStatus !== 'TRANSPORTED' && (
                <ButtonTheme useFor="EDIT2" title="แก้ไข" onClick={gotoEditClaim} />
              )}
            </ContainerButton>
          </Col>
        </Row>
      </StyledContainer>
    </Spin>
  );
};
