import React, { useEffect } from 'react';
import { ButtonTheme, ModalTheme, RenderForm } from '../../../../components';
import { TextLarge, TextSmall } from '../../../../components/text';
import { ContainerButton } from '../../../../styles/global-style';
import { useForm } from 'react-hook-form';
import { FORM_PROCESS_STATUS } from '../form';
import { processStatusQuery } from '../../../../services/react-query/optionMasterQuery';
import { UpdateProcessStatus } from '../../../../resources/payload';
import { useProcessStatus } from '../hooks/useProcessStatus';
import { Spin } from 'antd';

export const UpdateStatusModal = ({ open, onToggle, claimData, onFetch }) => {
  const { control, setValue, getValues, handleSubmit } = useForm({});
  const { data: processStatusList } = processStatusQuery();
  const { mutate: onSubmitProcessStatus, loading: isLoadingStatus } = useProcessStatus({
    onSuccessCallBack: onFetch,
  });

  useEffect(() => {
    setValue('claimId', claimData.claimId);
    setValue('processStatus', claimData.processStatus);
  }, [claimData]);

  const onSubmit = (values) => {
    const payload = UpdateProcessStatus(values, claimData);
    onSubmitProcessStatus(payload);
    onToggle({ defaultValue: '' });
  };

  return (
    <Spin spinning={isLoadingStatus}>
      <ModalTheme title=" " open={open} onToggle={onToggle}>
        <div style={{ width: '100%', textAlign: 'center' }}>
          <TextLarge text="แก้ไขสถานะภายใน" />
        </div>
        <div style={{ width: '100%', textAlign: 'center' }}>
          <TextSmall text={claimData?.claimCode + ' ' + claimData?.fullName} />
        </div>
        <RenderForm
          control={control}
          setValue={setValue}
          getValues={getValues}
          forms={FORM_PROCESS_STATUS({ processStatusList })}
        />
        <ContainerButton right>
          <ButtonTheme useFor="SUBMIT" onClick={handleSubmit(onSubmit)} />
          <ButtonTheme useFor="CANCEL" onClick={onToggle} />
        </ContainerButton>
      </ModalTheme>
    </Spin>
  );
};
