import { useMutation } from '@tanstack/react-query';
import { POST, POST_CLAIMS_INSERT } from '../../../../services';
import { openAlert } from '../../../../components/alert/hooks';

import { navigateTo } from '../../../../utils/navigation';
import { ROUTES_PATH } from '../../../../resources/routes-name';
import { InsertClaim } from '../../../../resources/payload';

export const onUploadFile = () => {
  const { mutate, isPending } = useMutation({
    mutationFn: async (values) => {
      const formData = new FormData();
      const payload = InsertClaim(values);

      Object.entries(payload).forEach(([key, value]) => {
        if (value !== null && value !== undefined) {
          formData.append(key, value);
        }
      });

      const response = await POST(POST_CLAIMS_INSERT(), formData);
      return response;
    },
    onSuccess: () => {
      openAlert({ model: 'message', type: 'success', message: 'ยื่นเคลมสำเร็จ' });
      setTimeout(() => {
        navigateTo({
          pathname: ROUTES_PATH.ROUTE_CLAIM.PATH,
        });
      }, 1500);
    },
  });
  return { mutate, loading: isPending };
};
