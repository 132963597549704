import { useForm } from 'react-hook-form';
import { FORM_REPORT_CLAIM, searchDefaultValues } from '../form';
import { columns, columnsExpandable } from '../columns';
import { useEffect, useState } from 'preact/hooks';
import { useGetReportWarrantyFull } from './useGetReportWarrantyFull';
import { useGetReportWarrantyByDate } from './useGetReportWarrantyByDate';

export const useGetReportWarrantyList = () => {
  const [searchType, setSearchType] = useState(null);
  const formSearchObj = useForm({ defaultValues: searchDefaultValues });
  const {
    dataSource: dataDate,
    onFetch,
    isLoading,
  } = useGetReportWarrantyByDate({ handleSubmit: formSearchObj.handleSubmit });

  const {
    dataSource: dataClaimFull,
    onFetchFull,
    loading: loadingClaimFull,
  } = useGetReportWarrantyFull({
    handleSubmit: formSearchObj.handleSubmit,
  });

  useEffect(() => {
    onSearchByField(searchDefaultValues);
  }, []);

  const onSearchByField = (values) => {
    if (values.search) {
      setSearchType('search');
      onFetchFull(values);
    } else if (values.startDate) {
      setSearchType('date');
      onFetch(values);
    }
  };

  const formatDataForExcel = (data) => {
    return data.flatMap((record) => {
      const mainRecord = {
        'ชื่อ-นามสกุล': record?.fullname,
        เบอร์โทร: record?.telephone,
        จำนวนการลงทะเบียน: record?.totalWarranty,
        วันที่ลงทะเบียนล่าสุด: record?.lastWarrantyDate,
      };

      if (!record?.warrantyHistory?.length) {
        return [mainRecord, {}];
      }

      return [
        ...record.warrantyHistory.map((warrantyDetail, index) => ({
          ...mainRecord,
          ...(index > 0
            ? {
                'ชื่อ-นามสกุล': undefined,
                เบอร์โทร: undefined,
                จำนวนการลงทะเบียน: undefined,
                วันที่ลงทะเบียนล่าสุด: undefined,
              }
            : {}),
          เลขที่รับประกัน: warrantyDetail?.warrantyCode,
          รหัสรับประกัน: warrantyDetail?.warrantyNumberCode,
          รหัสลูกค้า: warrantyDetail?.customerCode,
          ยี่ห้อ: warrantyDetail?.mobileBrandName,
          รุ่น: warrantyDetail?.mobileModelName,
          ชื่อสินค้า: warrantyDetail?.productName,
          รหัสสินค้า: warrantyDetail?.itemCode,
          ประเภทสินค้า: warrantyDetail?.productTypeCode,
          ช่องทางการซื้อขาย: warrantyDetail?.channelBuyNameTh,
          วันที่ลงทะเบียน: warrantyDetail?.createDate,
        })),
        {},
      ];
    });
  };

  const currentData = searchType === 'date' ? dataDate : dataClaimFull || [];
  const maxDetails = Math.max(...currentData.map((record) => record.warrantyHistory?.length || 0));

  const createExpandableHeaders = () => {
    return [
      { title: 'เลขที่รับประกัน', dataIndex: 'เลขที่รับประกัน' },
      { title: 'รหัสรับประกัน', dataIndex: 'รหัสรับประกัน' },
      { title: 'รหัสลูกค้า', dataIndex: 'รหัสลูกค้า' },
      { title: 'ยี่ห้อ', dataIndex: 'ยี่ห้อ' },
      { title: 'รุ่น', dataIndex: 'รุ่น' },
      { title: 'ชื่อสินค้า', dataIndex: 'ชื่อสินค้า' },
      { title: 'รหัสสินค้า', dataIndex: 'รหัสสินค้า' },
      { title: 'ประเภทสินค้า', dataIndex: 'ประเภทสินค้า' },
      { title: 'ช่องทางการซื้อขาย', dataIndex: 'ช่องทางการซื้อขาย' },
      { title: 'วันที่ลงทะเบียน', dataIndex: 'วันที่ลงทะเบียน' },
    ];
  };

  const columnsData = columns();
  const columnsExpandableData = columnsExpandable();
  const formSearch = FORM_REPORT_CLAIM({ onEnter: formSearchObj.handleSubmit(onSearchByField) });

  return {
    formSearch,
    formSearchObj,
    dataDate,
    onFetch,
    isLoading,
    columnsData,
    dataClaimFull,
    onFetchFull,
    loadingClaimFull,
    searchType,
    onSearchByField,
    columnsExpandableData,
    formatDataForExcel,
    maxDetails,
    createExpandableHeaders,
  };
};
