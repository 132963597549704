import { useState } from 'preact/hooks';

export const useTable = () => {
  const defaultPageSize = 10;
  const sizePageOptions = [10, 20, 50, 100];
  const defaultCurrent = 1;
  const [total, setTotal] = useState(0);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [current, setCurrent] = useState(defaultCurrent);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRowsData, setSelectedRowsData] = useState([]);

  const setTotalItem = (value) => {
    setTotal(value);
  };

  const setCurrentPage = (value) => {
    setCurrent(value);
  };

  const onShowSizeChange = (_currentSize, size) => {
    setPageSize(size);
  };

  const onSelectChange = (selectedRowDataKeys, selectedRows) => {
    console.log(selectedRows);
    console.log(selectedRowDataKeys);
    setSelectedRowsData(selectedRows);
    setSelectedRowKeys(selectedRowDataKeys);
  };

  return {
    total,
    current,
    pageSize,
    defaultPageSize,
    sizePageOptions,
    onShowSizeChange,
    setTotalItem,
    setCurrentPage,
    onSelectChange,
    selectedRowKeys,
    selectedRowsData,
  };
};
