import { useMutation } from '@tanstack/react-query';
import { POST, POST_WARRANTY_ADD } from '../../../../services';
import { openAlert } from '../../../../components/alert/hooks';

import { navigateTo } from '../../../../utils/navigation';
import { ROUTES_PATH } from '../../../../resources/routes-name';
import { InsertWarranty } from '../../../../resources/payload';

export const onUploadFile = ({ reset }) => {
  const { isPending, mutate } = useMutation({
    mutationFn: async (values) => {
      const formData = new FormData();
      const payload = InsertWarranty(values);

      Object.entries(payload).forEach(([key, value]) => {
        if (value !== null && value !== undefined) {
          formData.append(key, value);
        }
      });

      const response = await POST(POST_WARRANTY_ADD(), formData);
      return response;
    },
    onSuccess: (response) => {
      openAlert({ model: 'message', type: 'success', message: 'เพิ่มข้อมูลสินค้าสำเร็จ' });
      reset({
        ...response,
      });
      setTimeout(() => {
        navigateTo({
          pathname: ROUTES_PATH.ROUTE_WARRANTY.PATH,
        });
      }, 1500);
    },
  });
  return { mutate, loading: isPending };
};
