export const serviceOptions = (key, options = []) => {
  switch (key) {
    //warranty
    case 'CHANNEL_BUY':
      return options?.map((item) => ({
        label: item.channelBuyNameTh,
        value: item.channelBuyId,
      }));
    case 'MOBILE_BRAND':
      return options?.map((brand) => ({
        label: brand.mobileBrandNameTh,
        value: brand.mobileBrandId,
      }));
    //claim
    case 'PROVINCE':
      const provinceOptions = options?.map((item) => ({
        label: item.provinceNameTh,
        value: item.provinceId,
      }));
      return provinceOptions;
    case 'DISTRICT':
      const districtOptions = options?.map((item) => ({
        label: item.districtNameTh,
        value: item.districtId,
      }));
      return districtOptions;
    case 'SUBDISTRICT':
      const subDistrictOptions = options?.map((item) => ({
        label: item.subDistrictNameTh,
        value: item.subDistrictId,
      }));
      return subDistrictOptions;
    case 'ADDRESS':
      const addressOptions = options?.map((address) => ({
        label: address.addressFullText,
        value: address.customerAddressId,
      }));
      return addressOptions;
    case 'CLAIM_PRODUCT':
      return options?.map((item) => ({
        label: `${item.mobileModelName} || ${item.productName}`,
        value: item.warrantyId,
        breakdowns: item.breakdowns,
      }));
    case 'BREAKDOWN':
      const breakdownOptions = options?.map((item) => {
        return {
          label: item?.breakdownNameTh,
          value: item?.breakdownId,
        };
      });
      return breakdownOptions;
    case 'PROCESS_STATUS':
      return options?.map((status) => ({
        label: status.optionStatusNameTh,
        value: status.optionStatusCode,
      }));
    case 'SHIPPING_SELF':
      return options?.map((item) => ({
        label: item.shippingName,
        value: item.shippingId,
      }));
    case 'PRODUCT_TYPES':
      return options?.map((item) => ({
        label: item.productTypeNameTh,
        value: item.productTypeId,
      }));
    case 'USER':
      return options?.map((item) => ({
        label: `${item.userCode}: ${item.fullName}`,
        value: item.userId,
      }));
  }
};
