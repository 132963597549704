import { useForm } from 'react-hook-form';
import { ButtonTheme, ModalTheme, RenderForm } from '../../../components';
import { TextLarge } from '../../../components/text';
import { FORM_EDIT_TEL } from '../form';
import { ContainerButton } from '../../../styles/global-style';
import React, { useEffect } from 'react';
import { useUpdateTel } from '../hooks/useUpdateTel';
import { UpdateTel } from '../../../resources/payload';
import { Spin } from 'antd';

export const UpdateTelModel = ({ open, onToggle, initialValues, onFetch, closeAllWindows }) => {
  const { control, setValue, getValues, handleSubmit } = useForm({});
  const { mutate: onSubmitEditTel, loading: isLoadingTel } = useUpdateTel({
    onSuccessCallBack: () => {
      onFetch();
      closeAllWindows();
    },
  });
  useEffect(() => {
    if (initialValues) {
      Object.entries(initialValues).forEach(([key, value]) => setValue(key, value));
    }
  }, [initialValues, setValue]);
  const onSubmit = (values) => {
    const payload = UpdateTel(values, initialValues);
    onSubmitEditTel(payload);
    onToggle({ defaultValue: '' });
  };
  return (
    <Spin spinning={isLoadingTel}>
      <ModalTheme title=" " open={open} onToggle={onToggle}>
        <div style={{ width: '100%', textAlign: 'left' }}>
          <TextLarge text="แก้ไขเบอร์ติดต่อใหม่" />
        </div>
        <RenderForm
          control={control}
          setValue={setValue}
          getValues={getValues}
          forms={FORM_EDIT_TEL()}
        />
        <ContainerButton right>
          <ButtonTheme useFor="SUBMIT" onClick={handleSubmit(onSubmit)} />
          <ButtonTheme useFor="CANCEL" onClick={onToggle} />
        </ContainerButton>
      </ModalTheme>
    </Spin>
  );
};
