import { CardTheme } from '../../../components/card';
import { SpaceBetween, StyledContainer } from '../../../components/container/styled';
import React from 'react';
import { TextLarge } from '../../../components/text';
import { ContainerButton } from '../../../styles/global-style';
import { ButtonTheme, Gap } from '../../../components';
import { Row, Spin } from 'antd';
import { ResponsivePageLayout } from '../../../components/page/responsive-layout/ResponsivePageLayout';
import { Icon } from '../../../resources';
import { useGetClaimList } from './hooks/useGetClaimList';
import { InfoRegistration } from './view/InfoRegistration';
import { columns, exportHeaders } from './columns';
import ButtonExcelEmbedded from '../../../components/report-excel/ButtonExcelEmbedded';
import { CustomImage } from '../../../functions/Image';
import { UpdateStatusModal } from './modals/UpdateStatusModal';
import { UpdateTrackingModal } from './modals/UpdateTrackingModal';
import { SendLineMsgModal } from './modals/SendLineMsgModal';
import { UpdateRemarkModal } from './modals/UpdateRemarkModal';
import { useAntDModalContext } from '../../../components/modal/context/useAntdModalContext';

export const ClaimList = () => {
  const {
    formSearch,
    onOpenTracking,
    onOpenLineMessage,
    onOpenRemarkMessage,
    onOpenClaimEdit,
    onOpenClaimView,
    loadingClaimFull,
    dataClaimFull,
    searchType,
    formSearchObj,
    setSelectedRowKeys,
    onSearchByField,
    searchDefaultValues,
    selectedRowKeys,
    onSelectChange,
    loadingClaimDate,
    dataClaimDate,
    handleSubmitAccident,
    transformDataForExcel,
    gotoclaimAdd,
    onModalProcessStatus,
    openProcessStatus,
    onToggleProcessStatus,
    claimData,
    onFetch,
    openTracking,
    onToggleTracking,
    handleSubmitShipment,
    openLine,
    onToggleLine,
    openRemark,
    onToggleRemark,
    isLoadingApprove,
    isLoadingShipment,
  } = useGetClaimList();
  const { openConfirmModal } = useAntDModalContext();
  return (
    <Spin spinning={isLoadingApprove || isLoadingShipment}>
      <StyledContainer>
        <TextLarge text="เคลมสินค้า" />
        <CardTheme>
          <Row gutter={16} flex justify="space-between">
            <Row justify="start">
              <ButtonTheme
                title="ใบส่งสินค้า"
                useFor="BILL"
                onClick={handleSubmitShipment}
                disabled={selectedRowKeys.length === 0}
              />
            </Row>
            <Row justify="end">
              <ButtonTheme
                title="อนุมัติเคลม"
                useFor="CONFIRM_OK"
                onClick={() =>
                  openConfirmModal({
                    onOk: () => {
                      handleSubmitAccident();
                    },
                    title: 'คุณต้องการอนุมัติเคลมหรือไม่',
                  })
                }
                disabled={selectedRowKeys.length === 0}
              />
              <ButtonTheme
                title="ยื่นเรื่องเคลม"
                useFor="WARRANTY"
                onClick={() => gotoclaimAdd()}
              />
            </Row>
          </Row>
          <Gap />
          <ResponsivePageLayout
            searchLayout={{
              title: 'ค้นหาการเคลมสินค้า',
              icon: <Icon.todo />,
              formSearch,
              useFormParam: formSearchObj,
              onSearch: formSearchObj.handleSubmit(onSearchByField),
              onClearCustom: () => {
                formSearchObj.reset(searchDefaultValues);
                setSelectedRowKeys([]);
              },
            }}
            content={<InfoRegistration />}
            tableLayout={{
              columns: columns({
                onOpenTracking,
                onOpenLineMessage,
                onOpenRemarkMessage,
                onOpenClaimEdit,
                onOpenClaimView,
                onModalProcessStatus,
              }),
              loading: loadingClaimFull || loadingClaimDate,
              dataSource: searchType === 'search' ? dataClaimFull : dataClaimDate,
              rowKey: 'claimId',
              hasPagination: true,
              rowSelection: {
                selectedRowKeys,
                onChange: onSelectChange,
              },
              expandable: {
                expandedRowRender: (record) => (
                  <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <CustomImage
                      width={80}
                      preview
                      src={record?.claimImageUrl}
                      style={{ borderRadius: 10 }}
                    />
                    <CustomImage
                      width={80}
                      preview
                      src={record?.receiptImageUrl}
                      style={{ borderRadius: 10, marginLeft: 10 }}
                    />
                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '20px' }}>
                      <div style={{ marginBottom: '8px' }}>
                        <strong>ชื่อสินค้า: </strong>
                        {record.productName}
                      </div>
                      <div style={{ marginBottom: '8px' }}>
                        <strong>รุ่น: </strong>
                        {record.mobileModelName}
                      </div>
                      <div>
                        <strong>อาการเสีย: </strong>
                        {record.breakdownName}
                      </div>
                    </div>
                  </div>
                ),
              },
              extraContent: (
                <SpaceBetween>
                  <ContainerButton right>
                    <ButtonExcelEmbedded
                      title="Export"
                      filename="รายงานการยื่นเคลม"
                      header={exportHeaders}
                      data={transformDataForExcel(
                        searchType === 'search' ? dataClaimFull : dataClaimDate,
                      )}
                    />
                  </ContainerButton>
                </SpaceBetween>
              ),
            }}
          />
        </CardTheme>
        {openProcessStatus && (
          <UpdateStatusModal
            open={openProcessStatus}
            onToggle={onToggleProcessStatus}
            claimData={claimData}
            onFetch={onFetch}
          />
        )}
        {openTracking && (
          <UpdateTrackingModal
            open={openTracking}
            onToggle={onToggleTracking}
            claimData={claimData}
            onFetch={onFetch}
          />
        )}
        {openLine && (
          <SendLineMsgModal
            open={openLine}
            onToggle={onToggleLine}
            claimData={claimData}
            onFetch={onFetch}
          />
        )}
        {openRemark && (
          <UpdateRemarkModal
            open={openRemark}
            onToggle={onToggleRemark}
            claimData={claimData}
            onFetch={onFetch}
          />
        )}
      </StyledContainer>
    </Spin>
  );
};
