import { useMutation } from '@tanstack/react-query';
import { GET, GET_CLAIMS_ZIPCODE } from '../../../../services';
import { openAlert } from '../../../../components/alert/hooks';

export const useZipcode = ({ reset, getValues }) => {
  const { mutate, isPending } = useMutation({
    mutationFn: async (value) => {
      const response = await GET(GET_CLAIMS_ZIPCODE(value));
      return response.result;
    },
    onSuccess: (result) => {
      openAlert({
        type: 'success',
        message: 'ค้นหารหัสไปรษณีย์สำเร็จ',
        model: 'message',
      });
      reset({
        ...getValues(),
        ...result,
      });
    },
  });
  return { mutate, loading: isPending };
};
