import dayjs from 'dayjs';
export const FORM_CLAIM_LIST = ({ onEnter }) => {
  return [
    {
      name: 'search',
      type: 'INPUT',
      label: 'ค้นหารายการ (กรอกข้อมูลแล้วกด Enter เพื่อค้นหา)',
      placeholder: '',
      span: 14,
      helper: 'Tips : สามารถค้นหาด้วย รหัสเคลม , รหัสรับประกัน, เบอร์ , รหัสลูกค้า , ชื่อลูกค้า',
      onEnter,
    },
    {
      name: 'startDate',
      label: 'ค้นหา',
      placeholder: '',
      type: 'DATE_RANGE',
      placeholder: '',
      span: 10,
      defaultValue: [dayjs().startOf('month'), dayjs()],
      rules: {
        required: true,
      },
      onEnter,
    },
  ];
};
export const searchDefaultValues = {
  search: '',
  startDate: [dayjs().subtract(7, 'day'), dayjs()],
};
export const FORM_PROCESS_STATUS = ({ processStatusList = [] }) => {
  return [
    {
      name: 'processStatus',
      type: 'DROPDOWN',
      label: 'แก้ไขสถานะภายใน',
      placeholder: '',
      properties: {
        options: processStatusList || [],
      },
      rules: {
        required: true,
      },
    },
  ];
};
export const FORM_TRACKING = ({ shippingSelfList }) => {
  return [
    {
      name: 'shippingId',
      type: 'DROPDOWN',
      label: 'ขนส่ง',
      placeholder: '',
      properties: {
        options: shippingSelfList || [],
      },
      rules: {
        required: true,
      },
    },
    {
      name: 'trackingNumber',
      type: 'INPUT',
      label: 'เลขพัสดุ',
      placeholder: '',
      rules: {
        required: true,
      },
    },
    {
      name: 'shippingDate',
      type: 'DATE_PICKER',
      label: 'วันที่จัดส่ง',
      placeholder: '',
      defaultValue: dayjs(),
      disabled: true,
    },
  ];
};
export const FORM_SEND_LINE_MSG = () => {
  return [
    {
      name: 'message',
      label: 'ข้อความ (ข้อความระบุไม่เกิน 500 ตัวอักษร)',
      type: 'INPUT_AREA',
      placeholder: '',
      rules: {
        required: true,
        maxLength: 500,
      },
    },
  ];
};
export const FORM_REMARK = () => {
  return [
    {
      name: 'remark',
      label: 'ข้อความ (ข้อความระบุไม่เกิน 250 ตัวอักษร)',
      type: 'INPUT_AREA',
      placeholder: '',
      rules: {
        required: true,
        maxLength: 250,
      },
    },
  ];
};
