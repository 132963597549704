import React from 'react';
import { StyledContainer } from '../../../components/container/styled';
import { TextLarge } from '../../../components/text';
import { CardTheme } from '../../../components/card';
import { Col, Row, Spin } from 'antd';
import { useGetClaimEditList } from './hooks/useGetClaimEditList';
import { ButtonTheme, RenderForm } from '../../../components';
import {
  FORM_CLAIM_CUSTOMER_EDIT_DATA,
  FORM_CLAIM_EDIT_DATA,
  FORM_CLAIM_EDIT_PICTURE,
} from './form';
import { ContainerButton } from '../../../styles/global-style';
import { TimelineComponent } from '../../../components/timeline';

export const ClaimEdit = () => {
  const {
    control,
    getValues,
    setValue,
    timelineList,
    LoadingClaimEdit,
    onEnterZipcode,
    provinceOptions,
    districtOptions,
    subDistrictOptions,
    breakdownList,
    onSubmit,
    isloadingToEdit,
    isLoadingZipCode,
  } = useGetClaimEditList();

  return (
    <Spin spinning={LoadingClaimEdit || isloadingToEdit || isLoadingZipCode}>
      <StyledContainer>
        <TextLarge text="รายละเอียดรายการเคลมสินค้า" />
        <Row gutter={[24, 24]}>
          <Col span={10}>
            <CardTheme>
              <TextLarge text="ข้อมูลส่วนตัว" />
              <RenderForm
                control={control}
                getValues={getValues}
                setValue={setValue}
                forms={FORM_CLAIM_CUSTOMER_EDIT_DATA({
                  onEnter: onEnterZipcode,
                  provinceOptions,
                  districtOptions,
                  subDistrictOptions,
                })}
              />
            </CardTheme>
          </Col>
          <Col span={10}>
            <CardTheme>
              <TextLarge text="ข้อมูลการแจ้งเคลม" />
              <RenderForm
                control={control}
                getValues={getValues}
                setValue={setValue}
                forms={FORM_CLAIM_EDIT_DATA({ breakdownList })}
              />
            </CardTheme>
          </Col>
          <Col span={4}>
            <TextLarge text="สถานะการเคลม" /> <br />
            <TimelineComponent timelineList={timelineList} />
          </Col>
        </Row>
        <br />
        <Row gutter={[24, 24]}>
          <Col span={10}>
            <CardTheme>
              <RenderForm
                control={control}
                getValues={getValues}
                setValue={setValue}
                forms={FORM_CLAIM_EDIT_PICTURE()}
              />
            </CardTheme>
          </Col>
        </Row>
        <ContainerButton center>
          <ButtonTheme useFor="SUBMIT" title="บันทึก" onClick={onSubmit} />
        </ContainerButton>
      </StyledContainer>
    </Spin>
  );
};
