import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { navigationRef } from './utils/navigation';
import GlobalStyle from './styles/global-style';
import * as ROUTES from './resources/routes-name';
import HandleScene from './screens/handle';
import { Fragment } from 'react';
import { App as AppAntD } from 'antd';
import LoginScene from './screens/login';
import { Provider } from 'react-redux';
import store from './redux/store.js';
import Alert from './components/alert';
import Loading from './components/loading/loading.jsx';
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import LayoutPage from './components/layoutPage';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { env } from './utils/env/config';
// import ErrorBoundaryController from './components/error-boundary';
import { AntDModalContextProvider } from './components/modal/context/useAntdModalContext';
import React from 'react';
import { openAlert } from './components/alert/hooks';

if (env.node_env !== 'development') {
  console.log = () => null;
  console.error = () => null;
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5 * 60 * 1000,
      // ข้อมูลจะถือว่ายังใหม่ (fresh) อยู่ 5 นาที
      gcTime: 10 * 60 * 1000, // เก็บข้อมูลในแคชไว้ 10 นาที
      retry: 1, // ลองดึงข้อมูลใหม่ 1 ครั้งหากเกิดข้อผิดพลาด
      refetchOnWindowFocus: false, // ไม่ดึงข้อมูลใหม่เมื่อกลับมาที่หน้าต่าง
      refetchOnReconnect: true, // ดึงข้อมูลใหม่เมื่อเชื่อมต่ออินเทอร์เน็ตกลับมา
    },
  },
  queryCache: new QueryCache({
    onError: (error) => {
      openAlert({
        model: 'message',
        type: 'error',
        title: 'เกิดข้อผิดพลาด',
        message: error.message,
      });
    },
  }),
  mutationCache: new MutationCache({
    onError: (error, _vaiables, _context, mutation) => {
      if (mutation.meta?.hideError) {
        return;
      }
      openAlert({
        model: 'message',
        type: 'error',
        title: 'เกิดข้อผิดพลาด',
        message: error.message,
      });
    },
  }),
});

const AppContainer = () => {
  return (
    // <ErrorBoundaryController>
    <AntDModalContextProvider>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <Provider store={store}>
          <Loading>
            <Alert />
            <Router ref={navigationRef}>
              <div />
              <Switch>
                <Route exact path={ROUTES.ROUTE_HANDLE} component={HandleScene} />
                <Route exact path={ROUTES.ROUTE_LOGIN_MANUAL} component={LoginScene} />
                <Route exact component={LayoutPage} />
              </Switch>
            </Router>
          </Loading>
        </Provider>
      </QueryClientProvider>
    </AntDModalContextProvider>
    // </ErrorBoundaryController>
  );
};

const App = () => {
  return (
    <Fragment>
      <AppAntD>
        <AppContainer />
        <GlobalStyle />
      </AppAntD>
    </Fragment>
  );
};

export default App;
