import { useMutation } from '@tanstack/react-query';
import { POST, POST_UPDATE_PROCESS } from '../../../../services';
import { openAlert } from '../../../../components/alert/hooks';

export const useProcessStatus = ({ onSuccessCallBack }) => {
  const { mutate, isPending } = useMutation({
    mutationFn: async (payload) => {
      const response = await POST(POST_UPDATE_PROCESS(), payload);
      onSuccessCallBack && onSuccessCallBack(response);
      return response.result;
    },
    onSuccess: () => {
      openAlert({
        model: 'message',
        type: 'success',
        message: 'เปลี่ยนสถานะภายในสำเร็จ',
      });
    },
  });
  return { mutate, loading: isPending };
};
