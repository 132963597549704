import React from 'react';
import notFoundImg from '../../assets/images/404.png';

const NotfoundScene = () => {
  return (
    <div style={{ textAlignLast: 'center' }}>
      <img src={notFoundImg} style={{ minHeight: '100%', width: '50vw' }} />
    </div>
  );
};

export default NotfoundScene;
