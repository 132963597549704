import React from 'react';
import { TextLarge } from '../../components/text';
import { CardTheme } from '../../components/card';
import { ResponsivePageLayout } from '../../components/page/responsive-layout/ResponsivePageLayout';
import { Icon } from '../../resources';
import { useGetReportClaimList } from './hooks/useGetReportClaimList';
import { ContainerButton } from '../../styles/global-style';
import ButtonExcelEmbedded from '../../components/report-excel/ButtonExcelEmbedded';
import { SpaceBetween, StyledContainer } from '../../components/container/styled';
import { ResponsiveTableLayout } from '../../components/page/responsive-layout/views/ResponsiveTableLayout';
import { Col, Row } from 'antd';

export const ReportClaim = () => {
  const {
    formSearch,
    formSearchObj,
    dataDate,
    isLoading,
    columnsData,
    dataClaimFull,
    loadingClaimFull,
    searchType,
    onSearchByField,
    formatDataForExcel,
    createExpandableHeaders,
    columnsExpandableBeforeData,
    columnsExpandableAfterData,
  } = useGetReportClaimList();

  return (
    <StyledContainer>
      <TextLarge text="รายงานเปลี่ยนสินค้าข้ามรุ่น" />
      <CardTheme>
        <ResponsivePageLayout
          searchLayout={{
            title: 'ค้นหารายงานเปลี่ยนสินค้าข้ามรุ่น',
            icon: <Icon.todo />,
            formSearch,
            useFormParam: formSearchObj,
            onSearch: formSearchObj.handleSubmit(onSearchByField),
            onClear: formSearchObj.reset,
          }}
          tableLayout={{
            columns: columnsData || [],
            dataSource: searchType === 'date' ? dataDate : dataClaimFull || [],
            loading: searchType === 'date' ? isLoading : loadingClaimFull,
            rowKey: 'rowNumber',
            hasPagination: true,
            expandable: {
              expandedRowRender: (record) => {
                const logDataBefore = [record?.logDataBefore] || [];
                const logDataAfter = [record?.logDataAfter] || [];
                return (
                  <Row gutter={[16, 16]}>
                    <Col span={12}>
                      <ResponsiveTableLayout
                        columns={columnsExpandableBeforeData}
                        dataSource={logDataBefore}
                        loading={isLoading}
                      />
                    </Col>
                    <Col span={12}>
                      <ResponsiveTableLayout
                        columns={columnsExpandableAfterData}
                        dataSource={logDataAfter}
                        loading={isLoading}
                      />
                    </Col>
                  </Row>
                );
              },
            },
            extraContent: (
              <SpaceBetween>
                <ContainerButton right>
                  <ButtonExcelEmbedded
                    title="Export"
                    filename="รายงานเปลี่ยนสินค้าข้ามรุ่น"
                    header={[...columnsData, ...createExpandableHeaders()]}
                    data={formatDataForExcel(
                      searchType === 'date' ? dataDate : dataClaimFull || [],
                    )}
                  />
                </ContainerButton>
              </SpaceBetween>
            ),
          }}
        />
      </CardTheme>
    </StyledContainer>
  );
};
