import React from 'react';
import {
  createColumnButtonArr,
  createColumnText,
  widthOptions,
} from '../../../components/table/function';
import { ButtonTheme, ButtonToolTip } from '../../../components';

export const columns = ({ onOpenModalMessage, onOpenModalEdit }) => [
  createColumnText('รหัสลูกค้า', 'customerCode', widthOptions(150)),
  createColumnText('ชื่อ', 'fullName', widthOptions(150)),
  createColumnText('เบอร์ติดต่อ', 'telephone', widthOptions(150)),
  createColumnText('ไลน์', 'lineUid', widthOptions(150)),
  createColumnButtonArr('จัดการ', 'rowNo', {
    others: {
      fixed: 'right',
      width: 180,
    },
    buttonArr: [
      {
        useFor: 'EDIT2',
        render: (_, record) => (
          <ButtonToolTip toolTip="แก้ไข" placement="topLeft">
            <ButtonTheme useFor="EDIT2" onClick={() => onOpenModalEdit(record)} />
          </ButtonToolTip>
        ),
      },
      {
        useFor: 'LINE2',
        render: (_, record) => (
          <ButtonToolTip toolTip="ส่งข้อความ" placement="topLeft">
            <ButtonTheme useFor="LINE2" onClick={() => onOpenModalMessage(record)} />
          </ButtonToolTip>
        ),
      },
    ],
  }),
];
