import { useForm } from 'react-hook-form';
import { FORM_REPORT_CLAIM, searchDefaultValues } from '../form';
import { columns, columnsExpandableAfter, columnsExpandableBefore } from '../columns';
import { useEffect, useState } from 'preact/hooks';
import { useGetReportClaimFull } from './useGetReportClaimFull';
import { useGetReportClaimByDate } from './useGetReportClaimByDate';

export const useGetReportClaimList = () => {
  const [searchType, setSearchType] = useState(null);
  const formSearchObj = useForm({ defaultValues: searchDefaultValues });
  const {
    dataSource: dataDate,
    onFetch,
    isLoading,
  } = useGetReportClaimByDate({ handleSubmit: formSearchObj.handleSubmit });

  const {
    dataSource: dataClaimFull,
    onFetchFull,
    loading: loadingClaimFull,
  } = useGetReportClaimFull({
    handleSubmit: formSearchObj.handleSubmit,
  });

  useEffect(() => {
    onSearchByField(searchDefaultValues);
  }, []);

  const onSearchByField = (values) => {
    if (values.search) {
      setSearchType('search');
      onFetchFull(values);
    } else if (values.startDate) {
      setSearchType('date');
      onFetch(values);
    }
  };

  const formatDataForExcel = (data) => {
    const safeData = Array.isArray(data) ? data : [];
    const formatMainData = (record) => {
      const mainData = {};
      columnsData.forEach((col) => {
        mainData[col.dataIndex] = record[col.dataIndex] || '';
      });
      return mainData;
    };

    const formatExpandableData = (record, prefix) => {
      const detailData = {};
      [...columnsExpandableBefore(), ...columnsExpandableAfter()].forEach((col) => {
        detailData[`${prefix}_${col.dataIndex}`] = record?.[col.dataIndex] || '';
      });
      return detailData;
    };
    return safeData.map((record) => ({
      ...formatMainData(record),
      ...formatExpandableData(record.logDataBefore, 'before'),
      ...formatExpandableData(record.logDataAfter, 'after'),
    }));
  };
  const createExpandableHeaders = () => {
    const expandableHeaders = [];
    columnsExpandableBefore().forEach((col) => {
      expandableHeaders.push({
        ...col,
        key: `before_${col.dataIndex}`,
        dataIndex: `before_${col.dataIndex}`,
        title: `ก่อน - ${col.title}`,
      });
    });

    columnsExpandableAfter().forEach((col) => {
      expandableHeaders.push({
        ...col,
        key: `after_${col.dataIndex}`,
        dataIndex: `after_${col.dataIndex}`,
        title: `หลัง - ${col.title}`,
      });
    });

    return expandableHeaders;
  };

  const columnsData = columns();
  const columnsExpandableBeforeData = columnsExpandableBefore();
  const columnsExpandableAfterData = columnsExpandableAfter();
  const formSearch = FORM_REPORT_CLAIM({ onEnter: formSearchObj.handleSubmit(onSearchByField) });

  return {
    formSearch,
    formSearchObj,
    dataDate,
    onFetch,
    isLoading,
    columnsData,
    dataClaimFull,
    onFetchFull,
    loadingClaimFull,
    searchType,
    onSearchByField,
    columnsExpandableBeforeData,
    columnsExpandableAfterData,
    formatDataForExcel,
    createExpandableHeaders,
  };
};
