import { useMutation } from '@tanstack/react-query';
import { GET, GET_BARCORE } from '../../../../services';
import { openAlert } from '../../../../components/alert/hooks';

export const useBarcodeQuery = ({ reset, getValues }) => {
  const { isPending, mutate } = useMutation({
    mutationFn: async (value) => {
      const response = await GET(GET_BARCORE(value));
      return response.result;
    },
    onSuccess: (result) => {
      openAlert({
        type: 'success',
        model: 'message',
        message: 'ค้นหาชื่อสินค้าและประเภทสินค้าสำเร็จ',
      });
      reset({
        ...getValues(),
        productNameTh: result.item.productNameTh,
        productTypeName: result.item.productTypeName,
      });
    },
    onError: () => {
      reset({
        ...getValues(),
        productNameTh: '',
        productTypeName: '',
      });
    },
  });
  return { loading: isPending, mutate };
};
