import { useForm } from 'react-hook-form';
import { FORM_PRODUCT_SEARCH, searchDefaultValues } from '../form';
import { columns } from '../columns';
import { useGetProductFull } from './useGetProductFull';
import { useModal } from '../../../components/modal/hooks/useModal';
import { useState } from 'react';

export const useGetProductList = () => {
  const formSearchObj = useForm({ defaultValues: searchDefaultValues });
  const { open: openEdit, onToggle: onToggleEdit } = useModal();
  const [data, setData] = useState([]);

  const {
    dataSource: dataFull,
    onFetchFull,
    loading: isLoadingFull,
  } = useGetProductFull({ handleSubmit: formSearchObj.handleSubmit });

  const onOpenModalEdit = (record) => {
    setData(record);
    onToggleEdit({ record });
  };
  const formSearch = FORM_PRODUCT_SEARCH({ onEnter: onFetchFull });
  const columnsData = columns({ onOpenModalEdit });
  return {
    formSearch,
    formSearchObj,
    columnsData,
    dataFull,
    onFetchFull,
    isLoadingFull,
    openEdit,
    onToggleEdit,
    onOpenModalEdit,
    data,
  };
};
