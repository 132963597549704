import React, { useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { FORM_TRACKING } from '../form';
import { ContainerButton } from '../../../styles/global-style';
import { ButtonTheme, ModalTheme, RenderForm } from '../../../components';
import { TextLarge, TextSmall } from '../../../components/text';

export const UpdateTrackingModal = ({ open, onToggle, tableData, setTableData }) => {
  const { control, setValue, getValues, handleSubmit } = useForm({});

  useEffect(() => {
    setValue('trackingNumber', trackingNumber);
  }, [tableData]);

  const onSubmit = (values) => {
    setTableData((prevData) => {
      return prevData.map((item) =>
        item.claimCode === claimcode[0] ? { ...item, trackingNumber: values.trackingNumber } : item,
      );
    });
    onToggle({ defaultValue: '' });
  };
  const claimcode = tableData.map((item) => item.claimCode);
  const fullName = tableData.map((item) => item.fullName);
  const trackingNumber = tableData.map((item) => item.trackingNumber);
  return (
    <ModalTheme title=" " open={open} onToggle={onToggle}>
      <div style={{ width: '100%', textAlign: 'center' }}>
        <TextLarge text="แก้ไขเลขพัสดุ" />
      </div>
      <div style={{ width: '100%', textAlign: 'center' }}>
        <TextSmall text={claimcode + ' ' + fullName} />
      </div>
      <RenderForm
        control={control}
        setValue={setValue}
        getValues={getValues}
        forms={FORM_TRACKING()}
      />
      <ContainerButton right>
        <ButtonTheme useFor="SUBMIT" onClick={handleSubmit(onSubmit)} />
        <ButtonTheme useFor="CANCEL" onClick={onToggle} />
      </ContainerButton>
    </ModalTheme>
  );
};
