import { useMutation } from '@tanstack/react-query';
import { POST, POST_UPDATE_PRODUCT_LIST } from '../../../services';
import { openAlert } from '../../../components/alert/hooks';

export const useUpdateProductList = (onFetchFull) => {
  const { mutate, isPending } = useMutation({
    mutationFn: async (formData) => {
      const response = await POST(POST_UPDATE_PRODUCT_LIST(), formData);
      return response.item;
    },
    onSuccess: () => {
      openAlert({
        model: 'message',
        type: 'success',
        message: 'แก้ไขข้อมูลสินค้าสำเร็จ',
      });
      if (onFetchFull) {
        onFetchFull();
      }
    },
  });
  return { mutate, loading: isPending };
};
