export const FORM_USER_REGISTRATION_CLAIM = ({
  showOldAddress,
  watch,
  onEnter,
  addressFullOptions,
  provinceOptions,
  districtOptions,
  subDistrictOptions,
}) => {
  const span = 12;
  const SHOW_RADIO = [
    {
      name: 'isCreateNewAddress',
      label: 'ที่อยู่เดิม',
      type: 'RADIO',
      rules: {
        required: true,
      },
      span,
      defaultValue: 'false',
      properties: {
        options: [
          { label: 'ที่อยู่เดิม', value: 'false' },
          { label: 'เพิ่มที่อยู่ใหม่', value: 'true' },
        ],
      },
    },
  ];
  return [
    {
      name: 'telephone',
      label: 'เบอร์ติดต่อ (กรอกเบอร์ติดต่อแล้วกด Enter)',
      type: 'INPUT_PHONE',
      placeholder: 'กรุณากรอกหมายเลขโทรศัพท์',
      rules: {
        required: true,
        pattern: /^[0-9]{10}$/,
      },
      span,
      maxLength: 10,
      onEnter,
    },
    {
      name: 'fullName',
      label: 'ชื่อ-นามสกุล',
      type: 'INPUT',
      placeholder: 'กรุณากรอกชื่อ-นามสกุล',
      span,
      rules: {
        required: true,
      },
    },
    ...(showOldAddress ? SHOW_RADIO : []),
    ...(watch('isCreateNewAddress') !== 'true' && showOldAddress
      ? [
          {
            name: 'customerAddressId',
            label: 'เลือกที่อยู่',
            type: 'DROPDOWN',
            placeholder: 'กรุณาเลือก',
            properties: {
              options: addressFullOptions || [],
            },
            span: 24,
          },
        ]
      : []),
    {
      name: 'infoAddress',
      label: 'หากต้องการเพิ่มที่อยู่ใหม่กรอกรหัสไปรษณีย์แล้วกด Enter',
      type: 'TEXT',
      style: {
        textDecoration: 'underline',
      },
    },
    {
      name: 'zipcode',
      label: 'รหัสไปรษณีย์',
      type: 'INPUT',
      placeholder: 'กรุณากรอกรหัสไปรษณีย์',
      span,
      onEnter,
      rules: {
        required: true,
      },
    },
    {
      name: 'provinceId',
      label: 'จังหวัด',
      type: 'DROPDOWN',
      placeholder: 'กรุณาเลือกจังหวัด',
      span,
      properties: {
        options: provinceOptions || [],
      },
      rules: {
        required: true,
      },
    },
    {
      name: 'districtId',
      label: 'เขต/อำเภอ',
      type: 'DROPDOWN',
      placeholder: 'กรุณาเลือกเขต/อำเภอ',
      span,
      properties: {
        options: districtOptions || [],
      },
      rules: {
        required: true,
      },
      style: { width: '100%', height: '100%', minHeight: '32px' },
    },
    {
      name: 'subDistrictId',
      label: 'แขวง/ตำบล',
      type: 'DROPDOWN',
      placeholder: 'กรุณาเลือกแขวง/ตำบล',
      span,
      properties: {
        options: subDistrictOptions || [],
      },
      rules: {
        required: true,
      },
    },
    {
      name: 'other',
      label: 'เพิ่มเติม',
      type: 'INPUT',
      placeholder: '',
      span: 24,
    },
  ];
};
export const FORM_PRODUCT_REGISTRATION_CLAIM = ({ warrantyOptions, breakdownOptions }) => {
  const span = 24;
  const accept = [`image/jpeg`, 'image/png', 'image/jpg', 'image/bmp'];
  return [
    {
      name: 'warrantyId',
      label: 'เลือกสินค้าที่ต้องการเคลม',
      type: 'DROPDOWN',
      placeholder: '',
      span,
      properties: {
        options: warrantyOptions || [],
      },
      rules: {
        required: true,
      },
    },
    {
      name: 'breakdownId',
      label: 'อาการเสีย',
      type: 'DROPDOWN',
      placeholder: '',
      properties: {
        options: breakdownOptions || [],
      },
      span,
      rules: {
        required: true,
      },
    },
    {
      name: 'detail',
      label: 'รายละเอียด',
      type: 'INPUT',
      placeholder: '',
      span,
    },
    {
      name: 'claimImage',
      label: 'รูปภาพสินค้า (.png, .jpg, .jpeg, .bmp)',
      type: 'UPLOAD',
      accept: accept.join(','),
      listType: 'picture-card',
      span: 24,
      maxCount: 1,
      rules: {
        required: true,
      },
    },
    {
      name: 'receiptImage',
      label: 'รูปภาพสลิปค่าขนส่ง (.png, .jpg, .jpeg, .bmp)',
      type: 'UPLOAD',
      listType: 'picture-card',
      accept: accept.join(','),
      maxCount: 1,
    },
  ];
};
