import { ButtonTheme, ButtonToolTip } from '../../../components';
import {
  createColumnButtonArr,
  createColumnImage,
  createColumnNumber,
  createColumnTag,
  createColumnText,
  formatNumber,
  widthOptions,
} from '../../../components/table/function';
import {
  ALLOW_CLIAM_STATUS,
  ALLOW_WARRANTY_STATUS,
  PRODUCT_STATUS,
} from '../../../resources/status';
import { ProductTypesQuery } from '../../../services/react-query/optionMasterQuery';
import React from 'react';

export const columns = ({ onOpenModalEdit }) => {
  const { data: productTypesData = [] } = ProductTypesQuery();
  return [
    createColumnImage('รูปสินค้า', 'productImageUrl', widthOptions(150)),
    createColumnTag('สถานะสินค้า', { key: 'productStatus', icon: true }, PRODUCT_STATUS, {
      width: '150px',
      render: (_, record) => {
        const status = PRODUCT_STATUS?.find((e) => e.key === record?.productStatus);
        return (
          <div style={{ cursor: 'pointer' }}>
            <span style={{ color: status?.color }}>{status?.label}</span>
          </div>
        );
      },
      filters: PRODUCT_STATUS?.map((status) => ({
        text: status?.label,
        value: status?.key,
      })),
      onFilter: (value, record) => record?.productStatus === value,
      sorter: (a, b) => a.productStatus.localeCompare(b.productStatus),
    }),
    createColumnText('Code G', 'itemCode', widthOptions(150)),
    createColumnText('รหัสบาร์โค้ด', 'barcode', widthOptions(150)),
    createColumnTag(
      'ประเภทสินค้า',
      { key: 'productTypeId', text: 'productTypeNameTh', icon: true },
      productTypesData,
      {
        width: '150px',
        render: (_, record) => {
          const type = productTypesData.find((e) => e.value === record?.productTypeId);
          record.productTypeNameTh = type?.label || 'ไม่ระบุ';
          return <span>{type?.label || 'ไม่ระบุ'}</span>;
        },
        filters: productTypesData.map((type) => ({
          text: type.label,
          value: type.value,
        })),
        onFilter: (value, record) => record?.productTypeId === value,
      },
    ),
    createColumnText('ชื่อสินค้า', 'productFullName', widthOptions(150)),
    createColumnNumber(
      'วันรับประกัน',
      'warrantyPeriod',
      widthOptions(150, {
        render: (value) => {
          if (value > 300000) {
            return <div>ตลอดชีวิต</div>;
          } else {
            return formatNumber(value);
          }
        },
      }),
    ),
    createColumnTag(
      'สถานะ(ลงทะเบียน)',
      { key: 'allowWarranty', icon: true },
      ALLOW_WARRANTY_STATUS,
      {
        width: '150px',
        render: (_, record) => {
          const status = ALLOW_WARRANTY_STATUS?.find((e) => e.key === record?.allowWarranty);
          return (
            <div style={{ cursor: 'pointer' }}>
              <span style={{ color: status?.color }}>{status?.label}</span>
            </div>
          );
        },
        filters: ALLOW_WARRANTY_STATUS?.map((status) => ({
          text: status?.label,
          value: status?.key,
        })),
        onFilter: (value, record) => record?.allowWarranty === value,
        sorter: (a, b) => a.allowWarranty.localeCompare(b.allowWarranty),
      },
    ),
    createColumnTag('สถานะ(เคลม)', { key: 'allowClaim', icon: true }, ALLOW_CLIAM_STATUS, {
      width: '150px',
      render: (_, record) => {
        const status = ALLOW_CLIAM_STATUS?.find((e) => e.key === record?.allowClaim);
        return (
          <div style={{ cursor: 'pointer' }}>
            <span style={{ color: status?.color }}>{status?.label}</span>
          </div>
        );
      },
      filters: ALLOW_CLIAM_STATUS?.map((status) => ({
        text: status?.label,
        value: status?.key,
      })),
      onFilter: (value, record) => record?.allowClaim === value,
      sorter: (a, b) => a.allowClaim.localeCompare(b.allowClaim),
    }),
    createColumnButtonArr('จัดการ', 'rowNo', {
      others: {
        fixed: 'right',
        width: 180,
      },
      buttonArr: [
        {
          useFor: 'EDIT2',
          render: (_, record) => (
            <ButtonToolTip toolTip="แก้ไข" placement="topLeft">
              <ButtonTheme useFor="EDIT2" onClick={() => onOpenModalEdit(record)} />
            </ButtonToolTip>
          ),
        },
      ],
    }),
  ];
};
