import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { GET, GET_USER_PRIVILEGE } from '../../../services';
import { openAlert } from '../../../components/alert/hooks';

export const useGetUser = ({ handleSubmit, setSelectedRowKeys }) => {
  const [data, setData] = useState([]);
  const [userId, setUserId] = useState(null);

  const { isPending, mutate: onSearch } = useMutation({
    mutationFn: async (values) => {
      const response = await GET(GET_USER_PRIVILEGE(values.search));
      return response.result?.item || [];
    },
    onSuccess: (item) => {
      const activeRows = item?.privilegeRizzData
        ?.filter((row) => row?.status === 'ACTIVE')
        ?.map((row) => row?.id);
      setSelectedRowKeys(activeRows);
      setData(item?.privilegeRizzData || []);
      setUserId(item?.userId);
    },
    onError: (err) => {
      openAlert({
        model: 'message',
        type: 'error',
        message: err?.message || 'กรุณาเลือกข้อมูลให้ถูกต้อง',
      });
    },
  });

  const onFetch = () =>
    handleSubmit((values) => {
      return onSearch(values);
    })();

  return { dataSource: data, userId, setData, onSearch, loading: isPending, onFetch };
};
