import React, { useState } from 'react';
import { compareSortText, compareSortNumber, compareSortDate } from './columnSorter';
import { searchFilter } from '../searchfilter/SearchFilter';
import { ButtonTheme, ButtonToolTip } from '../../buttons/index.jsx';
import { ContainerButton } from '../../../styles/global-style';
import { color } from '../../../resources/color.js';
import { Modal } from 'antd';
import Notfound from '../../../assets/images/notFound.jpg';
const defaultOption = {
  skipExport: false,
  others: {},
};

export const widthOptions = (width, other) => ({
  others: {
    width,
    ...other,
  },
});

export const createColumnRowNumber = (title, optionsParam = {}) => {
  const options = { ...defaultOption, ...optionsParam };
  return {
    title,
    align: options.align || 'right',
    skipExport: options.skipExport,
    render: (_text, _record, index) => index + 1,
    ...options.others,
  };
};

export const createColumnText = (title, dataIndex, optionsParam = {}) => {
  const options = { ...defaultOption, ...optionsParam };
  const sorter = options.sorter ? options.sorter : (a, b) => compareSortText(a, b, dataIndex);
  const additionRender = options.noSearch ? {} : searchFilter(dataIndex);
  return {
    title,
    dataIndex,
    sorter: options.noSort ? null : sorter,
    align: options.align || 'left',
    skipExport: options.skipExport,
    ...additionRender,
    ...options.others,
  };
};

export const createColumnCenter = (title, dataIndex, optionsParam = {}) => {
  const options = { ...defaultOption, ...optionsParam };
  return {
    title,
    dataIndex,
    align: 'center',
    skipExport: options.skipExport,
    ...options.others,
  };
};

export const formatNumber = (value) => {
  if (value === null || value === undefined) return '';
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const createColumnNumber = (title, dataIndex, optionsParam = {}) => {
  const options = { ...defaultOption, ...optionsParam };
  const sorter = options.sorter ? options.sorter : (a, b) => compareSortNumber(a, b, dataIndex);
  const additionRender = options.noSearch ? {} : searchFilter(dataIndex);
  return {
    title,
    dataIndex,
    sorter: options.noSort ? null : sorter,
    align: options.align || 'right',
    skipExport: options.skipExport,
    ...additionRender,
    ...options.others,
  };
};

//TODO add create column commaNumber

export const createColumnDate = (title, dataIndex, optionsParam = {}) => {
  const options = { ...defaultOption, ...optionsParam };
  const sorter = options.sorter ? options.sorter : (a, b) => compareSortDate(a, b, dataIndex);
  const additionRender = options.noSearch ? {} : searchFilter(dataIndex);
  return {
    title,
    dataIndex,
    sorter: options.noSort ? null : sorter,
    align: options.align || 'center',
    skipExport: options.skipExport,
    ...additionRender,
    ...options.others,
  };
};

export const createColumnButton = (title, dataIndex, options = { others: {}, buttonArr: [] }) => {
  return {
    title,
    dataIndex,
    align: options.align || 'center',
    skipExport: true,
    render: (text, record) => {
      return (
        <ContainerButton center>
          {options.buttonArr.map((e, i) => (
            <ButtonTheme
              key={`action-${text}-${i}`}
              size="small"
              {...e}
              onClick={(event) => {
                e.onClick(text, record, event);
              }}
            />
          ))}
        </ContainerButton>
      );
    },
    ...options.others,
  };
};

export const createColumnButtonArr = (
  title,
  dataIndex,
  options = { others: {}, buttonArr: [] },
) => {
  return {
    title,
    dataIndex,
    align: options.align || 'center',
    skipExport: true,
    render: (text, record) => (
      <ContainerButton center>
        {options.buttonArr.map((e, i) =>
          e.render ? (
            e.render(text, record)
          ) : (
            <ButtonToolTip
              key={`action-${text}-${i}`}
              toolTip={e.title}
              {...e}
              onClick={(event) => {
                event.stopPropagation();
                e.onClick({ record, event });
              }}
            >
              {renderButtonType(e)}
            </ButtonToolTip>
          ),
        )}
      </ContainerButton>
    ),
    ...options.others,
  };
};

export const createColumnTag = (title, dataIndexObj, filterList = [], options = {}) => {
  const sorter = options.sorter
    ? options.sorter
    : (a, b) => compareSortText(a, b, dataIndexObj?.text);

  const filters = filterList
    .map((el) => ({
      text: el?.text || el?.label || el?.title,
      value: el?.value || el?.key,
    }))
    .filter((el) => el.value !== 'DEFAULT');

  return {
    title,
    dataIndex: dataIndexObj?.text,
    align: options.align || 'center',
    width: 120,
    skipExport: options.skipExport,
    sorter: options.noSort ? null : sorter,
    filters,
    onFilter: options?.onFilter
      ? options.onFilter
      : (value, record) => record[dataIndexObj?.key] === value,
    render: (_, record) => {
      const curIndex = filterList.findIndex(
        (el) => el?.key === record[dataIndexObj?.key] || el?.value === record[dataIndexObj?.key],
      );
      const current = filterList[curIndex];
      return (
        <div
          style={{
            color: current?.textColor || current?.color || current?.bgColor || color.clear,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '4px',
          }}
        >
          {current?.icon && (
            <span style={{ color: current?.iconColor || current?.color || current?.bgColor }}>
              {current.icon}
            </span>
          )}
          {record[dataIndexObj?.text] || current?.label}
        </div>
      );
    },
    ...options,
  };
};
import { useCallback } from 'preact/hooks';

export const createColumnImage = (title, dataIndex, optionsParam = {}) => {
  const options = { ...defaultOption, ...optionsParam };

  return {
    title,
    dataIndex,
    align: 'center',
    skipExport: options.skipExport,
    width: options.width || 150,
    render: (imageUrl) => <ImageWithModal imageUrl={imageUrl} options={options} />,
    ...options.others,
  };
};

const ImageWithModal = ({ imageUrl, options }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleOpenModal = useCallback(() => setIsModalVisible(true), []);
  const handleCloseModal = useCallback(() => setIsModalVisible(false), []);

  const displayImage = imageUrl || Notfound;
  const fileName = imageUrl ? decodeURIComponent(imageUrl.split('/').pop()) : 'ไม่มีรูป';

  return (
    <>
      <img
        src={displayImage}
        alt="รูปสินค้า"
        style={{
          width: options.widthImg || '100px',
          height: options.heightImg || '100px',
          borderRadius: options.borderRadius || '8px',
          objectFit: 'cover',
          cursor: 'pointer',
        }}
        onClick={handleOpenModal}
      />

      {isModalVisible && (
        <Modal
          open={isModalVisible}
          onCancel={handleCloseModal}
          footer={null}
          centered
          destroyOnClose
        >
          <div
            style={{
              textAlign: 'left',
              fontWeight: 'bold',
              fontSize: '16px',
            }}
          >
            {fileName}
          </div>
          <img
            src={displayImage}
            alt="รูปสินค้า"
            style={{
              width: '100%',
              height: 'auto',
              marginTop: '5px',
            }}
          />
        </Modal>
      )}
    </>
  );
};
