import { useMutation } from '@tanstack/react-query';
import { GET, GET_CLAIM_TRACKING_DETAIL } from '../../../services';
import { openAlert } from '../../../components/alert/hooks';

export const useGetInfoClaimData = ({ setValue }) => {
  const { mutate, isPending } = useMutation({
    mutationFn: async (value) => {
      const response = await GET(GET_CLAIM_TRACKING_DETAIL(value));
      return response.result;
    },
    onSuccess: (result) => {
      openAlert({
        type: 'success',
        model: 'message',
        message: 'ค้นหาเลขที่เคลมสำเร็จ',
      });

      Object.entries(result.claim || {}).forEach(([key, value]) => {
        if (key !== 'shippingDate') {
          setValue(key, value ?? '');
        }
      });
    },
  });
  return { mutate, loading: isPending };
};
