import { useMutation } from '@tanstack/react-query';
import { POST, POST_SEND_LINE_USER } from '../../../services';
import { openAlert } from '../../../components/alert/hooks';

export const useSendLineUser = ({ onSuccessCallBack }) => {
  const { mutate, isPending } = useMutation({
    mutationFn: async (payload) => {
      const response = await POST(POST_SEND_LINE_USER(), payload);
      onSuccessCallBack && onSuccessCallBack(response);
      return response.item;
    },
    onSuccess: () => {
      openAlert({
        model: 'message',
        type: 'success',
        message: 'ส่งข้อความสำเร็จ',
      });
    },
    onError: (error) => {
      openAlert({
        model: 'message',
        type: 'error',
        message: error?.message || 'ส่งข้อความไม่สำเร็จ',
      });
    },
  });
  return { mutate, loading: isPending };
};
