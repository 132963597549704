import { Popconfirm, Tag } from 'antd';

import React from 'react';

import {
  createColumnButtonArr,
  createColumnText,
  widthOptions,
} from '../../../components/table/function';
import { color } from '../../../resources';
import { ButtonTheme, ButtonToolTip } from '../../../components';

export const columns = ({ handleDelete, onOpenTracking }) => {
  return [
    createColumnText('เลขที่เคลม', 'claimCode', widthOptions(150)),
    createColumnText('ชื่อ-นามสกุล', 'fullName', widthOptions(150)),
    createColumnText('เบอร์ติดต่อ', 'telephone', widthOptions(150)),
    createColumnText('รหัสสินค้า', 'itemCode', widthOptions(140)),
    createColumnText('ชื่อสินค้า', 'productName', widthOptions(150)),
    createColumnText(
      'เลขพัสดุ',
      'trackingNumber',
      widthOptions(150, {
        render: (text, record) => {
          const handleClick = () => {
            onOpenTracking(record);
          };
          if (record.trackingNumber) {
            return (
              <div style={{ cursor: 'pointer' }}>
                <Tag color={color?.blueFocus} onClick={handleClick}>
                  {text}
                </Tag>
              </div>
            );
          }
        },
      }),
    ),
    createColumnButtonArr('จัดการ', 'rowNo', {
      others: {
        fixed: 'right',
        width: 150,
      },
      buttonArr: [
        {
          useFor: 'DELETE',
          render: (text, record) => {
            return (
              <ButtonToolTip toolTip="ลบ" placement="topLeft">
                <Popconfirm
                  title="คุณต้องการลบหรือไม่?"
                  onConfirm={() => handleDelete(record)}
                  okText="ใช่"
                  cancelText="ไม่"
                >
                  <ButtonTheme useFor="DELETE" />
                </Popconfirm>
              </ButtonToolTip>
            );
          },
        },
      ],
    }),
  ];
};
export const exportHeaders = [
  { dataIndex: 'claimCode', title: 'เลขที่เคลม' },
  { dataIndex: 'fullName', title: 'ชื่อ-นามสกุล' },
  { dataIndex: 'telephone', title: 'เบอร์โทร' },
  { dataIndex: 'itemCode', title: 'รหัสสินค้า' },
  { dataIndex: 'productName', title: 'ชื่อสินค้า' },
  { dataIndex: 'trackingNumber', title: 'เลขพัสดุ' },
];
