import React, { Fragment, useState } from 'react';
import { InboxOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { useController } from 'react-hook-form';
import { Button, Upload, Modal, Table } from 'antd';
import { Required, RowComponent } from '../../../styles/global-style';
import { TextXSMall, TextInputLabel } from '../../text';
import { UploadOutlined } from '@ant-design/icons';
import { renderTypeError } from '..';
import * as XLSX from 'xlsx';
import { color } from '../../../resources';

const UploadFiles = ({ control, item, setValue, getValues }) => {
  const { rules, name, defaultValue, label, disabled, ...propsInput } = item;
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');

  const { fieldState, field } = useController({
    control,
    name,
    rules,
    defaultValue,
  });
  const { error } = fieldState;
  const { value } = field;

  const onChange = (event) => {
    setValue(name, event.fileList);
  };

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  return (
    <Fragment>
      <RowComponent>
        {label && <TextInputLabel text={label} />}
        {rules && rules.required && <Required>*</Required>}
      </RowComponent>
      <Upload
        id={name}
        beforeUpload={() => false}
        listType={item.listType || 'picture'} /** picture-card, picture */
        disabled={disabled || false}
        maxCount={item.maxCount || 1}
        multiple={item.maxCount > 1 || false}
        accept={item.accept || '*'}
        onPreview={handlePreview}
        fileList={getValues(name)}
        onChange={onChange}
        {...propsInput}
      >
        {value?.length === item.maxCount ? null : item.listType !== 'picture-card' ? (
          <Button icon={<UploadOutlined />} {...dataCy}>
            {item.placeholder || 'Upload'}
          </Button>
        ) : (
          uploadButton
        )}
      </Upload>
      <Modal
        destroyOnClose={true}
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img
          alt="example"
          style={{
            width: '100%',
          }}
          src={previewImage}
        />
      </Modal>
      {error && <TextXSMall text={renderTypeError(item, error)} color="red" />}
    </Fragment>
  );
};

export const MemoizedUpload = UploadFiles; //memo(, compareRender);

const PreviewFiles = ({ control, item, getValues }) => {
  const { rules, name, defaultValue, label, disabled, ...propsInput } = item;
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');

  const { fieldState } = useController({
    control,
    name,
    rules,
    defaultValue,
  });
  const { error } = fieldState;

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };

  const handleCancel = () => setPreviewOpen(false);

  return (
    <Fragment>
      <RowComponent>
        {label && <TextInputLabel text={label} />}
        {rules && rules.required && <Required>*</Required>}
      </RowComponent>
      <Upload
        id={name}
        listType={item.listType || 'picture-card'}
        disabled={disabled || false}
        accept={item.accept || '*'}
        onPreview={handlePreview}
        fileList={getValues(name) || []}
        showUploadList={{
          showPreviewIcon: true,
          showRemoveIcon: false,
        }}
        {...propsInput}
      />
      <Modal
        destroyOnClose={true}
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img
          alt="example"
          style={{
            width: '100%',
          }}
          src={previewImage}
        />
      </Modal>
      {error && <TextXSMall text={renderTypeError(item, error)} color="red" />}
    </Fragment>
  );
};

export const MemoizedPreview = PreviewFiles;

const { Dragger } = Upload;

const DraggerUploadFiles = ({ control, item, setValue, getValues }) => {
  const { rules, name, defaultValue, label, disabled, ...propsInput } = item;

  const { fieldState, field } = useController({
    control,
    name,
    rules,
    defaultValue,
  });

  const { error } = fieldState;
  const [previewData, setPreviewData] = useState([]);
  const [previewColumns, setPreviewColumns] = useState([]);
  const [fileUploaded, setFileUploaded] = useState(false);

  const onChange = ({ fileList }) => {
    if (fileList.length > 0) {
      setValue(name, fileList);
      handlePreview(fileList[0]);
      setFileUploaded(true);
    } else {
      setValue(name, []);
      setFileUploaded(false);
    }
  };

  const onRemove = () => {
    setValue(name, []);
    setPreviewData([]);
    setPreviewColumns([]);
    setFileUploaded(false);
  };

  const handlePreview = async (file) => {
    if (!file.originFileObj) return;

    const reader = new FileReader();
    reader.readAsBinaryString(file.originFileObj);
    reader.onload = (event) => {
      try {
        const binaryStr = event.target.result;
        const workbook = XLSX.read(binaryStr, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const data = XLSX.utils.sheet_to_json(sheet, { header: 1 });

        if (data.length > 0) {
          const columns = data[0].slice(0, 4).map((col, index) => ({
            title: col || `Column ${index + 1}`,
            dataIndex: `col${index}`,
            key: `col${index}`,
          }));

          const formattedData = data.slice(1).map((row, rowIndex) => {
            let rowData = { key: rowIndex };
            row.slice(0, 4).forEach((cell, colIndex) => {
              rowData[`col${colIndex}`] = cell;
            });
            return rowData;
          });

          setPreviewColumns(columns);
          setPreviewData(formattedData);
        }
      } catch (error) {
        console.error('Error reading Excel file:', error);
      }
    };
  };

  return (
    <>
      <div style={{ marginBottom: '12px' }}>
        <RowComponent>
          {label && <TextInputLabel text={label} />}
          {rules?.required && <Required>*</Required>}
        </RowComponent>
      </div>

      {!fileUploaded ? (
        <Dragger
          id={name}
          beforeUpload={() => false}
          disabled={disabled}
          maxCount={1}
          multiple={false}
          accept=".xlsx"
          onChange={onChange}
          onRemove={onRemove}
          showUploadList={{
            showRemoveIcon: true,
            showPreviewIcon: false,
          }}
          {...propsInput}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">ลากไฟล์มาวางที่นี่ หรือคลิกเพื่อเลือกไฟล์</p>
          <p className="ant-upload-hint">รองรับไฟล์ .xlsx เท่านั้น</p>
        </Dragger>
      ) : (
        <>
          <Table
            dataSource={previewData}
            columns={previewColumns}
            pagination={{ pageSize: 4 }}
            scroll={{ x: 'max-content' }}
          />
          <Button
            type="primary"
            icon={<DeleteOutlined />}
            onClick={onRemove}
            style={{ background: color.blackFocus }}
          >
            อัปโหลดไฟล์ใหม่
          </Button>
        </>
      )}

      {error && <TextXSMall text={renderTypeError(item, error)} color="red" />}
    </>
  );
};

export const MemoizedDraggerUpload = DraggerUploadFiles;
