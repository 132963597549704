import { TextLarge } from '../../../components/text';
import React from 'react';
import { CardTheme } from '../../../components/card';
import { StyledContainer } from '../../../components/container/styled';
import { ButtonTheme, RenderForm } from '../../../components';
import { FORM_PRODUCT_REGISTRATION_CLAIM, FORM_USER_REGISTRATION_CLAIM } from './form';
import { useGetClaimAddList } from './hooks/useGetClaimAddList';
import { Col, Row, Spin } from 'antd';
import { ContainerButton } from '../../../styles/global-style';

export const ClaimAdd = () => {
  const {
    control,
    setValue,
    getValues,
    fullName,
    onEnterHandler,
    watch,
    onChangeAddress,
    addressFullOptions,
    provinceOptions,
    districtOptions,
    subDistrictOptions,
    breakdownOptions,
    warrantyOptions,
    handleSubmit,
    onSubmit,
    isLoadingToSave,
    isLoadingTele,
    isLoadingZipCode,
    addressData,
  } = useGetClaimAddList();
  return (
    <Spin spinning={isLoadingToSave || isLoadingTele || isLoadingZipCode}>
      <StyledContainer>
        <TextLarge text="ยื่นเรื่องเคลม" />
        <Row gutter={[24, 24]}>
          <Col span={14}>
            <CardTheme>
              <TextLarge text="ข้อมูลส่วนตัว" />
              <RenderForm
                control={control}
                setValue={setValue}
                getValues={getValues}
                onChange={onChangeAddress}
                forms={FORM_USER_REGISTRATION_CLAIM({
                  showOldAddress: !!fullName && addressData !== null,
                  watch,
                  onEnter: onEnterHandler,
                  addressFullOptions,
                  provinceOptions,
                  districtOptions,
                  subDistrictOptions,
                })}
              />
              <ContainerButton center>
                <ButtonTheme useFor="SUBMIT" title="บันทึก" onClick={handleSubmit(onSubmit)} />
              </ContainerButton>
            </CardTheme>
          </Col>
          <Col span={10}>
            <CardTheme>
              <TextLarge text="ข้อมูลการแจ้งเคลม" />
              <RenderForm
                control={control}
                setValue={setValue}
                getValues={getValues}
                forms={FORM_PRODUCT_REGISTRATION_CLAIM({ breakdownOptions, warrantyOptions })}
              />
            </CardTheme>
          </Col>
        </Row>
      </StyledContainer>
    </Spin>
  );
};
