import { useMutation } from '@tanstack/react-query';
import { GET, GET_EXAMPLE_TEMPLATE } from '../../../services';
import { openAlert } from '../../../components/alert/hooks';

export const useDownloadTemplate = () => {
  const { mutate, isPending } = useMutation({
    mutationFn: async () => {
      try {
        const response = await GET(GET_EXAMPLE_TEMPLATE(), undefined, { responseType: 'blob' });

        const url = window.URL.createObjectURL(response);

        const a = document.createElement('a');
        a.href = url;
        a.download = 'example_template.xlsx';

        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);

        return response;
      } catch (error) {
        throw error;
      }
    },
    onSuccess: () => {
      openAlert({
        type: 'success',
        model: 'message',
        message: 'ดาวน์โหลดเทมเพลตสำเร็จ',
      });
    },
  });
  return { mutate, loading: isPending };
};
