import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useGetClaimEdit } from './useGetClaimEdit';
import { useZipcode } from './useZipcodeQuery';
import { useOptionZipcode } from './useOptionZipcode';
import { UpdateClaimEdit } from '../../../../resources/payload';
import { onUploadFile } from './onUploadFile';

export const useGetClaimEditList = () => {
  const { control, getValues, setValue, reset, watch, handleSubmit } = useForm();
  const [timelineList, setTimelineList] = useState();
  const [claimData, setClaimData] = useState();
  const [claimEditData, setClaimEditData] = useState();
  const [state, setState] = useState();
  const [breakdownList, setBreakdownList] = useState();

  const queryParams = new URLSearchParams(window.location.search);
  const claimCode = queryParams.get('code');

  const { mutate: onCallZipCode, loading: isLoadingZipCode } = useZipcode({ reset, getValues });
  const { mutate: onEditClaim, loading: isloadingToEdit } = onUploadFile();
  const { provinceOptions, districtOptions, subDistrictOptions } = useOptionZipcode({
    watch,
  });

  const onEnterZipcode = (value) => {
    onCallZipCode(value, {
      onSuccess: (result) => {
        reset({
          ...getValues(),
          ...result,
          provinceId: '',
          districtId: '',
          subDistrictId: '',
          other: '',
        });
      },
    });
  };

  const { dataSource, loading: LoadingClaimEdit } = useGetClaimEdit({
    claimCode: state?.claimCode,
    reset,
    setValue,
    getValues,
  });
  useEffect(() => {
    if (claimCode) {
      setState({ claimCode });
    }
    if (dataSource) {
      setClaimEditData(dataSource);
    }
    if (claimEditData) {
      setClaimData(claimEditData?.claim);
      if (claimData?.remainingDays > 300000) {
        setValue('remainingDays', 'ตลอดชีวิต');
      }
      updateTimeline(claimEditData);
    }

    const autoCallZipCode = (values) => {
      if (values) {
        onCallZipCode(values);
      }
    };

    autoCallZipCode(claimEditData?.claim?.zipCode);
    const breakdown =
      claimEditData?.breakdowns?.map((item) => ({
        label: item?.breakdownNameTh,
        value: item?.breakdownId,
      })) ?? [];

    setBreakdownList(breakdown);
  }, [claimCode, dataSource, claimEditData, setValue, claimData]);

  const updateTimeline = () => {
    if (claimEditData?.timelineList) {
      const timeline = claimEditData.timelineList.map((item) => ({
        text: item?.optionStatusNameTh,
        color: item?.optionStatusCode === claimEditData?.claim.claimStatus ? 'green' : 'black',
      }));
      setTimelineList(timeline);
    }
  };

  const onSubmit = () =>
    handleSubmit((values) => {
      const formData = UpdateClaimEdit(values);
      onEditClaim(formData);
    })();

  return {
    control,
    getValues,
    setValue,
    timelineList,
    LoadingClaimEdit,
    claimData,
    onEnterZipcode,
    provinceOptions,
    districtOptions,
    subDistrictOptions,
    breakdownList,
    onSubmit,
    handleSubmit,
    isloadingToEdit,
    isLoadingZipCode,
  };
};
