export const FORM_PRODUCT_SEARCH = ({ onEnter }) => {
  return [
    {
      name: 'search',
      label: 'ค้นหารายการ (กรอกข้อมูลแล้วกด Enter เพื่อค้นหา)',
      type: 'INPUT',
      placeholder: 'ค้นหาข้อมูลสินค้า',
      span: 16,
      helper: 'Tips : สามารถค้นหาด้วย Code G, รหัสบาร์โค้ด, และชื่อสินค้า',
      onEnter,
    },
  ];
};
export const searchDefaultValues = {
  search: '',
};
export const FORM_EDIT_PRODUCT = ({ productTypeList, productStatus }) => {
  const accept = [`image/jpeg`, 'image/png', 'image/jpg', 'image/bmp'];
  return [
    {
      name: 'productId',
      type: 'HIDDEN',
    },
    {
      name: 'productTypeId',
      label: 'ประเภทสินค้า',
      type: 'DROPDOWN',
      placeholder: '',

      properties: {
        options: productTypeList || [],
      },
      rules: {
        required: true,
      },
    },
    {
      name: 'itemCode',
      label: 'Code G',
      type: 'INPUT',
      placeholder: '',

      rules: {
        required: true,
      },
    },
    {
      name: 'barcodeShortcut',
      label: 'Barcode',
      type: 'INPUT_BARCODE',
      placeholder: '',
      rules: {
        required: true,
        pattern: /^[0-9]{9}$/,
      },
      maxLength: 9,
    },
    {
      name: 'productFullName',
      label: 'ชื่อสินค้า',
      type: 'INPUT',
      placeholder: '',

      rules: {
        required: true,
      },
    },
    {
      name: 'warrantyPeriod',
      label: 'วันรับประกัน',
      type: 'INPUT',
      placeholder: '',
    },
    {
      name: 'allowWarranty',
      label: 'สถานะ(ลงทะเบียน)',
      type: 'SWITCH',
      placeholder: '',
    },
    {
      name: 'allowClaim',
      label: 'สถานะ(เคลม)',
      type: 'SWITCH',
      placeholder: '',
    },
    {
      name: 'productStatus',
      label: 'สถานะสินค้า',
      type: 'DROPDOWN',
      placeholder: '',
      properties: {
        options: productStatus || [],
      },
    },
    {
      name: 'productImageUrl',
      label: 'รูปสินค้า',
      type: 'UPLOAD',
      listType: 'picture-card',
      accept: accept.join(','),
      maxCount: 1,
      properties: {
        defaultFileList: productTypeList ? productTypeList : [],
      },
    },
  ];
};
