import { SpaceBetween, StyledContainer } from '../../components/container/styled';
import { ResponsivePageLayout } from '../../components/page/responsive-layout/ResponsivePageLayout';
import { TextLarge } from '../../components/text';
import React from 'react';
import { Icon } from '../../resources';
import { CardTheme } from '../../components/card';
import { useGetUserInfoList } from './hooks/useGetUserInfoList';
import { ContainerButton } from '../../styles/global-style';
import ButtonExcelEmbedded from '../../components/report-excel/ButtonExcelEmbedded';
import { SendLineUserModal } from './modals/SendLineUserModal';
import { EditUserDataModal } from './modals/EditUserDataModal';

export const UserInfo = () => {
  const {
    formSearch,
    formSearchObj,
    dataFull,
    isLoadingFull,
    onFetchFull,
    columnsData,
    openLineUser,
    onToggleLineUser,
    data,
    openEditData,
    onToggleEditData,
  } = useGetUserInfoList();
  return (
    <StyledContainer>
      <TextLarge text="ข้อมูลลูกค้า" />
      <CardTheme>
        <ResponsivePageLayout
          searchLayout={{
            title: 'ค้นหาข้อมูลลูกค้า',
            icon: <Icon.todo />,
            formSearch,
            useFormParam: formSearchObj,
            onSearch: onFetchFull,
            ignoreDateEmpty: true,
            onClear: formSearchObj.reset,
          }}
          tableLayout={{
            columns: columnsData,
            dataSource: dataFull,
            loading: isLoadingFull,
            hasPagination: true,
            extraContent: (
              <SpaceBetween>
                <ContainerButton right>
                  <ButtonExcelEmbedded
                    title="Export"
                    header={columnsData}
                    data={dataFull}
                    filename="รายงานข้อมูลลูกค้า"
                  />
                </ContainerButton>
              </SpaceBetween>
            ),
          }}
        />
      </CardTheme>
      {openLineUser && (
        <SendLineUserModal
          open={openLineUser}
          onToggle={onToggleLineUser}
          initialValues={data}
          onFetch={onFetchFull}
        />
      )}
      {openEditData && (
        <EditUserDataModal
          open={openEditData}
          onToggle={onToggleEditData}
          initialValues={data}
          onFetch={onFetchFull}
        />
      )}
    </StyledContainer>
  );
};
