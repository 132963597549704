import {
  createColumnDate,
  createColumnText,
  widthOptions,
} from '../../../components/table/function';

export const columns = () => {
  return [
    createColumnText('ชื่อ-นามสกุล', 'userFullName', widthOptions(150)),
    createColumnText('รหัสลงทะเบียน', 'warrantyCode', widthOptions(150)),
    createColumnText('ผู้ทำรายการ', 'logBy', widthOptions(150)),
    createColumnText('จำนวนที่เปลี่ยน', 'totalChanges', widthOptions(150)),
    createColumnText('วันที่เปลี่ยน', 'logDate', widthOptions(150)),
  ];
};

export const columnsExpandableBefore = () => {
  return [
    createColumnText('รหัสสินค้า', 'itemCode', widthOptions(150)),
    createColumnText('ชื่อสินค้า', 'productName', widthOptions(150)),
    createColumnDate('รุ่น', 'mobileModelName', widthOptions(150)),
    createColumnText('ยี่ห้อ', 'mobileBrandName', widthOptions(150)),
  ];
};
export const columnsExpandableAfter = () => {
  return [
    createColumnText('รหัสสินค้า', 'itemCode', widthOptions(150)),
    createColumnText('ชื่อสินค้า', 'productName', widthOptions(150)),
    createColumnDate('รุ่น', 'mobileModelName', widthOptions(150)),
    createColumnText('ยี่ห้อ', 'mobileBrandName', widthOptions(150)),
    createColumnText('หมายเหตุ', 'remark', widthOptions(150)),
  ];
};
