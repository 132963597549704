import React, { useEffect } from 'react';
import { ButtonTheme, ModalTheme, RenderForm } from '../../../../components';
import { TextLarge } from '../../../../components/text';
import { ContainerButton } from '../../../../styles/global-style';
import { FORM_CLAIM_UPDATE_PRODUCT } from '../form';
import { useUpdateProduct } from '../hooks/useUpdateProduct';
import { useBarcodeQuery } from '../hooks/useGetBarcodeQuery';
import { Spin } from 'antd';
import { useGetClaimViewList } from '../hooks/useGetClaimViewList';

export const UpdateProductModal = ({ open, onToggle, claimData }) => {
  const {
    control,
    setValue,
    getValues,
    LoadingClaimView,
    handleSubmit,
    reset,
    mobileBrandOptions,
  } = useGetClaimViewList();
  const { mutate: updateProduct, loading: isLoadingProduct } = useUpdateProduct();
  const { mutate: onCallBarcode, loading: isLoadingBarcode } = useBarcodeQuery({
    reset,
    getValues,
  });

  useEffect(() => {
    claimData?.claimId && setValue('claimId', claimData.claimId);
  }, [claimData, setValue]);

  const onSubmit = (values) => {
    updateProduct(values);
    onToggle({ defaultValue: '' });
  };
  const handleOnEnterBarcode = (value) => {
    onCallBarcode(value);
  };

  return (
    <Spin spinning={LoadingClaimView || isLoadingBarcode || isLoadingProduct}>
      <ModalTheme title=" " open={open} onToggle={onToggle}>
        <div style={{ width: '100%', textAlign: 'left' }}>
          <TextLarge text="เปลี่ยนสินค้า" />
        </div>
        <RenderForm
          control={control}
          setValue={setValue}
          getValues={getValues}
          forms={FORM_CLAIM_UPDATE_PRODUCT({ onEnter: handleOnEnterBarcode, mobileBrandOptions })}
        />
        <ContainerButton right>
          <ButtonTheme useFor="SUBMIT" onClick={handleSubmit(onSubmit)} />
          <ButtonTheme useFor="CANCEL" onClick={onToggle} />
        </ContainerButton>
      </ModalTheme>
    </Spin>
  );
};
