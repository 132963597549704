import { useMutation } from '@tanstack/react-query';
import { GET, GET_CUSTOMERS_VERIFY_TEL } from '../../../../services';
import { openAlert } from '../../../../components/alert/hooks';

export const useTeleQuery = ({ reset, getValues }) => {
  const { mutate, isPending } = useMutation({
    mutationFn: async (value) => {
      const response = await GET(GET_CUSTOMERS_VERIFY_TEL(value));
      return response?.result;
    },
    onSuccess: (result) => {
      openAlert({
        type: 'success',
        message: 'ค้นหาเบอร์โทรศัพท์สำเร็จ',
        model: 'message',
      });
      const { customer = {}, addresses: address = '' } = result;
      const { fullName = '', customerCode = '' } = customer;

      const telephone = getValues('telephone');

      const warrantyId = 0;

      reset({
        ...getValues(),
        telephone,
        fullName,
        customerCode,
        address,
        warrantyId,
        ...result,
      });
    },
  });
  return { mutate, loading: isPending };
};
