import { convertYearInputToPayload } from '../../functions/convert';

export const InsertWarranty = (value) => {
  return {
    invImage: value.invImage ? value.invImage.originFileObj : null,
    packageImage: value.packageImage ? value.packageImage.originFileObj : null,
    telephone: value.telephone,
    fullName: value.fullName,
    mobileBrandId: value.mobileBrandId,
    mobileModelName: value.mobileModelName,
    productId: value.productId,
    channelBuyId: value.channelBuyId,
  };
};
export const UpdateWarrantyEdit = (values) => {
  const formData = new FormData();

  formData.append('warrantyId', values.warrantyId);
  formData.append('telephone', values.telephone);
  formData.append('fullName', values.fullName);
  formData.append('mobileBrandId', values.mobileBrandId);
  formData.append('mobileModelName', values.mobileModelName);
  formData.append('channelBuyId', values.channelBuyId);
  formData.append('productId', values.productId);

  if (values.invImageUrl && values.invImageUrl.length > 0) {
    const file = values.invImageUrl[0];
    if (file.originFileObj) {
      formData.append('invImage', file.originFileObj);
    } else if (file.url) {
      formData.append('invImageUrl', file.url);
    }
  }

  if (values.packageImageUrl && values.packageImageUrl.length > 0) {
    const file = values.packageImageUrl[0];
    if (file.originFileObj) {
      formData.append('packageImage', file.originFileObj);
    } else if (file.url) {
      formData.append('packageImageUrl', file.url);
    }
  }

  return formData;
};

export const updateExpired = (values) => {
  return {
    warrantyId: values.warrantyId,
    expireDate: values.expireDate,
    remark: values.remark,
  };
};
export const InsertClaim = (value) => {
  return {
    claimImage: value.claimImage ? value.claimImage.originFileObj : null,
    receiptImage: value.receiptImage ? value.receiptImage.originFileObj : null,
    warrantyId: value.warrantyId,
    customerCode: value.customerCode,
    detail1: value.detail1,
    detail2: value.detail2,
    breakdownId: value.breakdownId,
    addressLine1: value.addressLine1,
    addressLine2: value.addressLine2,
    subDistrictId: value.subDistrictId,
    zipcode: value.zipcode,
    other: value.other,
    isCreateNewAddress: value.isCreateNewAddress,
  };
};
export const InsertTracking = (value) => {
  return {
    claimId: value.claimId,
    claimCode: value.claimCode,
    trackingNumber: value.trackingNumber,
    shippingId: value.shippingId,
  };
};
export const cancelClaim = (value) => {
  return {
    claimId: value.claimId,
    cancelReason: value.cancelReason,
  };
};
export const approveClaim = (value) => {
  return {
    claimIdList: value.claimIdList,
  };
};
export const noApproveClaim = (value) => {
  return {
    claimId: value.claimId,
    rejectReason: value.rejectReason,
  };
};
export const UpdateProcessStatus = (value) => {
  return {
    claimId: value.claimId,
    processStatus: value.processStatus,
  };
};
export const SendLineMsg = (value) => {
  return {
    claimId: value.claimId,
    message: value.message,
  };
};
export const SendLineUser = (value) => {
  return {
    customerId: value.customerId,
    message: value.message,
  };
};
export const UpdateTel = (value) => {
  return {
    customerId: value.customerId,
    telephone: value.telephone,
  };
};
export const UpdateRemark = (value) => {
  return {
    claimId: value.claimId,
    remark: value.remark,
  };
};
export const UpdateUserInfo = (value) => {
  return {
    customerId: value.customerId,
    fullName: value.fullName,
    birthday: value.birthday,
    customerLevel: 'GENERAL',
    email: value.email,
  };
};

export const UpdateTracking = (value) => {
  return {
    claimId: value.claimId,
    shippingId: value.shippingId,
    trackingNumber: value.trackingNumber,
    shippingDate: convertYearInputToPayload(value.shippingDate),
  };
};

export const updateProduct = (value) => {
  return {
    claimId: value.claimId,
    claimCode: value.claimCode,
    mobileBrandId: value.mobileBrandId,
    mobileModelName: value.mobileModelName,
    productId: value.productId,
    remark: value.remark,
  };
};

export const UpdateClaimEdit = (values) => {
  const formData = new FormData();

  formData.append('claimId', values.claimId);
  formData.append('customerCode', values.customerCode);
  formData.append('detail1', values.detail1);
  formData.append('detail2', values.detail2);
  formData.append('breakdownId', values.breakdownId);
  formData.append('addressLine1', values.addressLine1);
  formData.append('addressLine2', values.addressLine2);
  formData.append('subDistrictId', values.subDistrictId);
  formData.append('zipCode', values.zipCode);
  formData.append('other', values.other);

  if (values?.claimImageUrl && values?.claimImageUrl.length > 0) {
    const file = values?.claimImageUrl[0];
    if (file.originFileObj) {
      formData.append('claimImage', file.originFileObj);
    } else if (file.url) {
      formData.append('claimImageUrl', file.url);
    }
  }

  if (values?.receiptImageUrl && values?.receiptImageUrl.length > 0) {
    const file = values?.receiptImageUrl[0];
    if (file.originFileObj) {
      formData.append('receiptImage', file.originFileObj);
    } else if (file.url) {
      formData.append('receiptImageUrl', file.url);
    }
  }

  return formData;
};
export const UpdateExcel = (values) => {
  const formData = new FormData();

  formData.append('shippingId', values.shippingId);
  if (values?.excelFile && values.excelFile.length > 0) {
    const file = values.excelFile[0];
    if (file.originFileObj) {
      formData.append('excelFile', file.originFileObj);
    }
  }

  return formData;
};
export const updatePermission = ({ userId, selectedRowKeys, dataSource }) => {
  return {
    userId: userId,
    privilegeRizzData: dataSource?.map((item) => {
      return {
        code: item.code,
        status: selectedRowKeys.includes(item.id) ? 'ACTIVE' : 'INACTIVE',
      };
    }),
  };
};
export const UpdateProductList = (values) => {
  const formData = new FormData();

  formData.append('productId', values.productId);
  formData.append('productTypeId', values.productTypeId);
  formData.append('barcodeShortcut', values.barcodeShortcut);
  formData.append('productFullName', values.productFullName);
  formData.append('itemCode', values.itemCode);
  formData.append('productStatus', values.productStatus);
  formData.append('warrantyPeriod', values.warrantyPeriod);
  formData.append('remark', values.remark);
  formData.append('allowWarranty', values.allowWarranty);
  formData.append('allowClaim', values.allowClaim);

  if (values?.productImageUrl && values?.productImageUrl.length > 0) {
    const file = values?.productImageUrl[0];
    if (file.originFileObj) {
      formData.append('productImage', file.originFileObj);
    } else if (file.url) {
      formData.append('productImageUrl', file.url);
    }
  }
  return formData;
};
