export const handleSubmitForm = (values, onAddClaim) => {
  const uploadData = {
    claimImage: values.claimImage ? values.claimImage[0] : null,
    receiptImage: values.receiptImage ? values.receiptImage[0] : null,
    warrantyId: values.warrantyId,
    customerCode: values.customerCode,
    detail1: values.detail1,
    detail2: values.detail2,
    breakdownId: values.breakdownId,
    addressLine1: values.addressLine1,
    addressLine2: values.addressLine2,
    subDistrictId: values.subDistrictId,
    zipcode: values.zipcode,
    other: values.other,
    isCreateNewAddress: values.isCreateNewAddress,
  };

  onAddClaim(uploadData);
};
