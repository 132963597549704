import {
  createColumnDate,
  createColumnNumber,
  createColumnText,
  widthOptions,
} from '../../../components/table/function';

export const columns = () => {
  return [
    {
      ...createColumnText('ชื่อ-นามสกุล', 'fullname', widthOptions(150)),
      render: (text, record) => (
        <span style={{ color: record?.totalWarranty > 5 ? '#ff4d4f' : '#000' }}>{text}</span>
      ),
    },

    {
      ...createColumnText('เบอร์โทร', 'telephone', widthOptions(95)),
      render: (text, record) => (
        <span style={{ color: record?.totalWarranty > 5 ? '#ff4d4f' : '#000' }}>{text}</span>
      ),
    },
    {
      ...createColumnNumber('จำนวนการลงทะเบียน', 'totalWarranty', widthOptions(110)),
      sorter: (a, b) => a.totalWarranty - b.totalWarranty,
      defaultSortOrder: 'descend',
      render: (text, record) => (
        <span style={{ color: record?.totalWarranty > 5 ? '#ff4d4f' : '#000' }}>{text}</span>
      ),
    },
    {
      ...createColumnDate('วันที่ลงทะเบียนล่าสุด', 'lastWarrantyDate', widthOptions(150)),
      render: (text, record) => (
        <span style={{ color: record?.totalWarranty > 5 ? '#ff4d4f' : '#000' }}>{text}</span>
      ),
    },
  ];
};

export const columnsExpandable = () => {
  return [
    createColumnText('เลขที่รับประกัน', 'warrantyCode', widthOptions(150)),
    createColumnText('รหัสรับประกัน', 'warrantyNumberCode', widthOptions(150)),
    createColumnDate('รหัสลูกค้า', 'customerCode', widthOptions(150)),
    createColumnText('ยี่ห้อ', 'mobileBrandName', widthOptions(150)),
    createColumnText('รุ่น', 'mobileModelName', widthOptions(150)),
    createColumnText('ชื่อสินค้า', 'productName', widthOptions(150)),
    createColumnText('รหัสสินค้า', 'itemCode', widthOptions(150)),
    createColumnText('ประเภทสินค้า', 'productTypeCode', widthOptions(150)),
    createColumnText('ช่องทางการซื้อขาย', 'channelBuyNameTh', widthOptions(150)),
    createColumnText('วันที่ลงทะเบียน', 'createDate', widthOptions(150)),
  ];
};
