import React from 'react'
import { useForm } from 'react-hook-form';
import { ROUTE_LOGIN } from './../../resources/routes-name';
import { useEffect } from 'preact/hooks';
import { useAuthLogin } from '../../hooks/useAuth';
import { ImageLogin, LoginCard, LoginForm } from './view/login';

const LoginScene = () => {
  const { control, setValue, getValues, handleSubmit } = useForm({});
  const { redirectToMain, onLogin } = useAuthLogin();

  useEffect(() => {
    redirectToMain();
  }, [])

  const goToExternalLogin = () => window.location.assign(ROUTE_LOGIN)

  return (
    <LoginCard>
      <ImageLogin />
      <LoginForm
        {...{ control, setValue, getValues, handleSubmit, onLogin, goToExternalLogin }}
      />
    </LoginCard>
  );
};

export default LoginScene;
