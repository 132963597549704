import dayjs from 'dayjs';
import React from 'react';

export const FORM_SCAN = ({ onEnter, claimCodeRef, trackingNumberRef }) => {
  return [
    {
      name: 'claimId',
      type: 'HIDDEN',
    },
    {
      name: 'claimCode',
      label: 'เลขที่เคลม (กรอกเลขที่เคลมแล้วกด Enter)',
      type: 'INPUT',
      placeholder: '',
      onKeyDown: (e) => onEnter(e, 'claimCode'),
      ref: claimCodeRef,
    },
    {
      name: 'trackingNumber',
      type: 'INPUT',
      label: 'เลขพัสดุ (กรอกเลขพัสดุแล้วกด Enter)',
      placeholder: '',
      onKeyDown: (e) => onEnter(e, 'trackingNumber'),
      ref: trackingNumberRef,
    },
    {
      name: 'shippingDate',
      type: 'DATE_PICKER',
      label: 'วันที่จัดส่ง',
      placeholder: '',
      defaultValue: dayjs(),
      disabled: true,
    },
  ];
};

export const FORM_DATA = () => {
  const span = 12;
  return [
    {
      name: 'claimCode',
      type: 'TEXT',
      label: 'เลขที่เคลม',
      placeholder: '',
      span,
    },
    {
      name: 'fullName',
      type: 'TEXT',
      label: 'ชื่อ-นามสกุล',
      placeholder: '',
      span,
    },
    {
      name: 'telephone',
      type: 'TEXT',
      label: 'หมายเลขโทรศัพท์',
      placeholder: '',
      span,
    },
    {
      name: 'addressFullText',
      type: 'TEXT',
      label: 'ที่อยู่',
      placeholder: '',
      span,
    },
    {
      name: 'itemCode',
      type: 'TEXT',
      label: 'รหัสสินค้า',
      placeholder: '',
      span,
    },
    {
      name: 'productName',
      type: 'TEXT',
      label: 'ชื่อสินค้า',
      placeholder: '',
      span,
    },
    {
      name: 'mobileBrandName',
      type: 'TEXT',
      label: 'ยี่ห้อ',
      placeholder: '',
      span,
    },
    {
      name: 'mobileModelName',
      type: 'TEXT',
      label: 'รุ่น',
      placeholder: '',
      span,
    },
    {
      name: 'remark',
      type: 'TEXT',
      label: 'หมายเหตุ',
      placeholder: '',
      span,
    },
    {
      name: 'claimStatusName',
      type: 'TEXT',
      label: 'สถานะ',
      placeholder: '',
      span,
    },
  ];
};
export const FORM_SHIPPING = ({ shippingSelfOptions }) => {
  return [
    {
      name: 'shippingId',
      label: 'ขนส่ง',
      type: 'DROPDOWN',
      placeholder: '',
      properties: {
        options: shippingSelfOptions || [],
      },
      rules: {
        required: true,
      },
    },
  ];
};
export const FORM_UPLOADFILE = ({ shippingSelfOptions }) => {
  return [
    {
      name: 'shippingId',
      label: 'ขนส่ง',
      type: 'DROPDOWN',
      placeholder: '',
      properties: {
        options: shippingSelfOptions || [],
      },
      rules: {
        required: true,
      },
    },
    {
      name: 'warningText',
      label: (
        <span style={{ color: 'red' }}>หมายเหตุ : กำหนดข้อมูล Excel ให้ตรงกับตัวอย่างเทมเพลต</span>
      ),
      type: 'TEXT',
      style: { marginBottom: '-15px' },
    },

    {
      name: 'excelFile',
      type: 'DRAGGER_UPLOAD',
      label: 'อัปโหลดไฟล์',
      rules: {
        required: true,
      },
      style: { marginTop: '-10px' },
    },
  ];
};
export const FORM_TRACKING = () => {
  return [
    {
      name: 'trackingNumber',
      type: 'INPUT',
      label: 'เลขพัสดุ',
      placeholder: '',
      rules: {
        required: true,
      },
    },
  ];
};
