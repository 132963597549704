import { useMutation } from '@tanstack/react-query';
import { POST, POST_TRACKING_DATA } from '../../../services';
import { openAlert } from '../../../components/alert/hooks';

export const onUploadFile = () => {
  const { mutate, isPending } = useMutation({
    mutationFn: async (values) => {
      const payload = values.map(({ claimId, claimCode, trackingNumber, shippingId }) => ({
        claimId,
        claimCode,
        trackingNumber,
        shippingId,
      }));

      const response = await POST(POST_TRACKING_DATA(), payload);
      return response;
    },
    onSuccess: () => {
      openAlert({ model: 'message', type: 'success', message: 'บันทึกข้อมูลสำเร็จ' });
    },
  });
  return { mutate, loading: isPending };
};
