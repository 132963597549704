import { useForm } from 'react-hook-form';
import { useGetInfoClaimData } from './useGetInfoClaimData';
import { shippingSelfQuery } from '../../../services/react-query/optionMasterQuery';
import { useCallback, useRef, useState } from 'react';
import { onUploadFile } from './onUploadFile';
import { openAlert } from '../../../components/alert/hooks';
import { useModal } from '../../../components/modal/hooks/useModal';

export const useGetScanDeliveryList = () => {
  const { getValues, setValue, control, reset, handleSubmit } = useForm({
    defaultValues: {
      claimCode: '',
      fullName: '',
      telephone: '',
      addressFullText: '',
      itemCode: '',
      productName: '',
      mobileBrandName: '',
      mobileModelName: '',
      remark: '',
      claimStatusName: '',
    },
  });
  const [tableData, setTableData] = useState([]);
  const claimCodeRef = useRef(null);
  const trackingNumberRef = useRef(null);

  const { data: shippingSelfOptions } = shippingSelfQuery();
  const { mutate: onCallClaimData, loading: isLoadingInfo } = useGetInfoClaimData({ setValue });
  const { mutate: submitTrackingData, loading: isLoadingToSave } = onUploadFile();
  const { open: openUpload, onToggle: onToggleUpload } = useModal();
  const { open: openTracking, onToggle: onToggleTracking } = useModal();

  const handleOnEnter = useCallback(
    async (event, fieldName) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        const value = getValues(fieldName);

        switch (fieldName) {
          case 'claimCode':
            if (value) {
              const existingClaim = tableData.find((item) => item.claimCode === value);

              if (existingClaim) {
                openAlert({
                  type: 'warning',
                  model: 'message',
                  message: 'เลขที่เคลมซ้ำ ไม่สามารถเพิ่มข้อมูลได้',
                });
                reset({ claimCode: '' });
                claimCodeRef.current?.focus();
                return;
              }

              onCallClaimData(value, {
                onSuccess: () => {
                  trackingNumberRef.current?.focus();
                },
              });
            }
            break;

          case 'trackingNumber':
            if (value) {
              const existingTracking = tableData.find((item) => item.trackingNumber === value);

              if (existingTracking) {
                openAlert({
                  type: 'warning',
                  model: 'message',
                  message: 'เลขพัสดุซ้ำ ไม่สามารถเพิ่มข้อมูลได้',
                });
                setValue('trackingNumber', '');
                trackingNumberRef.current?.focus();
                return;
              }

              setTableData((prevData) => [...prevData, getValues()]);
              reset({ trackingNumber: '' });
              claimCodeRef.current?.focus();
            }
            break;
        }
      }
    },
    [getValues, tableData, reset, setValue],
  );

  const handleDeleteRecord = (record) => {
    setTableData((prevData) => prevData.filter((item) => item.claimId !== record.claimId));
  };
  const onOpenTracking = (record) => {
    setTableData([record]);
    onToggleTracking({ defaultValues: record });
  };
  const onSubmit = (values) => {
    const shippingId = values.shippingId;

    const updatedTableData = tableData.map((item) => ({
      ...item,
      shippingId,
    }));

    submitTrackingData(updatedTableData, {
      onSuccess: () => {
        openAlert({ model: 'message', type: 'success', message: 'บันทึกข้อมูลสำเร็จ' });
        reset({ shippingId: null });
        setTableData(() => []);
      },
      onError: (error) => {
        openAlert({
          model: 'message',
          type: 'error',
          message: error?.message || 'เกิดข้อผิดพลาดในการบันทึกข้อมูล',
        });
      },
    });
  };
  return {
    getValues,
    setValue,
    control,
    reset,
    shippingSelfOptions,
    tableData,
    handleDeleteRecord,
    handleSubmit,
    onSubmit,
    claimCodeRef,
    trackingNumberRef,
    handleOnEnter,
    openUpload,
    onToggleUpload,
    isLoadingToSave,
    isLoadingInfo,
    onOpenTracking,
    openTracking,
    onToggleTracking,
    setTableData,
  };
};
