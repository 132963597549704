import { useMutation } from '@tanstack/react-query';
import { POST, POST_UPDATE_TEL_USER } from '../../../services';
import { openAlert } from '../../../components/alert/hooks';

export const useUpdateTel = ({ onSuccessCallBack }) => {
  const { mutate, isPending } = useMutation({
    mutationFn: async (payload) => {
      const response = await POST(POST_UPDATE_TEL_USER(), payload);
      onSuccessCallBack && onSuccessCallBack(response);
      return response.item;
    },
    onSuccess: () => {
      openAlert({
        model: 'message',
        type: 'success',
        message: 'แก้ไขเบอร์ติดต่อสำเร็จ',
      });
    },
  });
  return { mutate, loading: isPending };
};
