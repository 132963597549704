import React from 'react';
import { TextLarge } from '../../components/text';
import { CardTheme } from '../../components/card';
import { ResponsivePageLayout } from '../../components/page/responsive-layout/ResponsivePageLayout';
import { Icon } from '../../resources';
import { useGetReportPageList } from './hooks/useGetReportPageList';
import { ContainerButton } from '../../styles/global-style';
import ButtonExcelEmbedded from '../../components/report-excel/ButtonExcelEmbedded';
import { SpaceBetween, StyledContainer } from '../../components/container/styled';
import { ResponsiveTableLayout } from '../../components/page/responsive-layout/views/ResponsiveTableLayout';

export const ReportPage = () => {
  const {
    formSearch,
    formSearchObj,
    dataDate,
    isLoading,
    columnsData,
    dataClaimFull,
    loadingClaimFull,
    searchType,
    onSearchByField,
    formatDataForExcel,
    createExpandableHeaders,
    columnsExpandableData,
  } = useGetReportPageList();

  return (
    <StyledContainer>
      <TextLarge text="รายงานประวัติการเคลม" />
      <CardTheme>
        <ResponsivePageLayout
          searchLayout={{
            title: 'ค้นหารายงานประวัติการเคลม',
            icon: <Icon.todo />,
            formSearch,
            useFormParam: formSearchObj,
            onSearch: formSearchObj.handleSubmit(onSearchByField),
            onClear: formSearchObj.reset,
          }}
          tableLayout={{
            columns: columnsData || [],
            dataSource: searchType === 'date' ? dataDate : dataClaimFull || [],
            loading: searchType === 'date' ? isLoading : loadingClaimFull,
            rowKey: 'rowNumber',
            hasPagination: true,
            expandable: {
              expandedRowRender: (record) => {
                const dataClaimReportHistoryDetails = record?.claimReportHistoryDetails || [];
                return (
                  <ResponsiveTableLayout
                    columns={columnsExpandableData}
                    dataSource={dataClaimReportHistoryDetails}
                    loading={isLoading}
                  />
                );
              },
            },
            extraContent: (
              <SpaceBetween>
                <ContainerButton right>
                  <ButtonExcelEmbedded
                    title="Export"
                    filename="รายงานประวัติการเคลม"
                    header={[
                      { title: 'ชื่อ-นามสกุล', dataIndex: 'ชื่อ-นามสกุล' },
                      { title: 'เบอร์โทร', dataIndex: 'เบอร์โทร' },
                      { title: 'จำนวนการเคลม', dataIndex: 'จำนวนการเคลม' },
                      { title: 'วันที่เคลมล่าสุด', dataIndex: 'วันที่เคลมล่าสุด' },
                      ...createExpandableHeaders(),
                    ]}
                    data={formatDataForExcel(
                      searchType === 'date' ? dataDate : dataClaimFull || [],
                    )}
                  />
                </ContainerButton>
              </SpaceBetween>
            ),
          }}
        />
      </CardTheme>
    </StyledContainer>
  );
};
