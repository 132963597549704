import React from 'react';
import { StyledContainer } from '../../../components/container/styled';
import { TextLarge } from '../../../components/text';
import { CardTheme } from '../../../components/card';
import { Col, Row, Spin } from 'antd';
import { ButtonTheme, RenderForm } from '../../../components';
import { FORM_PRODUCT_REGISTRATION_WARRANTY, FORM_USER_REGISTRATION_WARRANTY } from './form';
import { useGetWarrantyAddList } from './hooks/useGetWarrantyAddList';
import { ContainerButton } from '../../../styles/global-style';
export const WarrantyAdd = () => {
  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    channelBuyOptions,
    mobileBrandOptions,
    handleOnEnterTele,
    handleOnEnterBarcode,
    onSubmit,
    isSubmitting,
    isloadingWarrantyAdd,
    isloadingBarcode,
    isloadingTele,
  } = useGetWarrantyAddList();

  return (
    <Spin spinning={isloadingWarrantyAdd || isloadingBarcode || isloadingTele}>
      <StyledContainer>
        <TextLarge text="ลงทะเบียนสินค้า" />
        <Row gutter={16}>
          <Col span={14}>
            <CardTheme>
              <TextLarge text="ลูกค้าลงทะเบียนรับประกัน" />
              <RenderForm
                control={control}
                setValue={setValue}
                getValues={getValues}
                forms={FORM_USER_REGISTRATION_WARRANTY({ onEnter: handleOnEnterTele })}
              />
            </CardTheme>
          </Col>
          <Col span={10}>
            <CardTheme>
              <TextLarge text="สินค้าลงทะเบียนรับประกัน" />
              <RenderForm
                control={control}
                setValue={setValue}
                getValues={getValues}
                forms={FORM_PRODUCT_REGISTRATION_WARRANTY({
                  onEnter: handleOnEnterBarcode,
                  mobileBrandOptions,
                  channelBuyOptions,
                })}
              />
            </CardTheme>
          </Col>
        </Row>
        <ContainerButton right>
          <ButtonTheme
            useFor="SUBMIT"
            title="บันทึก"
            style={{ marginTop: '2%' }}
            onClick={handleSubmit(onSubmit)}
            disabled={isSubmitting}
          />
        </ContainerButton>
      </StyledContainer>
    </Spin>
  );
};
