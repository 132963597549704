import React from 'react';
import '../../styles/style.css';

export const MainPage = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '60vh',
      }}
    >
      <img src="../../assets/images/logo-rizz.jpg" alt="Logo" style={{ height: 100, width: 200 }} />
    </div>
  );
};
