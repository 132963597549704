import { useForm } from 'react-hook-form';
import { ButtonTheme, ModalTheme, RenderForm } from '../../../components';
import { TextLarge } from '../../../components/text';
import { FORM_EDIT_PRODUCT } from '../form';
import React, { useEffect } from 'react';
import { ContainerButton } from '../../../styles/global-style';
import { ProductTypesQuery } from '../../../services/react-query/optionMasterQuery';
import { useUpdateProductList } from '../hooks/useUpdateProductList';
import { UpdateProductList } from '../../../resources/payload';
import { Spin } from 'antd';
import { PRODUCT_STATUS } from '../../../resources/status';

export const EditProductModal = ({ open, onToggle, initialValues, onFetch }) => {
  const { control, getValues, setValue, handleSubmit } = useForm({});
  const { data: productTypeList } = ProductTypesQuery();
  const { mutate: updateProduct, loading: isLoadingUpdateProduct } = useUpdateProductList(onFetch);
  const productStatus = PRODUCT_STATUS?.map((status) => ({
    label: status?.label,
    value: status?.key,
  }));

  useEffect(() => {
    if (initialValues) {
      Object.keys(initialValues).forEach((key) => {
        let value = initialValues[key];

        if (key === 'productImageUrl') {
          value = Array.isArray(value) ? value : value ? [{ uid: '-1', url: value }] : [];
        }

        setValue(key, value);
      });
    }
  }, [initialValues, setValue]);
  const onSubmit = () =>
    handleSubmit((values) => {
      const formData = UpdateProductList(values);
      updateProduct(formData);
      onToggle({ defaultValue: '' });
    })();

  return (
    <Spin spinning={isLoadingUpdateProduct}>
      <ModalTheme title=" " open={open} onToggle={onToggle}>
        <div style={{ width: '100%', textAlign: 'center' }}>
          <TextLarge text="แก้ไขสินค้า" />
        </div>
        <RenderForm
          control={control}
          getValues={getValues}
          setValue={setValue}
          forms={FORM_EDIT_PRODUCT({ productTypeList, productStatus })}
        />
        <ContainerButton center>
          <ButtonTheme useFor="SUBMIT" onClick={handleSubmit(onSubmit)} />
          <ButtonTheme useFor="CANCEL" onClick={onToggle} />
        </ContainerButton>
      </ModalTheme>
    </Spin>
  );
};
