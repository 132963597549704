import React from 'react';

import { FORM_UPLOADFILE } from '../form';
import { useGetScanDeliveryList } from '../hooks/useGetScanDeliveryList';
import { ButtonTheme, ModalTheme, RenderForm } from '../../../components';
import { ContainerButton } from '../../../styles/global-style';
import { TextLarge } from '../../../components/text';
import { useDownloadTemplate } from '../hooks/useDownloadTemplate';
import { useUpdateExcel } from '../hooks/useUpdateExcel';
import { UpdateExcel } from '../../../resources/payload';
import { Spin } from 'antd';

export const UploadFileModal = ({ open, onToggle }) => {
  const { control, setValue, getValues, shippingSelfOptions, handleSubmit } =
    useGetScanDeliveryList();
  const { mutate: downloadTemplate, isLoading, loading: isloadingTem } = useDownloadTemplate();
  const { mutate: upDateExcel, loading: isLoadingToSave } = useUpdateExcel();
  const onSubmit = () =>
    handleSubmit((values) => {
      const formData = UpdateExcel(values);
      upDateExcel(formData, {
        onSuccess: () => {
          onToggle({ defaultValue: '' });
        },
      });
    })();

  return (
    <Spin spinning={isloadingTem || isLoadingToSave}>
      <ModalTheme title=" " open={open} onToggle={onToggle}>
        <div style={{ width: '100%', textAlign: 'center' }}>
          <TextLarge text="นำเข้าเลขพัสดุ" />
        </div>
        <ContainerButton right>
          <ButtonTheme
            useFor="DOWNLOADTEM"
            onClick={() => downloadTemplate()}
            isLoading={isLoading}
          />
        </ContainerButton>
        <RenderForm
          control={control}
          setValue={setValue}
          getValues={getValues}
          forms={FORM_UPLOADFILE({ shippingSelfOptions })}
        />
        <div style={{ marginTop: '30px' }}>
          <ContainerButton center>
            <ButtonTheme useFor="SUBMIT" onClick={handleSubmit(onSubmit)} />
            <ButtonTheme useFor="CANCEL" onClick={onToggle} />
          </ContainerButton>
        </div>
      </ModalTheme>
    </Spin>
  );
};
