import { CardTheme } from '../../components/card';
import { SpaceBetween, StyledContainer } from '../../components/container/styled';
import { ResponsivePageLayout } from '../../components/page/responsive-layout/ResponsivePageLayout';
import { TextLarge } from '../../components/text';
import React, { useEffect } from 'react';
import { Icon } from '../../resources';
import { useGetProductList } from './hooks/useGetProductList';
import { ContainerButton } from '../../styles/global-style';
import ButtonExcelEmbedded from '../../components/report-excel/ButtonExcelEmbedded';
import { EditProductModal } from './modals/EditProductModal';

export const ProductWarranty = () => {
  const {
    formSearch,
    formSearchObj,
    columnsData,
    dataFull,
    onFetchFull,
    isLoadingFull,
    openEdit,
    onToggleEdit,
    data,
  } = useGetProductList();

  useEffect(() => {
    onFetchFull();
  }, []);

  return (
    <StyledContainer>
      <TextLarge text="สินค้ารับประกัน" />
      <CardTheme>
        <ResponsivePageLayout
          searchLayout={{
            title: 'ค้นหาข้อมูลสินค้า',
            icon: <Icon.todo />,
            formSearch,
            useFormParam: formSearchObj,
            onSearch: onFetchFull,
            ignoreDateEmpty: true,
            onClear: formSearchObj.reset,
          }}
          tableLayout={{
            columns: columnsData,
            dataSource: dataFull,
            loading: isLoadingFull,
            hasPagination: true,
            extraContent: (
              <SpaceBetween>
                <ContainerButton right>
                  <ButtonExcelEmbedded
                    title="Export"
                    header={columnsData}
                    data={dataFull}
                    filename="รายงานข้อมูลสินค้า"
                  />
                </ContainerButton>
              </SpaceBetween>
            ),
          }}
        />
      </CardTheme>
      {openEdit && (
        <EditProductModal
          open={openEdit}
          onToggle={onToggleEdit}
          initialValues={data}
          onFetch={onFetchFull}
        />
      )}
    </StyledContainer>
  );
};
