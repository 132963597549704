import { useForm } from 'react-hook-form';
import { ButtonTheme, ModalTheme, RenderForm } from '../../../components';
import { TextLarge } from '../../../components/text';
import { FORM_EDIT_USER_DATA } from '../form';
import React, { useEffect } from 'react';
import { ContainerButton } from '../../../styles/global-style';
import { color } from '../../../resources';
import { useResetLine } from '../hooks/useResetLine';
import { useAntDModalContext } from '../../../components/modal/context/useAntdModalContext';
import { useModal } from '../../../components/modal/hooks/useModal';
import { UpdateTelModel } from './UpdateTelModel';
import { useUpdateUserInfo } from '../hooks/useUpdateUserInfo';
import { UpdateUserInfo } from '../../../resources/payload';
import { Spin } from 'antd';

export const EditUserDataModal = ({ open, onToggle, onFetch, initialValues }) => {
  const { control, setValue, getValues, handleSubmit } = useForm({});
  const { openConfirmModal } = useAntDModalContext() || {};
  const { open: openEditTel, onToggle: onToggleEditTel } = useModal();
  const { mutate: onSubmitReset, loading: isLoadingLine } = useResetLine({
    onSuccessCallBack: onFetch,
  });
  const { mutate: onSubmitEditData, loading: isLoadingToSave } = useUpdateUserInfo({
    onSuccessCallBack: onFetch,
  });

  useEffect(() => {
    if (initialValues) {
      Object.entries(initialValues).forEach(([key, value]) => setValue(key, value));
    }
  }, [initialValues, setValue]);

  const closeAllWindows = () => {
    onToggle({ defaultValues: '' });
    onToggleEditTel({ defaultValues: '' });
  };
  const onSubmit = (values) => {
    const payload = UpdateUserInfo(values, initialValues);
    onSubmitEditData(payload);
    onToggle({ defaultValue: '' });
  };

  return (
    <Spin spinning={isLoadingLine || isLoadingToSave}>
      <ModalTheme title=" " open={open} onToggle={onToggle}>
        <div style={{ width: '100%', textAlign: 'left' }}>
          <TextLarge text="แก้ไขข้อมูล" />
        </div>
        <RenderForm
          control={control}
          setValue={setValue}
          getValues={getValues}
          forms={FORM_EDIT_USER_DATA()}
        />
        <div className="buttonToEdit">
          <ButtonTheme
            useFor="TEL"
            title="แก้ไขเบอร์ติดต่อ"
            style={{ backgroundColor: color.blackFocus }}
            onClick={onToggleEditTel}
          />
          <ButtonTheme
            useFor="LINE"
            title="รีเซ็ตไลน์"
            style={{ marginLeft: '40%' }}
            onClick={() =>
              openConfirmModal({
                onOk: () => {
                  onSubmitReset({ customerId: initialValues.customerId });
                  onToggle({ defaultValues: '' });
                },
                title: 'คุณต้องการรีเซ็ตไลน์หรือไม่',
              })
            }
          />
        </div>
        <ContainerButton right>
          <ButtonTheme useFor="SUBMIT" onClick={handleSubmit(onSubmit)} />
          <ButtonTheme useFor="CANCEL" onClick={onToggle} />
        </ContainerButton>
        {openEditTel && (
          <UpdateTelModel
            open={openEditTel}
            onToggle={onToggleEditTel}
            initialValues={initialValues}
            onFetch={onFetch}
            closeAllWindows={closeAllWindows}
          />
        )}
      </ModalTheme>
    </Spin>
  );
};
