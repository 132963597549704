import React, { useEffect } from 'react';
import { ButtonTheme, ModalTheme, RenderForm } from '../../../../components';
import { TextLarge, TextSmall } from '../../../../components/text';
import { ContainerButton } from '../../../../styles/global-style';
import { useForm } from 'react-hook-form';
import { FORM_TRACKING } from '../form';
import { shippingSelfQuery } from '../../../../services/react-query/optionMasterQuery';
import { useUpdateTracking } from '../hooks/useUpdateTracking';
import { UpdateTracking } from '../../../../resources/payload';
import { Spin } from 'antd';

export const UpdateTrackingModal = ({ open, onToggle, claimData, onFetch }) => {
  const { control, setValue, getValues, handleSubmit } = useForm({});
  const { data: shippingSelfList } = shippingSelfQuery();
  const { mutate: onSubmitUpdateTracking, loading: isLoadingTracking } = useUpdateTracking({
    onSuccessCallBack: onFetch,
  });

  useEffect(() => {
    setValue('trackingNumber', claimData?.trackingNumber);
    setValue('shippingId', claimData?.shippingId);
    setValue('claimId', claimData?.claimId);
  }, [claimData]);

  const onSubmit = (values) => {
    const payload = UpdateTracking(values, claimData);
    onSubmitUpdateTracking(payload);
    onToggle({ defaultValue: '' });
  };

  return (
    <Spin spinning={isLoadingTracking}>
      <ModalTheme title=" " open={open} onToggle={onToggle}>
        <div style={{ width: '100%', textAlign: 'center' }}>
          <TextLarge text="แก้ไขเลขพัสดุ" />
        </div>
        <div style={{ width: '100%', textAlign: 'center' }}>
          <TextSmall text={claimData?.claimCode + ' ' + claimData?.fullName} />
        </div>
        <RenderForm
          control={control}
          setValue={setValue}
          getValues={getValues}
          forms={FORM_TRACKING({ shippingSelfList })}
        />
        <ContainerButton right>
          <ButtonTheme useFor="SUBMIT" onClick={handleSubmit(onSubmit)} />
          <ButtonTheme useFor="CANCEL" onClick={onToggle} />
        </ContainerButton>
      </ModalTheme>
    </Spin>
  );
};
