import React, { Fragment } from 'preact';
import { useController } from 'react-hook-form';
import { Required, RowComponent } from '../../../styles/global-style';
import { Switch } from 'antd';
import { color } from '../../../resources';
import { TextInputLabel } from '../../text';

const SwitchComponent = ({ control, item, handleChange, setValue, getValues }) => {
  const { rules, name, defaultValue, label, disabled, propperties, ...propsInput } = item;

  const { field } = useController({
    control,
    name,
    rules,
    defaultValue: defaultValue ? 'ACTIVE' : 'INACTIVE',
  });

  return (
    <Fragment>
      <RowComponent>
        {label && <TextInputLabel text={label} />}
        {rules?.required && <Required>*</Required>}
      </RowComponent>
      <Switch
        id={name}
        disabled={disabled}
        {...propperties}
        checked={field.value === 'ACTIVE'}
        checkedChildren=""
        unCheckedChildren=""
        onChange={(checked) => {
          const newValue = checked ? 'ACTIVE' : 'INACTIVE';
          field.onChange(newValue);
          setValue(name, newValue);
          handleChange(newValue, item);
        }}
        style={{
          backgroundColor: field.value === 'ACTIVE' ? color.line : '#d9d9d9',
          borderColor: field.value === 'ACTIVE' ? color.line : '#d9d9d9',
        }}
        {...propsInput}
      />
    </Fragment>
  );
};

export const MemoizedSwitch = SwitchComponent;
