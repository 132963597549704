export const FORM_CLAIM_CUSTOMER_EDIT_DATA = ({
  onEnter,
  provinceOptions,
  districtOptions,
  subDistrictOptions,
}) => {
  const span = 24;
  return [
    {
      name: 'fullName',
      type: 'TEXT',
      label: 'ชื่อ-นามสกุล',
      placeholder: '',
      span,
    },
    {
      name: 'telephone',
      type: 'TEXT',
      label: 'หมายเลขโทรศัพท์',
      placeholder: '',
      span,
    },
    {
      name: 'addressFullText',
      type: 'TEXT',
      label: 'ที่อยู่',
      placeholder: '',
      span,
    },
    {
      name: 'zipCode',
      type: 'INPUT',
      label: 'รหัสไปรษณีย์',
      placeholder: '',
      span,
      onEnter,
      rules: {
        required: true,
      },
    },
    {
      name: 'provinceId',
      type: 'DROPDOWN',
      label: 'จังหวัด',
      placeholder: '',
      properties: {
        options: provinceOptions || [],
      },
      span,
      rules: {
        required: true,
      },
    },
    {
      name: 'districtId',
      type: 'DROPDOWN',
      label: 'เขต/อำเภอ',
      placeholder: '',
      properties: {
        options: districtOptions || [],
      },
      span,
      rules: {
        required: true,
      },
    },
    {
      name: 'subDistrictId',
      type: 'DROPDOWN',
      label: 'เขต/ตำบล',
      placeholder: '',
      properties: {
        options: subDistrictOptions || [],
      },
      span,
      rules: {
        required: true,
      },
    },
    {
      name: 'other',
      type: 'INPUT',
      label: 'เพิ่มเติม',
      placeholder: '',
      span,
    },
  ];
};

export const FORM_CLAIM_EDIT_DATA = ({ breakdownList }) => {
  const span = 24;
  return [
    {
      name: 'claimCode',
      type: 'TEXT',
      label: 'เลขที่การเคลม',
      placeholder: '',
      span,
    },
    {
      name: 'warrantyCode',
      type: 'TEXT',
      label: 'รหัสลงทะเบียนสินค้า',
      placeholder: '',
      span,
    },
    {
      name: 'claimDate',
      type: 'TEXT',
      label: 'วันที่แจ้งเคลม',
      placeholder: '',
      span,
    },
    {
      name: 'productName',
      type: 'TEXT',
      label: 'ชื่อสินค้า',
      placeholder: '',
      span,
    },
    {
      name: 'mobileBrandName',
      type: 'TEXT',
      label: 'ยี่ห้อ',
      placeholder: '',
      span,
    },
    {
      name: 'mobileModelName',
      type: 'TEXT',
      label: 'รุ่น',
      placeholder: '',
      span,
    },
    {
      name: 'breakdownId',
      type: 'DROPDOWN',
      label: 'อาการเสีย',
      placeholder: '',
      properties: {
        options: breakdownList || [],
      },
      rules: {
        required: true,
      },
      span,
    },
    {
      name: 'detail1',
      type: 'INPUT',
      label: 'รายละเอียด',
      placeholder: '',
      span,
    },
    {
      name: 'remark',
      type: 'INPUT_AREA',
      label: 'หมายเหตุ',
      placeholder: '',
      span,
      rules: {
        maxLength: 500,
      },
    },
    {
      name: 'claimedDays',
      type: 'TEXT',
      label: 'จำนวนวันที่แจ้งเคลมมาแล้ว',
      placeholder: '',
      span,
    },
    {
      name: 'remainingDays',
      type: 'TEXT',
      label: 'จำนวนวันคงเหลือ',
      placeholder: '',
      span,
    },
  ];
};
export const FORM_CLAIM_EDIT_PICTURE = () => {
  return [
    {
      name: 'claimImageUrl',
      label: 'รูปภาพสินค้าเคลม',
      type: 'UPLOAD',
      listType: 'picture-card',
      span: 12,
      maxCount: 1,
    },
    {
      name: 'receiptImageUrl',
      label: 'รูปภาพใบเสร็จ',
      type: 'UPLOAD',
      listType: 'picture-card',
      span: 12,
      maxCount: 1,
    },
  ];
};

export const FORM_CLAIM_CANCEL = () => {
  return [
    {
      name: 'claimId',
      type: 'HIDDEN',
    },
    {
      name: 'cancelReason',
      label: 'ข้อความ (ข้อความระบุไม่เกิน 250 ตัวอักษร)',
      type: 'INPUT_AREA',
      placeholder: '',
      rules: {
        required: true,
        maxLength: 250,
      },
      span: 24,
    },
  ];
};
