export const handleSubmitForm = (values, dataBarcode, mutateUpload) => {
  const uploadData = {
    invImage: values.invImage ? values.invImage[0] : null,
    packageImage: values.packageImage ? values.packageImage[0] : null,
    telephone: values.telephone,
    fullName: values.fullName,
    mobileBrandId: values.mobileBrandId,
    mobileModelName: values.mobileModelName,
    productId: dataBarcode.item.productId,
    channelBuyId: values.channelBuyId,
  };

  mutateUpload(uploadData);
};
