import { useMutation } from '@tanstack/react-query';
import { POST, POST_CLAIMS_CANCEL } from '../../../../services';
import { openAlert } from '../../../../components/alert/hooks';
import { navigateTo } from '../../../../utils/navigation';
import { ROUTES_PATH } from '../../../../resources/routes-name';
import { cancelClaim } from '../../../../resources/payload';

export const useCancelClaim = () => {
  const { mutate, isPending } = useMutation({
    mutationFn: async (value) => {
      const payload = cancelClaim(value);
      const response = await POST(POST_CLAIMS_CANCEL(), payload);
      return response;
    },
    onSuccess: (response) => {
      openAlert({ type: 'success', message: response?.message, model: 'message' });
      setTimeout(() => {
        navigateTo({ pathname: ROUTES_PATH.ROUTE_CLAIM.PATH });
      }, 1000);
    },
  });
  return { mutate, loading: isPending };
};
