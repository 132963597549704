export const FORM_CLAIM_CUSTOMER_DATA = () => {
  const span = 24;
  return [
    {
      name: 'fullName',
      type: 'TEXT',
      label: 'ชื่อ-นามสกุล',
      placeholder: '',
      span,
    },
    {
      name: 'telephone',
      type: 'TEXT',
      label: 'หมายเลขโทรศัพท์',
      placeholder: '',
      span,
    },
    {
      name: 'addressFullText',
      type: 'TEXT',
      label: 'ที่อยู่',
      placeholder: '',
      span,
    },
    {
      name: 'zipCode',
      type: 'TEXT',
      label: 'รหัสไปรษณีย์',
      placeholder: '',
      span,
    },
    {
      name: 'provinceName',
      type: 'TEXT',
      label: 'จังหวัด',
      placeholder: '',
      span,
    },
    {
      name: 'districtName',
      type: 'TEXT',
      label: 'เขต/อำเภอ',
      placeholder: '',
      span,
    },
    {
      name: 'subDistrictName',
      type: 'TEXT',
      label: 'เขต/ตำบล',
      placeholder: '',
      span,
    },
    {
      name: 'other',
      type: 'TEXT',
      label: 'เพิ่มเติม',
      placeholder: '',
      span,
    },
  ];
};

export const FORM_CLAIM_DETAIL_DATA = () => {
  const span = 24;
  return [
    {
      name: 'claimCode',
      type: 'TEXT',
      label: 'เลขที่การเคลม',
      placeholder: '',
      span,
    },
    {
      name: 'warrantyCode',
      type: 'TEXT',
      label: 'รหัสลงทะเบียนสินค้า',
      placeholder: '',
      span,
    },
    {
      name: 'claimDate',
      type: 'TEXT',
      label: 'วันที่แจ้งเคลม',
      placeholder: '',
      span,
    },
    {
      name: 'productName',
      type: 'TEXT',
      label: 'ชื่อสินค้า',
      placeholder: '',
      span,
    },
    {
      name: 'mobileBrandName',
      type: 'TEXT',
      label: 'ยี่ห้อ',
      placeholder: '',
      span,
    },
    {
      name: 'mobileModelName',
      type: 'TEXT',
      label: 'รุ่น',
      placeholder: '',
      span,
    },
    {
      name: 'breakdownName',
      type: 'TEXT',
      label: 'อาการเสีย',
      placeholder: '',
      span,
    },
    {
      name: 'detail1',
      type: 'TEXT',
      label: 'รายละเอียด',
      placeholder: '',
      span,
    },
    {
      name: 'remark',
      type: 'TEXT',
      label: 'หมายเหตุ',
      placeholder: '',
      span,
    },
    {
      name: 'claimedDays',
      type: 'TEXT',
      label: 'จำนวนวันที่แจ้งเคลมมาแล้ว',
      placeholder: '',
      span,
    },
    {
      name: 'remainingDays',
      type: 'TEXT',
      label: 'จำนวนวันคงเหลือ',
      placeholder: '',
      span,
    },
  ];
};
export const FORM_CLAIM_DETAIL_PICTURE = () => {
  return [
    {
      name: 'claimImageUrl',
      label: 'รูปภาพสินค้าเคลม',
      type: 'PREVIEW',
      listType: 'picture-card',
      span: 12,
      maxCount: 1,
    },
    {
      name: 'receiptImageUrl',
      label: 'รูปภาพใบเสร็จ',
      type: 'PREVIEW',
      listType: 'picture-card',
      span: 12,
      maxCount: 1,
    },
  ];
};

export const FORM_CLAIM_CANCEL = () => {
  return [
    {
      name: 'claimId',
      type: 'HIDDEN',
    },
    {
      name: 'cancelReason',
      label: 'ข้อความ (ข้อความระบุไม่เกิน 250 ตัวอักษร)',
      type: 'INPUT_AREA',
      placeholder: '',
      rules: {
        required: true,
        maxLength: 250,
      },
      span: 24,
    },
  ];
};

export const FORM_CLAIM_NO_APPROVE = () => {
  return [
    {
      name: 'claimId',
      type: 'HIDDEN',
    },
    {
      name: 'rejectReason',
      label: 'ข้อความ (ข้อความระบุไม่เกิน 250 ตัวอักษร)',
      type: 'INPUT_AREA',
      placeholder: '',
      rules: {
        required: true,
        maxLength: 250,
      },
      span: 24,
    },
  ];
};

export const FORM_CLAIM_UPDATE_PRODUCT = ({ onEnter, mobileBrandOptions }) => {
  const span = 24;
  return [
    {
      name: 'barcodeShortcut',
      label: 'Barcode',
      type: 'INPUT_BARCODE',
      placeholder: '',
      span,
      rules: {
        required: true,
        pattern: /^[0-9]{9}$/,
      },
      maxLength: 9,
      onEnter,
    },
    {
      name: 'productId',
      type: 'HIDDEN',
    },

    {
      name: 'productName',
      label: 'ชื่อสินค้า',
      type: 'INPUT',
      placeholder: '',
      span,
      rules: {
        required: true,
      },
      style: { width: '100%', height: '100%', minHeight: '32px' },
      disabled: true,
    },
    {
      name: 'productTypeName',
      label: 'ประเภทของสินค้า',
      type: 'INPUT',
      placeholder: '',
      span,
      rules: {
        required: true,
      },
      disabled: true,
    },
    {
      name: 'mobileBrandId',
      label: 'ยี่ห้อ',
      type: 'DROPDOWN',
      placeholder: '',
      properties: {
        options: mobileBrandOptions || [],
      },

      rules: {
        required: true,
      },
      style: { width: '100%', height: '100%', minHeight: '32px' },
    },
    {
      name: 'mobileModelName',
      label: 'รุ่น',
      type: 'INPUT',
      placeholder: '',
      span,
      rules: {
        required: true,
      },
      style: { width: '100%', height: '100%', minHeight: '32px' },
    },
    {
      name: 'claimId',
      type: 'HIDDEN',
    },
    {
      name: 'remark',
      label: 'หมายเหตุ (ข้อความระบุไม่เกิน 500 ตัวอักษร)',
      type: 'INPUT_AREA',
      placeholder: '',
      rules: {
        required: true,
        maxLength: 500,
      },
      span: 24,
    },
  ];
};
