import { useMutation } from '@tanstack/react-query';
import { GET, GET_BARCORE } from '../../../../services';
import { openAlert } from '../../../../components/alert/hooks';

export const useBarcodeQuery = ({ reset, getValues }) => {
  const { mutate, isPending } = useMutation({
    mutationFn: async (value) => {
      const response = await GET(GET_BARCORE(value));
      return response.result;
    },
    onSuccess: (result) => {
      openAlert({
        type: 'success',
        model: 'message',
        message: 'ค้นหาชื่อสินค้าและประเภทสินค้าสำเร็จ',
      });
      reset({
        ...getValues(),
        productName: result.item.productNameTh,
        productTypeName: result.item.productTypeName,
        productId: result.item.productId,
      });
    },
    onError: () => {
      reset({
        ...getValues(),
        productName: '',
        productTypeName: '',
        productId: '',
      });
    },
  });
  return { mutate, loading: isPending };
};
