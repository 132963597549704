import { useForm } from 'react-hook-form';
import { useSendLineUser } from '../hooks/useSendLineUser';
import { useEffect } from 'react';
import { SendLineUser } from '../../../resources/payload';
import { ButtonTheme, ModalTheme, RenderForm } from '../../../components';
import { TextLarge, TextSmall } from '../../../components/text';
import { FORM_SEND_LINE_USER } from '../form';
import React from 'react';
import { ContainerButton } from '../../../styles/global-style';
import { Spin } from 'antd';

export const SendLineUserModal = ({ open, onToggle, initialValues, onFetch }) => {
  const { control, setValue, getValues, handleSubmit } = useForm({});
  const { mutate: onSubmitSendLineUser, loading: isLoadingSendLine } = useSendLineUser({
    onSuccessCallBack: onFetch,
  });

  useEffect(() => {
    initialValues?.customerId && setValue('customerId', initialValues.customerId);
  }, [initialValues, setValue]);

  const onSubmit = (values) => {
    const payload = SendLineUser(values, initialValues);
    onSubmitSendLineUser(payload);
    onToggle({ defaultValue: '' });
  };

  return (
    <Spin spinning={isLoadingSendLine}>
      <ModalTheme title=" " open={open} onToggle={onToggle}>
        <div style={{ width: '100%', textAlign: 'center' }}>
          <TextLarge text="ส่งข้อความ" />
        </div>
        <div style={{ width: '100%', textAlign: 'left' }}>
          <TextSmall text={'ชื่อ : ' + initialValues?.fullName} />
          <TextSmall text={'รหัสลูกค้า: ' + initialValues?.customerCode} />
        </div>
        <RenderForm
          control={control}
          setValue={setValue}
          getValues={getValues}
          forms={FORM_SEND_LINE_USER()}
        />
        <ContainerButton right>
          <ButtonTheme useFor="SUBMIT" onClick={handleSubmit(onSubmit)} />
          <ButtonTheme useFor="CANCEL" onClick={onToggle} />
        </ContainerButton>
      </ModalTheme>
    </Spin>
  );
};
