import { useMutation } from '@tanstack/react-query';
import { POST, POST_CLAIM_UPDATE } from '../../../../services';
import { openAlert } from '../../../../components/alert/hooks';

export const onUploadFile = () => {
  const { mutate, isPending } = useMutation({
    mutationFn: async (formData) => {
      const response = await POST(POST_CLAIM_UPDATE(), formData);
      return response;
    },
    onSuccess: () => {
      openAlert({ model: 'message', type: 'success', message: 'แก้ไขข้อมูลการเคลมสำเร็จ' });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    },
  });
  return { mutate, loading: isPending };
};
