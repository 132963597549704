import React, { useEffect } from 'react';
import { ButtonTheme, ModalTheme, RenderForm } from '../../../../components';
import { TextLarge, TextSmall } from '../../../../components/text';
import { ContainerButton } from '../../../../styles/global-style';
import { useForm } from 'react-hook-form';
import { FORM_SEND_LINE_MSG } from '../form';
import { useSendLineMsg } from '../hooks/useSendLineMsg';
import { SendLineMsg } from '../../../../resources/payload';
import { Spin } from 'antd';

export const SendLineMsgModal = ({ open, onToggle, claimData, onFetch }) => {
  const { control, setValue, getValues, handleSubmit } = useForm({});
  const { mutate: onSubmitSendLineMsg, loading: isLoadingLine } = useSendLineMsg({
    onSuccessCallBack: onFetch,
  });

  useEffect(() => {
    claimData?.claimId && setValue('claimId', claimData.claimId);
  }, [claimData, setValue]);

  const onSubmit = (values) => {
    const payload = SendLineMsg(values, claimData);
    onSubmitSendLineMsg(payload);
    onToggle({ defaultValue: '' });
  };

  return (
    <Spin spinning={isLoadingLine}>
      <ModalTheme title=" " open={open} onToggle={onToggle}>
        <div style={{ width: '100%', textAlign: 'center' }}>
          <TextLarge text="ส่งข้อความ" />
        </div>
        <div style={{ width: '100%', textAlign: 'left' }}>
          <TextSmall text={'ชื่อ : ' + claimData?.fullName} />
          <TextSmall text={'เลขที่เคลม : ' + claimData?.claimCode} />
        </div>
        <RenderForm
          control={control}
          setValue={setValue}
          getValues={getValues}
          forms={FORM_SEND_LINE_MSG()}
        />
        <ContainerButton right>
          <ButtonTheme useFor="SUBMIT" onClick={handleSubmit(onSubmit)} />
          <ButtonTheme useFor="CANCEL" onClick={onToggle} />
        </ContainerButton>
      </ModalTheme>
    </Spin>
  );
};
