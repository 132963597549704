import { useState } from 'react';
import { GET, GET_PRODUCT_FULL_SEARCH } from '../../../services';
import { useMutation } from '@tanstack/react-query';
import { openAlert } from '../../../components/alert/hooks';

export const useGetProductFull = ({ handleSubmit }) => {
  const [data, setData] = useState([]);

  const { isPending, mutate: onSearchFull } = useMutation({
    mutationFn: async (searchParamState) => {
      const { search } = searchParamState;
      const response = await GET(
        GET_PRODUCT_FULL_SEARCH({
          search: search || '',
        }),
      );
      return response.result.items || [];
    },
    onSuccess: (response) => {
      setData(response);
    },
    onError: () => {
      openAlert({
        model: 'message',
        type: 'error',
        message: error?.message,
      });
    },
  });

  const onFetchFull = () =>
    handleSubmit((values) => {
      return onSearchFull(values);
    })();

  return { dataSource: data, setData, onSearchFull, loading: isPending, onFetchFull };
};
