import WrapperComponent from '../components/hoc/Wrapper-component';
import { MainPage } from './welcome';
import { Warranty } from './warranty/warranty-list/Warranty';
import { WarrantyAdd } from './warranty/warranty-add/WarrantyAdd';
import { WarrantyEdit } from './warranty/warranty-edit/WarrantyEdit';
import { WarrantyView } from './warranty/warranty-view/WarrantyView';
import { ClaimList } from './claim/claim-list/ClaimList';
import { ClaimAdd } from './claim/claim-add/ClaimAdd';
import { ClaimEdit } from './claim/claim-edit/ClaimEdit';
import { ClaimView } from './claim/claim-view/ClaimView';
import { ScanDelivery } from './scan-delivery/ScanDelivery';
import { UserInfo } from './user-info/UserInfo';
import { ProductWarranty } from './product-warranty/ProductWarranty';
import { UserPermission } from './permission/UserPermission';
import { ReportPage } from './report/ReportPage';
import { ReportWarranty } from './report-warranty/ReportWarranty';
import { ReportClaim } from './report-claim/ReportClaim';

export default {
  MainPage: WrapperComponent('MainPage')(MainPage),
  Warranty: WrapperComponent('Warranty')(Warranty),
  WarrantyAdd: WrapperComponent('WarrantyAdd')(WarrantyAdd),
  WarrantyEdit: WrapperComponent('WarrantyEdit')(WarrantyEdit),
  WarrantyView: WrapperComponent('WarrantyView')(WarrantyView),
  ClaimList: WrapperComponent('ClaimList')(ClaimList),
  ClaimAdd: WrapperComponent('ClaimAdd')(ClaimAdd),
  ClaimEdit: WrapperComponent('ClaimEdit')(ClaimEdit),
  ClaimView: WrapperComponent('ClaimView')(ClaimView),
  ScanDelivery: WrapperComponent('ScanDelivery')(ScanDelivery),
  UserInfo: WrapperComponent('UserInfo')(UserInfo),
  ProductWarranty: WrapperComponent('ProductWarranty')(ProductWarranty),
  UserPermission: WrapperComponent('UserPermission')(UserPermission),
  ReportPage: WrapperComponent('ReportPage')(ReportPage),
  ReportClaim: WrapperComponent('ReportClaim')(ReportClaim),
  ReportWarranty: WrapperComponent('ReportWarranty')(ReportWarranty),
};
