import React from 'react';
import { ContainerButton } from '../../../styles/global-style';
import ButtonExcelEmbedded from '../../../components/report-excel/ButtonExcelEmbedded';
import { ResponsivePageLayout } from '../../../components/page/responsive-layout/ResponsivePageLayout';
import { Icon } from '../../../resources';
import { SpaceBetween, StyledContainer } from '../../../components/container/styled';
import { TextLarge } from '../../../components/text';
import { CardTheme } from '../../../components/card';
import { ButtonTheme } from '../../../components/buttons';
import { useWarrantyList } from './hooks/useWarrantyList';
import { InfoRegistration } from './views/InfoRegistration';
import { columns, exportHeaders } from './columns';
import { Spin } from 'antd';

export const Warranty = () => {
  const {
    searchType,
    formSearchObj,
    dataDate,
    dataFull,
    isLoading,
    isLoadingFull,
    onSearch,
    gotoWarrantyAdd,
    handleDelete,
    formSearch,
    transformDataForExcel,
    isloadingToDel,
  } = useWarrantyList();

  return (
    <Spin spinning={isloadingToDel}>
      <StyledContainer>
        <TextLarge text="รายงานการลงทะเบียน" />
        <CardTheme>
          <ContainerButton right>
            <ButtonTheme
              useFor="WARRANTY"
              title="ลงทะเบียนสินค้า"
              onClick={() => gotoWarrantyAdd()}
            />
          </ContainerButton>
          <ResponsivePageLayout
            searchLayout={{
              title: 'ค้นหารายการลงทะเบียน',
              icon: <Icon.todo />,
              formSearch,
              useFormParam: formSearchObj,
              onSearch: formSearchObj.handleSubmit(onSearch),
              onClear: formSearchObj.reset,
            }}
            content={<InfoRegistration />}
            tableLayout={{
              columns: columns({ handleDelete }),
              dataSource: searchType === 'search' ? dataFull : dataDate,
              hasPagination: true,
              loading: isLoading || isLoadingFull,
              extraContent: (
                <SpaceBetween>
                  <ContainerButton right>
                    <ButtonExcelEmbedded
                      title="Export"
                      filename="รายงานการลงทะเบียน"
                      header={exportHeaders}
                      data={transformDataForExcel(searchType === 'search' ? dataFull : dataDate)}
                    />
                  </ContainerButton>
                </SpaceBetween>
              ),
            }}
          />
        </CardTheme>
      </StyledContainer>
    </Spin>
  );
};
