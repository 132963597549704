import { useMutation } from '@tanstack/react-query';
import { GET, GET_CUSTOMERS_TEL } from '../../../../services';
import { openAlert } from '../../../../components/alert/hooks';

export const useTeleQuery = ({ reset, getValues }) => {
  const { isPending, mutate } = useMutation({
    mutationFn: async (value) => {
      const response = await GET(GET_CUSTOMERS_TEL(value));
      return response.result;
    },
    onSuccess: (result) => {
      openAlert({ type: 'success', model: 'message', message: 'ค้นหาเบอร์โทรสำเร็จ' });
      reset({
        ...getValues(),
        fullName: result.item.fullName,
      });
    },

    onError: () => {
      reset({
        ...getValues(),
        fullName: '',
      });
    },
  });
  return { loading: isPending, mutate };
};
