export const FORM_CUSTOMER_SEARCH = ({ onEnter }) => {
  return [
    {
      name: 'search',
      label: 'ค้นหารายการ (กรอกข้อมูลแล้วกด Enter เพื่อค้นหา)',
      type: 'INPUT',
      placeholder: 'ค้นหาข้อมูลลูกค้า',
      span: 16,
      helper: 'Tips : สามารถค้นหาด้วย เบอร์, lineUid, รหัสลูกค้า และชื่อลูกค้า',
      onEnter,
    },
  ];
};
export const searchDefaultValues = {
  search: '',
};
export const FORM_SEND_LINE_USER = () => {
  return [
    {
      name: 'message',
      label: 'ข้อความ (ข้อความระบุไม่เกิน 250 ตัวอักษร)',
      type: 'INPUT_AREA',
      placeholder: '',
      rules: {
        required: true,
        maxLength: 250,
      },
    },
  ];
};
export const FORM_EDIT_USER_DATA = () => {
  const span = 12;
  return [
    {
      name: 'customerId',
      label: 'รหัสลูกค้า',
      type: 'HIDDEN',
      disabled: true,
    },
    {
      name: 'customerCode',
      label: 'รหัสลูกค้า',
      type: 'INPUT',
      placeholder: '',
      disabled: true,
    },
    {
      name: 'fullName',
      label: 'ชื่อ',
      type: 'INPUT',
      placeholder: '',
      rules: {
        required: true,
      },
    },
    {
      name: 'telephone',
      label: 'เบอร์ติดต่อ',
      type: 'INPUT',
      placeholder: '',
      disabled: true,
      span,
      rules: {
        required: true,
        pattern: /^[0-9]{10}$/,
      },
      maxLength: 10,
    },
    {
      name: 'lineUid',
      label: 'ไลน์',
      type: 'INPUT',
      span,
      placeholder: '',
      disabled: true,
    },
  ];
};
export const FORM_EDIT_TEL = () => {
  return [
    {
      name: 'telephone',
      label: 'เบอร์ติดต่อใหม่',
      type: 'INPUT',
      placeholder: '',
      rules: {
        required: true,
        pattern: /^[0-9]{10}$/,
      },
      maxLength: 10,
    },
  ];
};
