import { useForm } from 'react-hook-form';
import { FORM_SEARCH, searchDefaultValues } from '../form';
import { userPermissionQuery } from '../../../services/react-query/optionMasterQuery';
import { useState } from 'preact/hooks';
import { useGetUser } from './useGetUser';
import { columns } from '../columns';
import { useUpdateUser } from './useUpdateUser';
import { useAntDModalContext } from '../../../components/modal/context/useAntdModalContext';

export const useGetUserPermissionList = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const { openConfirmModal } = useAntDModalContext();
  const formSearchObj = useForm({ defaultValues: searchDefaultValues });

  const { data: userList } = userPermissionQuery();
  const {
    dataSource: dataTable,
    onFetch,
    userId,
    loading: isPending,
  } = useGetUser({
    handleSubmit: formSearchObj.handleSubmit,
    setSelectedRowKeys,
  });

  const { mutate: updateUserPermission, loading: isUpdateUser } = useUpdateUser({
    userId,
    selectedRowKeys,
    dataSource: dataTable,
    onSuccessCallback: onFetch,
  });

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleSubmitAccident = () => {
    updateUserPermission();
  };

  const columnsData = columns();
  const formSearch = FORM_SEARCH({ userList });

  return {
    formSearch,
    formSearchObj,
    dataTable,
    isPending,
    onSelectChange,
    selectedRowKeys,
    onFetch,
    columnsData,
    handleSubmitAccident,
    isUpdateUser,
    openConfirmModal,
  };
};
