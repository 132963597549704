import { Col, Row, Spin } from 'antd';
import { StyledContainer } from '../../../components/container/styled';
import { TextLarge } from '../../../components/text';
import { CardTheme } from '../../../components/card';
import { ButtonTheme, RenderForm } from '../../../components';
import { FORM_PRODUCT_REGISTRATION_WARRANTY, FORM_USER_REGISTRATION_WARRANTY } from './form';
import { useGetWarrantyViewList } from './hooks/useGetWarrantyViewList';
import React from 'react';

export const WarrantyView = () => {
  const {
    control,
    setValue,
    getValues,
    LoadingWarrantyView,
    goBack,
    mobileBrandOptions,
    channelBuyOptions,
  } = useGetWarrantyViewList();
  return (
    <Spin spinning={LoadingWarrantyView}>
      <StyledContainer>
        <TextLarge text="แก้ไขข้อมูลรับประกัน" />
        <Row gutter={16}>
          <Col span={14}>
            <CardTheme>
              <TextLarge text="ลูกค้าลงทะเบียนรับประกัน" />
              <RenderForm
                control={control}
                setValue={setValue}
                getValues={getValues}
                forms={FORM_USER_REGISTRATION_WARRANTY()}
              />
            </CardTheme>
          </Col>
          <Col span={10}>
            <CardTheme>
              <TextLarge text="สินค้าลงทะเบียนรับประกัน" />
              <RenderForm
                control={control}
                setValue={setValue}
                getValues={getValues}
                forms={FORM_PRODUCT_REGISTRATION_WARRANTY({
                  mobileBrandOptions,
                  channelBuyOptions,
                })}
              />
            </CardTheme>
          </Col>
        </Row>
        <Row gutter={16} justify="space-between" align="middle" style={{ marginTop: '2%' }}>
          <Col>
            <ButtonTheme useFor="BACK" title="ย้อนกลับ" onClick={goBack} />
          </Col>
        </Row>
      </StyledContainer>
    </Spin>
  );
};
