import { Col, Row, Spin } from 'antd';
import { CardTheme } from '../../components/card';
import { StyledContainer } from '../../components/container/styled';
import { TextLarge } from '../../components/text';
import { RenderForm, Gap, ButtonTheme } from '../../components';
import { useGetScanDeliveryList } from './hooks/useGetScanDeliveryList';
import { FORM_DATA, FORM_SCAN, FORM_SHIPPING } from './form';
import React from 'react';
import { ResponsivePageLayout } from '../../components/page/responsive-layout/ResponsivePageLayout';
import { ContainerButton } from '../../styles/global-style';
import { columns } from './columns';
import { UploadFileModal } from './modals/UploadFileModal';
import { UpdateTrackingModal } from './modals/UpdateTrackingModal';

export const ScanDelivery = () => {
  const {
    getValues,
    setValue,
    control,
    shippingSelfOptions,
    tableData,
    handleDeleteRecord,
    onSubmit,
    handleSubmit,
    claimCodeRef,
    trackingNumberRef,
    handleOnEnter,
    openUpload,
    onToggleUpload,
    isLoadingToSave,
    isLoadingInfo,
    openTracking,
    onToggleTracking,
    onOpenTracking,
    setTableData,
  } = useGetScanDeliveryList();

  return (
    <Spin spinning={isLoadingToSave || isLoadingInfo}>
      <StyledContainer>
        <TextLarge text="ยิงจ่ายพัสดุ (คำเตือน ไม่ควร รีเฟรชหน้าจอระหว่างการแสกน)" />
        <Row gutter={16}>
          <Col span={8}>
            <CardTheme>
              <TextLarge text="ข้อมูลการยิง" />
              <RenderForm
                control={control}
                getValues={getValues}
                setValue={setValue}
                forms={FORM_SCAN({
                  onEnter: handleOnEnter,
                  claimCodeRef,
                  trackingNumberRef,
                })}
              />
            </CardTheme>
          </Col>
          <Col span={16}>
            <CardTheme>
              <TextLarge text="ข้อมูลเอกสาร" />
              <RenderForm
                control={control}
                getValues={getValues}
                setValue={setValue}
                forms={FORM_DATA()}
              />
            </CardTheme>
          </Col>
        </Row>
        <Gap />
        <Row justify="space-between" align="middle" style={{ width: '100%', display: 'flex' }}>
          <div style={{ flex: 0.3 }}>
            <RenderForm
              control={control}
              getValues={getValues}
              setValue={setValue}
              forms={FORM_SHIPPING({ shippingSelfOptions })}
            />
          </div>
          <div>
            <ButtonTheme useFor="IMPORT" onClick={onToggleUpload} />
            {openUpload && <UploadFileModal open={openUpload} onToggle={onToggleUpload} />}
          </div>
        </Row>
        <Gap />
        <ResponsivePageLayout
          tableLayout={{
            columns: columns({ handleDelete: handleDeleteRecord, onOpenTracking }),
            dataSource: tableData,
            rowKey: 'claimCode',
          }}
        />
        <div style={{ marginTop: 15 }}></div>
        <ContainerButton right>
          <ButtonTheme useFor="SUBMIT" title="บันทึก" onClick={handleSubmit(onSubmit)} />
        </ContainerButton>
        {openTracking && (
          <UpdateTrackingModal
            open={openTracking}
            onToggle={onToggleTracking}
            tableData={Array.isArray(tableData) ? tableData : []}
            setTableData={setTableData}
          />
        )}
      </StyledContainer>
    </Spin>
  );
};
