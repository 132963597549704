import dayjs from 'dayjs';

export const FORM_REPORT_CLAIM = ({ onEnter }) => {
  return [
    {
      label: 'ค้นหารายการ (กรอกข้อมูลแล้วกด Enter เพื่อค้นหา)',
      name: 'search',
      type: 'INPUT',
      span: 12,
      onEnter,
    },
    {
      label: 'ค้นหาผ่านวันที่',
      name: 'startDate',
      type: 'DATE_RANGE',
      span: 12,
    },
  ];
};

export const searchDefaultValues = {
  search: '',
  claimFrom: '',
  startDate: [dayjs().startOf('month'), dayjs()],
};
