import { useForm } from 'react-hook-form';
import { FORM_REPORT_CLAIM, searchDefaultValues } from '../form';
import { useGetReportByDate } from './useGetReportByDate';
import { columns, columnsExpandable } from '../columns';
import { useEffect, useState } from 'preact/hooks';
import { useGetReportClaimFull } from './useGetReportClaimFull';

export const useGetReportPageList = () => {
  const [searchType, setSearchType] = useState(null);
  const formSearchObj = useForm({ defaultValues: searchDefaultValues });
  const {
    dataSource: dataDate,
    onFetch,
    isLoading,
  } = useGetReportByDate({ handleSubmit: formSearchObj.handleSubmit });

  const {
    dataSource: dataClaimFull,
    onFetchFull,
    loading: loadingClaimFull,
  } = useGetReportClaimFull({
    handleSubmit: formSearchObj.handleSubmit,
  });

  useEffect(() => {
    onSearchByField(searchDefaultValues);
  }, []);

  const onSearchByField = (values) => {
    if (values.search) {
      setSearchType('search');
      onFetchFull(values);
    } else if (values.startDate) {
      setSearchType('date');
      onFetch(values);
    }
  };

  const formatDataForExcel = (data) => {
    return data.flatMap((record) => {
      const mainRecord = {
        'ชื่อ-นามสกุล': record?.fullName,
        เบอร์โทร: record?.telephone,
        จำนวนการเคลม: record?.totalClaims,
        วันที่เคลมล่าสุด: record?.lastClaimDate,
      };

      if (!record?.claimReportHistoryDetails?.length) {
        return [mainRecord, {}];
      }

      return [
        ...record.claimReportHistoryDetails.map((claimDetail, index) => ({
          ...mainRecord,
          ...(index > 0
            ? {
                'ชื่อ-นามสกุล': undefined,
                เบอร์โทร: undefined,
                จำนวนการเคลม: undefined,
                วันที่เคลมล่าสุด: undefined,
              }
            : {}),
          รูปภาพสินค้าเคลม: claimDetail?.claimImageUrl,
          รูปภาพสลิปโอนเงิน: claimDetail?.receiptImageUrl,
          เลขที่การเคลม: claimDetail?.claimCode,
          ยี่ห้อ: claimDetail?.mobileBrandName,
          รุ่น: claimDetail?.mobileModelName,
          ชื่อสินค้า: claimDetail?.productName,
          รหัสสินค้า: claimDetail?.itemCode,
          ประเภทสินค้า: claimDetail?.productTypeCode,
          วันที่แจ้งเคลม: claimDetail?.claimDate,
          อาการเสีย: claimDetail?.breakdownName,
          'EMS จัดส่ง': claimDetail?.trackingNumber,
        })),
        {},
      ];
    });
  };

  const currentData = searchType === 'date' ? dataDate : dataClaimFull || [];
  const maxDetails = Math.max(
    ...currentData.map((record) => record.claimReportHistoryDetails?.length || 0),
  );

  const createExpandableHeaders = () => {
    return [
      { title: 'รูปภาพสินค้าเคลม', dataIndex: 'รูปภาพสินค้าเคลม' },
      { title: 'รูปภาพสลิปโอนเงิน', dataIndex: 'รูปภาพสลิปโอนเงิน' },
      { title: 'เลขที่การเคลม', dataIndex: 'เลขที่การเคลม' },
      { title: 'ยี่ห้อ', dataIndex: 'ยี่ห้อ' },
      { title: 'รุ่น', dataIndex: 'รุ่น' },
      { title: 'ชื่อสินค้า', dataIndex: 'ชื่อสินค้า' },
      { title: 'รหัสสินค้า', dataIndex: 'รหัสสินค้า' },
      { title: 'ประเภทสินค้า', dataIndex: 'ประเภทสินค้า' },
      { title: 'วันที่แจ้งเคลม', dataIndex: 'วันที่แจ้งเคลม' },
      { title: 'อาการเสีย', dataIndex: 'อาการเสีย' },
      { title: 'EMS จัดส่ง', dataIndex: 'EMS จัดส่ง' },
    ];
  };

  const columnsData = columns();
  const columnsExpandableData = columnsExpandable();
  const formSearch = FORM_REPORT_CLAIM({ onEnter: formSearchObj.handleSubmit(onSearchByField) });

  return {
    formSearch,
    formSearchObj,
    dataDate,
    onFetch,
    isLoading,
    columnsData,
    dataClaimFull,
    onFetchFull,
    loadingClaimFull,
    searchType,
    onSearchByField,
    columnsExpandableData,
    formatDataForExcel,
    maxDetails,
    createExpandableHeaders,
  };
};
