import React from 'react';

import { Icon } from './icon';
import Pages from '../screens/all-page';
import { color } from './color';
import { FaCog } from 'react-icons/fa';

export const ROUTE_LOGIN = 'https://central.zetta-system.com';
export const ROUTE_LOGIN_MANUAL = '/account/login';
export const ROUTE_HANDLE = '/account/handle';

export const KEY_ROUTE_MAIN = '@ROUTES/ROUTE_MAIN';
export const KEY_ROUTE_HOME = '@ROUTES/ROUTE_HOME';
export const KEY_ROUTE_DASHBOARD = '@ROUTES/ROUTE_DASHBOARD';

// Warranty
export const KEY_ROUTE_WARRANTY = '@ROUTES/ROUTE_WARRANTY';
export const KEY_ROUTE_WARRANTY_ADD = '@ROUTES/ROUTE_WARRANTY_ADD';
export const KEY_ROUTE_WARRANTY_EDIT = '@ROUTES/ROUTE_WARRANTY_EDIT';
export const KEY_ROUTE_WARRANTY_VIEW = '@ROUTES/ROUTE_WARRANTY_VIEW';

//claim
export const KEY_ROUTE_CLAIM = '@ROUTES/ROUTE_CLAIM';
export const KEY_ROUTE_CLAIM_ADD = '@ROUTES/ROUTE_CLAIM_ADD';
export const KEY_ROUTE_CLAIM_VIEW = '@ROUTES/ROUTE_CLAIM_VIEW';
export const KEY_ROUTE_CLAIM_EDIT = '@ROUTES/ROUTE_CLAIM_EDIT';

//Scan Delivery
export const KEY_ROUTE_SCAN_DELIVERY = '@ROUTES/ROUTE_SCAN_DELIVERY';

//User Info
export const KEY_ROUTE_USER_INFO = '@ROUTE/ROUTE_USER_INFO';

//Product Warranty
export const KEY_ROUTE_PRODUCT_WARRANTY = '@ROUTE/ROUTE_PRODUCT_WARRANTY';

//User Permisson
export const KEY_ROUTE_USER_PERMISSION = '@ROUTE/ROUTE_USER_PERMISSION';

//Report
export const KEY_ROUTE_REPORT_ALL = '@ROUTES/ROUTE_REPORT_ALL';
export const KEY_ROUTE_REPORT = '@ROUTES/ROUTE_REPORT';
export const KEY_ROUTE_REPORT_CLAIM = '@ROUTES/ROUTE_REPORT_CLAIM';
export const KEY_ROUTE_REPORT_WARRANTY = '@ROUTES/ROUTE_REPORT_WARRANTY';

function renderStyle(comp) {
  return <div style={{ marginRight: '1px', color: color.redRizz }}>{comp}</div>;
}

export const ROUTES_PATH = {
  ROUTE_MAIN: {
    KEY: KEY_ROUTE_MAIN,
    PATH: '/',
    LABEL: 'หน้าหลัก',
    CODE: 'PE001',
    COMPONENT: Pages.MainPage,
  },
  //warranty
  ROUTE_WARRANTY: {
    KEY: KEY_ROUTE_WARRANTY,
    ICON: renderStyle(<Icon.registered />),
    PATH: '/warranty',
    LABEL: 'รายการลงทะเบียน',
    CODE: 'PE101',
    COMPONENT: Pages.Warranty,
  },
  ROUTE_WARRANTY_ADD: {
    KEY: KEY_ROUTE_WARRANTY_ADD,
    PATH: '/warranty-add',
    LABEL: 'ลงทะเบียนสินค้า',
    CODE: 'PE111',
    COMPONENT: Pages.WarrantyAdd,
  },
  ROUTE_WARRANTY_EDIT: {
    KEY: KEY_ROUTE_WARRANTY_EDIT,
    PATH: '/warranty-edit',
    LABEL: 'แก้ไขรายการลงทะเบียน',
    CODE: 'PE112',
    COMPONENT: Pages.WarrantyEdit,
  },
  ROUTE_WARRANTY_VIEW: {
    KEY: KEY_ROUTE_WARRANTY_VIEW,
    PATH: '/warranty-view',
    LABEL: 'ดูข้อมูลลงทะเบียน',
    CODE: 'PE113',
    COMPONENT: Pages.WarrantyView,
  },
  //claim
  ROUTE_CLAIM: {
    KEY: KEY_ROUTE_CLAIM,
    ICON: renderStyle(<Icon.fileSearch />),
    PATH: '/claim',
    LABEL: 'เคลมสินค้า',
    CODE: 'PE201',
    COMPONENT: Pages.ClaimList,
  },
  ROUTE_CLAIM_ADD: {
    KEY: KEY_ROUTE_CLAIM_ADD,
    PATH: '/claim-add',
    LABEL: 'ยื่นเรื่องเคลม',
    CODE: 'PE211',
    COMPONENT: Pages.ClaimAdd,
  },
  ROUTE_CLAIM_VIEW: {
    KEY: KEY_ROUTE_CLAIM_VIEW,
    PATH: '/claim-view',
    LABEL: 'ดูการเคลมสินค้า',
    CODE: 'PE210',
    COMPONENT: Pages.ClaimView,
  },
  ROUTE_CLAIM_EDIT: {
    KEY: KEY_ROUTE_CLAIM_EDIT,
    PATH: '/claim-edit',
    LABEL: 'แก้ไขเคลมสินค้า',
    CODE: 'PE212',
    COMPONENT: Pages.ClaimEdit,
  },
  //Scan Delivery
  ROUTE_SCAN_DELIVERY: {
    KEY: KEY_ROUTE_SCAN_DELIVERY,
    ICON: renderStyle(<Icon.qrcode />),
    PATH: '/scan-delivery',
    LABEL: 'ยิงจ่ายพัสดุ',
    CODE: 'PE301',
    COMPONENT: Pages.ScanDelivery,
  },
  // User Info
  ROUTE_USER_INFO: {
    KEY: KEY_ROUTE_USER_INFO,
    ICON: renderStyle(<Icon.users />),
    PATH: '/user',
    LABEL: 'ข้อมูลลูกค้า',
    CODE: 'PE401',
    COMPONENT: Pages.UserInfo,
  },
  // Product Warranty
  ROUTE_PRODUCT_WARRANTY: {
    KEY: KEY_ROUTE_PRODUCT_WARRANTY,
    ICON: renderStyle(<Icon.formExample />),
    PATH: '/product-warranty',
    LABEL: 'สินค้ารับประกัน',
    CODE: 'PE504',
    COMPONENT: Pages.ProductWarranty,
  },
  // User Permission
  ROUTE_USER_PERMISSION: {
    KEY: KEY_ROUTE_USER_PERMISSION,
    ICON: renderStyle(<FaCog />),
    PATH: '/manage-permission',
    LABEL: 'ปรับสิทธิ์เมนู',
    CODE: 'PE701',
    COMPONENT: Pages.UserPermission,
  },
  // Report
  ROUTE_REPORT: {
    KEY: KEY_ROUTE_REPORT,
    ICON: renderStyle(<Icon.ticket />),
    PATH: '/report',
    LABEL: 'รายงานประวัติการเคลม',
    CODE: 'PE601',
    COMPONENT: Pages.ReportPage,
  },
  ROUTE_REPORT_CLAIM: {
    KEY: KEY_ROUTE_REPORT_CLAIM,
    ICON: renderStyle(<Icon.ticket />),
    PATH: '/report-claim',
    LABEL: 'รายงานเปลี่ยนสินค้าข้ามรุ่น',
    CODE: 'PE602',
    COMPONENT: Pages.ReportClaim,
  },
  ROUTE_REPORT_WARRANTY: {
    KEY: KEY_ROUTE_REPORT_WARRANTY,
    ICON: renderStyle(<Icon.ticket />),
    PATH: '/report-warranty',
    LABEL: 'หน้ารายงานประวัติลงทะเบียน',
    CODE: 'PE603',
    COMPONENT: Pages.ReportWarranty,
  },
};

function getKeyLowerCase(obj) {
  return Object.fromEntries(Object.entries(obj).map(([k, v]) => [k.toLowerCase(), v]));
}

export const ROUTES_MENU = [
  getKeyLowerCase(ROUTES_PATH.ROUTE_WARRANTY),
  getKeyLowerCase(ROUTES_PATH.ROUTE_CLAIM),
  getKeyLowerCase(ROUTES_PATH.ROUTE_SCAN_DELIVERY),
  getKeyLowerCase(ROUTES_PATH.ROUTE_USER_INFO),
  getKeyLowerCase(ROUTES_PATH.ROUTE_PRODUCT_WARRANTY),

  {
    key: KEY_ROUTE_REPORT_ALL,
    icon: renderStyle(<Icon.formExample />),
    label: 'รายงาน',
    children: [
      getKeyLowerCase(ROUTES_PATH.ROUTE_REPORT_WARRANTY),
      getKeyLowerCase(ROUTES_PATH.ROUTE_REPORT),
      getKeyLowerCase(ROUTES_PATH.ROUTE_REPORT_CLAIM),
    ],
  },
  getKeyLowerCase(ROUTES_PATH.ROUTE_USER_PERMISSION),
];
