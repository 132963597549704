import {
  createColumnDate,
  createColumnImage,
  createColumnNumber,
  createColumnText,
  widthOptions,
} from '../../../components/table/function';

export const columns = () => {
  return [
    {
      ...createColumnText('ชื่อ-นามสกุล', 'fullName', widthOptions(150)),
      render: (text, record) => (
        <span style={{ color: record?.totalClaims > 5 ? '#ff4d4f' : '#000' }}>{text}</span>
      ),
    },

    {
      ...createColumnText('เบอร์โทร', 'telephone', widthOptions(95)),
      render: (text, record) => (
        <span style={{ color: record?.totalClaims > 5 ? '#ff4d4f' : '#000' }}>{text}</span>
      ),
    },
    {
      ...createColumnNumber('จำนวนการเคลม', 'totalClaims', widthOptions(100)),
      sorter: (a, b) => a.totalClaims - b.totalClaims,
      defaultSortOrder: 'descend',
      render: (text, record) => (
        <span style={{ color: record?.totalClaims > 5 ? '#ff4d4f' : '#000' }}>{text}</span>
      ),
    },
    {
      ...createColumnDate('วันที่เคลมล่าสุด', 'lastClaimDate', widthOptions(150)),
      render: (text, record) => (
        <span style={{ color: record?.totalClaims > 5 ? '#ff4d4f' : '#000' }}>{text}</span>
      ),
    },
  ];
};

export const columnsExpandable = () => {
  return [
    createColumnImage('รูปภาพสินค้าเคลม', 'claimImageUrl', widthOptions(150)),
    createColumnImage('รูปภาพสลิปโอนเงิน', 'receiptImageUrl', widthOptions(150)),
    createColumnText('เลขที่การเคลม', 'claimCode', widthOptions(150)),
    createColumnText('ยี่ห้อ', 'mobileBrandName', widthOptions(150)),
    createColumnText('รุ่น', 'mobileModelName', widthOptions(150)),
    createColumnText('ชื่อสินค้า', 'productName', widthOptions(150)),
    createColumnText('รหัสสินค้า', 'itemCode', widthOptions(150)),
    createColumnText('ประเภทสินค้า', 'productTypeCode', widthOptions(150)),
    createColumnDate('วันที่แจ้งเคลม', 'claimDate', widthOptions(150)),
    createColumnText('อาการเสีย', 'breakdownName', widthOptions(150)),
    createColumnText('EMS จัดส่ง', 'trackingNumber', widthOptions(150)),
  ];
};
