import { create } from 'apisauce';
import { getQueryString, getQueryStringIgnoreEmpty } from '../functions';
import { getCookies } from '../store/useCookies';
import { env } from '../utils/env/config';
import { ROUTE_LOGIN_MANUAL } from '../resources/routes-name';
import { navigateTo } from '../utils/navigation';
import { Modal } from 'antd';
export const ip = 'http://192.168.1.169:2000';

export const MODE_API = {
  DEVELOPMENT: env.url_dev,
  UAT: env.url_uat,
  PRODUCTION: env.url_prd,
};
export const CONNECT_API = MODE_API[`${env.node_env}`.toUpperCase()];

const api = create({
  baseURL: CONNECT_API,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 30000, // 30 seconds
});

api.axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.code === 'ECONNABORTED') {
      navigateTo({ pathname: ROUTE_LOGIN_MANUAL });
    }
    return Promise.reject(error);
  },
);

const apiupload = create({
  baseURL: CONNECT_API,
  withCredentials: true,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});
/**
 * ฟังก์ชั่นสำหรับ ยิงข้อมูล
 * @param {string} path
 * @param {{}} obj
 * @return {{}}
 */

const checkAccessToken = (token) => {
  if (!token) {
    Modal.confirm({
      title: 'แจ้งเตือน',
      content:
        'ไม่สามารถใช้งานได้ กรุณาออกจากระบบ แล้วลองเข้าใหม่อีกครั้ง หากเข้าไม่ได้ให้ติดต่อ Admin',
      okText: 'ตกลง',
      onOk: () => {
        navigateTo({ pathname: ROUTE_LOGIN_MANUAL });
      },
    });
    return false;
  }
  return true;
};

export const POST = (path, obj, token = getCookies('accessToken'), config = {}) =>
  new Promise((resolve, reject) => {
    api
      .post(path, obj, {
        headers: {
          Authorization: 'Bearer ' + token,
          ...(config.responseType === 'blob'
            ? { Accept: 'application/pdf' }
            : { 'Content-Type': 'application/json' }),
        },
        ...config,
        responseType: config.responseType || 'json',
      })
      .then((response) => {
        if (config.responseType === 'blob' && response.status === 200) {
          resolve(response.data);
          return;
        }

        if (response.status === 200) {
          resolve(response.data);
        } else {
          if (response.status === 401) {
            navigateTo({ pathname: ROUTE_LOGIN_MANUAL });
          } else {
            response.data
              ? reject(response.data)
              : reject({ success: false, message: response.problem });
          }
        }
      })
      .catch((err) => {
        // ถ้าเป็น blob แต่เกิด error
        if (config.responseType === 'blob' && err.response) {
          // แปลง blob error เป็น json
          const reader = new FileReader();
          reader.onload = () => {
            try {
              const error = JSON.parse(reader.result);
              if (error.status === 401) {
                navigateTo({ pathname: ROUTE_LOGIN_MANUAL });
              } else {
                reject(error);
              }
            } catch (e) {
              reject({ success: false, message: 'เกิดข้อผิดพลาด' });
            }
          };
          reader.onerror = () => {
            reject({ success: false, message: 'เกิดข้อผิดพลาด' });
          };
          reader.readAsText(err.response.data);
          return;
        }

        // error กรณีปกติ
        if (err.response && err.response.status === 401) {
          navigateTo({ pathname: ROUTE_LOGIN_MANUAL });
        } else {
          reject(err);
        }
      });
  });

/**
 * ฟังก์ชั่นสำหรับ ยิงข้อมูล
 * @param {string} path
 * @param {{}} obj
 * @return {{}}
 */
export const PUT = (path, obj, token = getCookies('accessToken')) =>
  new Promise((resolve, reject) => {
    api
      .put(path, obj, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then((response) => {
        if (response.ok) {
          resolve(response.data);
        } else {
          response.data
            ? reject(response.data)
            : reject({ success: false, message: response.problem });
        }
      })
      .catch((err) => reject(err));
  });

// /**
//  * ฟังก์ชั่นสำหรับ ดึงข้อมูล
//  * @param {string} path
//  *
export const GET = (path, token = getCookies('accessToken'), config = {}) =>
  new Promise((resolve, reject) => {
    if (!checkAccessToken(token)) {
      return;
    }

    api
      .get(
        path,
        {},
        {
          headers: {
            Authorization: 'Bearer ' + token,
            Accept: '*/*', // เปลี่ยนเป็นแบบเดียวกับ POSTMAN
          },
          ...config,
          responseType: config.responseType || 'json', // ต้องกำหนด 'blob' หากเป็นไฟล์
        },
      )
      .then((response) => {
        if (config.responseType === 'blob' && response.status === 200) {
          resolve(response.data);
          return;
        }

        if (response.status === 200) {
          resolve(response.data);
        } else {
          if (response.status === 401) {
            navigateTo({ pathname: ROUTE_LOGIN_MANUAL });
          } else {
            response.data
              ? reject(response.data)
              : reject({ success: false, message: response.problem });
          }
        }
      })
      .catch((err) => {
        if (config.responseType === 'blob' && err.response) {
          const reader = new FileReader();
          reader.onload = () => {
            try {
              const error = JSON.parse(reader.result);
              if (error.status === 401) {
                navigateTo({ pathname: ROUTE_LOGIN_MANUAL });
              } else {
                reject(error);
              }
            } catch (e) {
              reject({ success: false, message: 'เกิดข้อผิดพลาด' });
            }
          };
          reader.onerror = () => {
            reject({ success: false, message: 'เกิดข้อผิดพลาด' });
          };
          reader.readAsText(err.response.data);
          return;
        }

        if (err.response && err.response.status === 401) {
          navigateTo({ pathname: ROUTE_LOGIN_MANUAL });
        } else {
          reject(err);
        }
      });
  });

export const UPLOAD = (path, formdata, token = getCookies('accessToken')) =>
  new Promise((resolve, reject) => {
    if (!checkAccessToken(token)) {
      return;
    }
    apiupload
      .post(path, formdata, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then((response) => {
        if (response.ok) {
          resolve(response.data);
        } else {
          response.data
            ? reject(response.data)
            : reject({ success: false, message: response.message });
        }
      })
      .catch((err) => reject(err));
  });

/* ################################################## URL ################################################## */
export const LOGIN = `/warranty/rizz/api/v2/admin/auth/login`;
export const LOGIN_GOOGLE = `/warranty/rizz/api/v2/admin/auth/login/central`;
export const REGISTER = `/authen/register`;
export const GET_PROFILE = `/warranty/rizz/api/v2/admin/auth/me`;
export const GET_ALL_PRODUCT = `/product/get-all`;
export const GET_PRODUCT_SEARCH = (obj) => `/product/search?${getQueryString(obj)}`;

export const GET_ALL_POST = `/posts`;
export const INSERT_POST = `/posts`;
export const UPDATE_POST = (id) => `/posts/${id}`;
export const GET_POST_BY_ID = (id) => `/posts/${id}`;

//warranty
export const GET_WARRANTY = (obj) =>
  `/warranty/rizz/api/v2/admin/warranties/by-date?${getQueryStringIgnoreEmpty(obj)}`;
export const GET_WARRANTY_FULL_SEARCH = (obj) =>
  `/warranty/rizz/api/v2/admin/warranties/full-search?${getQueryStringIgnoreEmpty(obj)}`;
export const GET_WARRANTY_REGISTER = (warrantyNumberCode) =>
  `/warranty/rizz/api/v2/admin/serial-number/verify/${warrantyNumberCode}`;
export const GET_CUSTOMERS_TEL = (telephone) =>
  `/warranty/rizz/api/v2/admin/customers/tel/${telephone}`;
export const POST_CANCEL_WARRANTY = () => `/warranty/rizz/api/v2/admin/warranties/cancel`;
export const GET_WARRANTY_INFO_CODE = (warrantyCode) =>
  `/warranty/rizz/api/v2/admin/warranties/info/${warrantyCode}`;
export const POST_WARRANTY_UPDATE = () => `/warranty/rizz/api/v2/admin/warranties/update`;
export const POST_WARRANTY_ADD = () => `/warranty/rizz/api/v2/admin/warranties/add`;
export const POST_UPDATE_EXPIRED = () => `/warranty/rizz/api/v2/admin/warranties/update/expired`;

//claim
export const GET_CLAIM_BY_DATE = (obj) =>
  `/warranty/rizz/api/v2/admin/claims/by-date?${getQueryStringIgnoreEmpty(obj)}`;
export const GET_CLAIM_FULL_SEARCH = (obj) =>
  `/warranty/rizz/api/v2/admin/claims/full-search?${getQueryStringIgnoreEmpty(obj)}`;
export const GET_CUSTOMERS_VERIFY_TEL = (telephone) =>
  `/warranty/rizz/api/v2/admin/claims/customers/verify/${telephone}`;
export const GET_CLAIMS_ZIPCODE = (zipcode) =>
  `/warranty/rizz/api/v2/admin/options/addresses/zipcode/${zipcode}`;
export const POST_UPDATE_TRACKING = () => `/warranty/rizz/api/v2/admin/tracking/change-number`;
export const POST_SEND_LINE_MSG = () => `/warranty/rizz/api/v2/admin/claims/send-msg/line`;
export const POST_SEND_REMARK = () => `/warranty/rizz/api/v2/admin/claims/update/remark`;
export const POST_APPROVE_CLAIM = () =>
  `/warranty/rizz/api/v2/admin/claims/update/status/approve-list`;
export const GET_CLAIM_DETAIL = (claimCode) =>
  `/warranty/rizz/api/v2/admin/claims/code/${claimCode}`;
export const POST_REJECT_CLAIM = () =>
  `/warranty/rizz/api/v2/admin/claims/update/status/reject-list`;
export const POST_CLAIM_REJECT = () => `/warranty/rizz/api/v2/admin/claims/update/status/reject`;
export const POST_UPDATE_PRODUCT = () => `/warranty/rizz/api/v2/admin/claims/update/product`;
export const POST_CLAIM_UPDATE = () => `/warranty/rizz/api/v2/admin/claims/update/info`;
export const POST_CLAIMS_INSERT = () => `/warranty/rizz/api/v2/admin/claims/insert`;
export const POST_CLAIMS_CANCEL = () => `/warranty/rizz/api/v2/admin/claims/update/status/cancel`;
export const GET_PROCESS_STATUS = () => `/warranty/rizz/api/v2/admin/options/status/claim-process`;
export const POST_UPDATE_PROCESS = () => `/warranty/rizz/api/v2/admin/claims/update/process`;
export const GET_TRACKING_STATUS = () => `/warranty/rizz/api/v2/admin/options/shipping/self`;
export const POST_TRACKING_SHIPMENT = () =>
  `/warranty/rizz/api/v2/admin/tracking/download/shipment-label`;
//Scan-delivery
export const GET_CLAIM_TRACKING_DETAIL = (claimCode) =>
  `/warranty/rizz/api/v2/admin/claims/tracking/${claimCode}`;
export const POST_TRACKING_DATA = () => `/warranty/rizz/api/v2/admin/tracking/update/bulk`;
export const GET_EXAMPLE_TEMPLATE = () => `/warranty/rizz/api/v2/admin/tracking/download/example`;
export const POST_UPDATE_EXCEL = () => `/warranty/rizz/api/v2/admin/tracking/update/excel`;
//Customer
export const GET_CUSTOMER_FULL_SEARCH = (obj) =>
  `/warranty/rizz/api/v2/admin/customers/full-search?${getQueryStringIgnoreEmpty(obj)}`;
export const POST_SEND_LINE_USER = () => `/warranty/rizz/api/v2/admin/customers/send-msg/line`;
export const POST_RESET_LINE_USER = () => `/warranty/rizz/api/v2/admin/customers/reset/lineuid`;
export const POST_UPDATE_TEL_USER = () => `/warranty/rizz/api/v2/admin/customers/update/tel`;
export const POST_UPDATE_USER_INFO = () => `/warranty/rizz/api/v2/admin/customers/update/info`;
//Product-Warranty
export const GET_PRODUCT_FULL_SEARCH = (obj) =>
  `/warranty/rizz/api/v2/admin/options/products/full-search?${getQueryStringIgnoreEmpty(obj)}`;
export const POST_UPDATE_PRODUCT_LIST = () =>
  `/warranty/rizz/api/v2/admin/options/products/update-list`;

// User Permission
export const GET_USER_PERMISSION = (obj) =>
  `/warranty/rizz/api/v2/admin/auth/users?${getQueryStringIgnoreEmpty(obj)}`;
export const GET_USER_PRIVILEGE = (userId) =>
  `/warranty/rizz/api/v2/admin/auth/users/privileges/for-user/${userId}`;
export const POST_UPDATE_USER_PERMISSION = () =>
  `/warranty/rizz/api/v2/admin/auth/users/update/privilege`;
//Report
export const GET_REPORT_HISTORY_BY_DATE = (obj) =>
  `/warranty/rizz/api/v2/admin/reports/claim/history/by-date?startDate?${getQueryStringIgnoreEmpty(
    obj,
  )}`;
export const GET_REPORT_HISTORY_FULL = (obj) =>
  `/warranty/rizz/api/v2/admin/reports/claim/history/full-search?${getQueryStringIgnoreEmpty(obj)}`;
//Report warranty
export const GET_REPORT_WARRANTY_HISTORY_BY_DATE = (obj) =>
  `/warranty/rizz/api/v2/admin/reports/warranty/history/by-date?startDate?${getQueryStringIgnoreEmpty(
    obj,
  )}`;
export const GET_REPORT_WARRANTY_HISTORY_FULL = (obj) =>
  `/warranty/rizz/api/v2/admin/reports/warranty/history/full-search?${getQueryStringIgnoreEmpty(
    obj,
  )}`;
//Report Claim
export const GET_REPORT_CHANGE_HISTORY_BY_DATE = (obj) =>
  `/warranty/rizz/api/v2/admin/reports/claim/change-product/by-date?startDate?${getQueryStringIgnoreEmpty(
    obj,
  )}`;
export const GET_REPORT_CHANGE_HISTORY_FULL = (obj) =>
  `/warranty/rizz/api/v2/admin/reports/claim/change-product/full-search?${getQueryStringIgnoreEmpty(
    obj,
  )}`;

// OPTIONS
export const GET_CHANNEL_BUYS = () => `/warranty/rizz/api/v2/admin/options/channel-buys`;
export const GET_MOBILE_BRANDS = () => `/warranty/rizz/api/v2/admin/options/mobile-brands`;
export const GET_BARCORE = (barcode) =>
  `/warranty/rizz/api/v2/admin/options/products/barcode/${barcode}`;
export const GET_PRODUCT_TYPES = () => `/warranty/rizz/api/v2/admin/options/products/types`;
