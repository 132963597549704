import { color } from '../../../../resources';
import React from 'react';
import { Icon } from '../../../../resources/icon';

export const requestStatusObg2 = {
  [10]: {
    label: 'รอตรวจสอบ',
    color: color.blueFocus,
    icon: <Icon.hourglass />,
  },
  [20]: {
    label: 'กำลังจัดส่ง',
    color: color.yellow,
    icon: <Icon.truck />,
  },
  [30]: {
    label: 'เรียบร้อยแล้ว',
    color: color.line,
    icon: <Icon.paperPlane />,
  },
  [40]: {
    label: 'ไม่อนุมัติ',
    color: color.edit,
    icon: <Icon.times />,
  },
  [50]: {
    label: 'ยกเลิกรายการ',
    color: 'gray',
    icon: <Icon.cancel />,
  },
};
