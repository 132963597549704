import { useMutation } from '@tanstack/react-query';
import { POST, POST_SEND_REMARK } from '../../../../services';
import { openAlert } from '../../../../components/alert/hooks';

export const useUpdateRemark = ({ onSuccessCallBack }) => {
  const { mutate, isPending } = useMutation({
    mutationFn: async (payload) => {
      const response = await POST(POST_SEND_REMARK(), payload);
      onSuccessCallBack && onSuccessCallBack(response);
      return response.result;
    },
    onSuccess: () => {
      openAlert({
        model: 'message',
        type: 'success',
        message: 'ส่งข้อความหมายเหตุสำเร็จ',
      });
    },
  });
  return { mutate, loading: isPending };
};
