import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTeleQuery } from './useTeleQuery';
import { useZipcode } from './useZipcodeQuery';
import { useOptions } from './useOptions';
import { handleSubmitForm } from '../function/submitHandler';
import { onUploadFile } from './onUploadFile';

export const useGetClaimAddList = () => {
  const { control, setValue, getValues, watch, reset, handleSubmit } = useForm({
    defaultValues: {
      isCreateNewAddress: 'false',
    },
  });
  const fullName = watch('fullName');
  const isCreateNewAddress = watch('isCreateNewAddress');
  const selectedWarranty = watch('warrantyId');
  useEffect(() => {
    setValue('breakdownId', '');
  }, [selectedWarranty, setValue]);

  const { mutate: onCallTele, loading: isLoadingTele } = useTeleQuery({ reset, getValues });
  const { mutate: onCallZipCode, loading: isLoadingZipCode } = useZipcode({ reset, getValues });
  const { mutate: onAddClaim, loading: isLoadingToSave } = onUploadFile();

  const {
    addressFullOptions,
    provinceOptions,
    districtOptions,
    subDistrictOptions,
    addressData,
    warrantyOptions,
    breakdownOptions,
  } = useOptions({
    watch,
  });

  const onEnterHandler = useCallback(
    async (value, item) => {
      switch (item?.name) {
        case 'telephone':
          reset({ telephone: getValues('telephone') });
          onCallTele(value);
          setValue('isCreateNewAddress', 'false');
          break;
        case 'zipcode':
          onCallZipCode(value);
          break;
        default:
          break;
      }
    },
    [getValues],
  );
  const onChangeAddress = (value) => {
    const addressSetNew = addressData?.find((item) => item?.customerAddressId === value);
    if (addressSetNew) {
      setValue('zipcode', addressSetNew?.zipcode);
      setValue('provinceId', {
        value: addressSetNew?.provinceId,
        label: addressSetNew?.provinceName,
      });
      setValue('districtId', {
        value: addressSetNew?.districtId,
        label: addressSetNew?.districtName,
      });
      setValue('subDistrictId', {
        value: addressSetNew?.subDistrictId,
        label: addressSetNew?.subDistrictName,
      });
      setValue('other', addressSetNew?.other);
    }
    if (addressData === null) {
      setValue('isCreateNewAddress', 'true');
    }
  };
  useEffect(() => {
    if (isCreateNewAddress === 'true') {
      setValue('zipcode', '');
      setValue('provinceId', '');
      setValue('districtId', '');
      setValue('subDistrictId', '');
      setValue('other', '');
    }
  }, [isCreateNewAddress]);

  const onSubmit = (values) => {
    const valuesWithCustomerCode = {
      ...values,
      provinceId:
        typeof values.provinceId === 'object' ? values.provinceId.value : values.provinceId,
      districtId:
        typeof values.districtId === 'object' ? values.districtId.value : values.districtId,
      subDistrictId:
        typeof values.subDistrictId === 'object'
          ? values.subDistrictId.value
          : values.subDistrictId,
    };
    handleSubmitForm(valuesWithCustomerCode, onAddClaim);
  };
  return {
    control,
    setValue,
    getValues,
    fullName,
    watch,
    onEnterHandler,
    addressFullOptions,
    provinceOptions,
    districtOptions,
    subDistrictOptions,
    warrantyOptions,
    breakdownOptions,
    onChangeAddress,
    onSubmit,
    handleSubmit,
    isLoadingToSave,
    isLoadingTele,
    isLoadingZipCode,
    addressData,
    isCreateNewAddress,
    handleSubmit,
  };
};
